import React, { useState, useEffect } from 'react';
import { Flex, Image, useColorModeValue, Text, IconButton, Switch, Box, Heading } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil';
import { AuthenticatedLayout } from '../layouts';
import { useFormActions } from '../hooks';
import { currentUserState, UserAllottedCredits } from '../state';
import { SoftCard } from '../components/generic/SoftCard';
import { Stat, StatLabel, StatHelpText } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md';

export const FormsPage: React.FC = () => {
  const userAllottedCredits = useRecoilValue(UserAllottedCredits);
  const currentUser = useRecoilValue(currentUserState);
  const { deleteForm, getUserForms, toggleFormStatus } = useFormActions();
  const [userForms, setUserForms] = useState<any>([]);
  const noSitesFoundImageSource = useColorModeValue('/illustrations/no-sites-found.png', '/illustrations/no-sites-found-dark.png');

  const handleToggleFormStatus = async (formId: string) => {
    try {
      await toggleFormStatus(formId)

      setUserForms((previousForms: any) => {
        return previousForms.map((form: any) => {
          if (form.id === formId) {
            return {
              ...form,
              is_active: !form.is_active,
            };
          }
          return form;
        });
      });

    } catch {
      throw new Error('Could not toggle form status.');
    }
  }

  const handleDeleteForm = async (formId: string) => {
    try {
      await deleteForm(formId);
      setUserForms((previousForms: any) => previousForms.filter((f: any) => f.id !== formId));
    } catch {
      throw new Error('Could not delete form.');
    }
  }

  useEffect(() => {
    async function fetchUserForms() {
      if (!currentUser) return;
      const userForms = await getUserForms(currentUser?.id);
      setUserForms(userForms);
    }

    fetchUserForms();
  }, [])

  return (
    <AuthenticatedLayout>
      <Flex flex={1} flexDirection={'column'} gap={3} w='full'>
        <SoftCard h='full'>
          <Flex p={4} justifyContent='space-between' gap={4}>
            <Heading as="h3" size="md">
              Forms
            </Heading>
          </Flex>
          <Flex flexDir='column' h='full' gap={2}>
            {userForms && userForms.length > 0 ? (
              <>
                <TableContainer>
                  <Table variant='simple'>
                    <TableCaption>Enabled Forms</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>Status</Th>
                        <Th>Identifier</Th>
                        <Th>Recipient</Th>
                        <Th>Project</Th>
                        <Th>Mode</Th>
                        <Th>Delete</Th>
                      </Tr>
                    </Thead>
                    <Tbody>

                      {

                        userForms.map((form: any, index: number) => (
                          <Tr key={index}>
                            <Td>
                              <Switch size='sm' colorScheme='purple' isChecked={form.is_active} onChange={() => handleToggleFormStatus(form.id)} />
                            </Td>
                            <Td>{form.dom_id}</Td>
                            <Td>{form.destination_email}</Td>
                            <Td>{form.project.name}</Td>
                            <Td>{form.project.mode}</Td>
                            <Td>
                              <IconButton
                                size="xs"
                                variant='solid'
                                colorScheme='purple'
                                aria-label='Delete'
                                icon={<MdDelete />}
                                onClick={() => handleDeleteForm(form.id)}
                              />
                            </Td>
                          </Tr>
                        ))
                      }

                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Flex flexGrow={1} justifyContent="center" alignItems="center">
                <Flex flexDir="column" gap={10} alignItems="center">
                  <Image src={noSitesFoundImageSource} width='500px' />
                  <Text textAlign="center">You do not have any enabled forms at the moment.</Text>
                </Flex>
              </Flex>
            )
            }
          </Flex>
        </SoftCard>
      </Flex>
    </AuthenticatedLayout>
  );
};