import React from 'react';
import { Flex } from '@chakra-ui/react';
// import { Flex, Box } from '@chakra-ui/react';
import { Sidebar, MainPanel, MainContent } from '../components/layout';
import { LoadingBar } from '../components/generic/LoadingBar';
import WakeLockComponent from '../components/engine/WakeLock';
import { CompletionLoadingScreen } from '../components/engine/completion-loading-screen';

interface AuthenticatedLayoutProps {
  children: React.ReactNode;
  sidebar?: boolean;
}

export const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({ children, sidebar = true }) => {
  return (
    <Flex flexDir="column" h='100vh' tabIndex={-1}>
      {/* <Box display="none"> */}
      <WakeLockComponent />
      {/* </Box> */}
      <Sidebar hidden={!sidebar} />
      <MainPanel clearSidebar={sidebar}>
        <MainContent>{children}</MainContent>
        <CompletionLoadingScreen />
      </MainPanel>
      <LoadingBar full={sidebar} />
    </Flex>
  );
};
