export const plans = {
    STARTER_MONTHLY: {
        title: 'Starter',
        description: 'A plan that fits your customers basic needs',
        price: 49.99,
        features: [
            'Model 1',
            '1 project',
            '1 deployment',
            '2 pages per project',
            '3 templates',
            '3 UI libraries',
            { a: '1 GB/month of bandwidth', b: 'For very low traffic sites with up to 500 visitors/month.' },
            { a: '100 form submissions', b: 'For personal or small websites with low interaction.' },
            '2 languages translation per site',
            'Code auto-fixer',
            'SSL certificate',
            'Starter prompts',
            'Build on mobile'
        ]
    },
    PROFESSIONAL_MONTHLY: {
        title: 'Professional',
        description: 'A plan that fits most of your customers needs',
        price: 99.99,
        features: [
            'Model 1, 2 and 4.',
            'Voice mode',
            '5 projects',
            '5 deployments',
            '5 pages per project',
            '10 templates',
            '10 UI libraries',
            { a: '2 GB/month of bandwidth', b: 'For sites expecting up to 1200 visitors/month.' },
            { a: '2,500 form submissions', b: 'For small businesses and medium-traffic sites.' },
            '5 languages translation per site',
            'Image generation (1 model)',
            'Images auto-fixer',
            'Code auto-fixer',
            'SSL certificate',
            'Starter prompts',
            'Build on mobile',
            'Styles enhancer',
            'Developer mode',
        ]
    },
    ELITE_MONTHLY: {
        title: 'Elite',
        description: 'A plan that fits all your customers needs',
        price: 199.99,
        features: [
            'Model 1, 2, 3, and 4.',
            'Voice mode',
            '10 projects',
            '10 deployments',
            '15 pages per project',
            'All templates',
            'All UI libraries',
            { a: '5 GB/month of bandwidth', b: 'For more active sites with up to 3000 visitors/month.' },
            { a: '10,000 form submissions', b: 'For active business websites with higher interaction.' },
            '10 languages translation',
            'Image generation (2 models)',
            'AI actions',
            'Images auto-fixer',
            'Code auto-fixer',
            'SSL certificate',
            'Starter prompts',
            'Build on mobile',
            'Styles enhancer',
            'Developer mode',
            'Export project'
        ]
    },
    STARTER_ANNUAL: {
        title: 'Starter',
        description: 'A plan for businesses who are just starting out.',
        price: 39.99,
        amount_saved: 120,
        features: [
            'Model 1',
            '1 project',
            '1 deployment',
            '2 pages per project',
            '3 templates',
            '3 UI libraries',
            { a: '1 GB/month of bandwidth', b: 'For very low traffic sites with up to 500 visitors/month.' },
            { a: '100 form submissions', b: 'For personal or small websites with low interaction.' },
            '2 languages translation per site',
            'Code auto-fixer',
            'SSL certificate',
            'Starter prompts',
            'Build on mobile'
        ]
    },
    PROFESSIONAL_ANNUAL: {
        title: 'Professional',
        description: 'A plan that will have you covered for most of your business needs.',
        price: 79.99,
        amount_saved: 240,
        features: [
            'Model 1, 2 and 4.',
            'Voice mode',
            '5 projects',
            '5 deployments',
            '5 pages per project',
            '10 templates',
            '10 UI libraries',
            { a: '2 GB/month of bandwidth', b: 'For sites expecting up to 1200 visitors/month.' },
            { a: '2,500 form submissions', b: 'For small businesses and medium-traffic sites.' },
            '5 languages translation per site',
            'Image generation (1 model)',
            'Images auto-fixer',
            'Code auto-fixer',
            'SSL certificate',
            'Starter prompts',
            'Build on mobile',
            'Styles enhancer',
            'Developer mode'
        ]
    },
    ELITE_ANNUAL: {
        title: 'Elite',
        description: 'A plan for big enterprises.',
        price: 169.99,
        amount_saved: 360,
        features: [
            'Model 1, 2, 3, and 4.',
            'Voice mode',
            '10 projects',
            '10 deployments',
            '15 pages per project',
            'All templates',
            'All UI libraries',
            { a: '5 GB/month of bandwidth', b: 'For more active sites with up to 3000 visitors/month.' },
            { a: '10,000 form submissions', b: 'For active business websites with higher interaction.' },
            '10 languages translation',
            'Image generation (2 models)',
            'AI actions',
            'Images auto-fixer',
            'Code auto-fixer',
            'SSL certificate',
            'Starter prompts',
            'Build on mobile',
            'Styles enhancer',
            'Developer mode',
            'Export project'
        ]
    }
};