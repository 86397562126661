import { atom,selector} from 'recoil';

export const loadingState = atom({
  key: 'loading',
  default: false,
});

export const IsGeneratingSpinner = atom({
  key: 'is-generating-spinner',
  default: false,
});

export const IsGeneratingLoadingScreen = atom({
  key: 'is-generating-loading-screen',
  default: false,
});

export const IsGeneratingImageSpinner = atom({
  key: 'is-generating-image-spinner',
  default: false,
});

export const IsTranscribingSpinner = atom({
  key: 'is-transcribing-spinner',
  default: false,
});

export const IsDeployingSpinner = atom({
  key: 'is-deploying-spinner',
  default: false,
});

export const IsUploadingSpinner = atom({
  key: 'is-uploading-spinner',
  default: false,
});

export const MessageGroupTypes = {
  GENERATING: 'generating',
  AUTOMATING: 'automating',
  TRANSLATING: 'translating',
};

export const CurrentMessageGroupType = atom({
  key: 'current-message-group-type',
  default: MessageGroupTypes.GENERATING,
});

// Define message groups
export const messageGroups = {
  [MessageGroupTypes.GENERATING]: [
    "Crafting your custom creation—just a moment!",
    "Bringing your vision to life, tailored precisely to your request.",
    "Transforming ideas into reality with AI-powered precision.",
    "Building each element with care, just as you envisioned.",
    "Curating a digital experience unique to your needs.",
    "Turning your input into a seamless, interactive experience.",
    "Generating a solution that aligns perfectly with your goals.",
    "Refining every detail to match your creative vision.",
    "Your custom creation is almost ready—thank you for waiting!",
    "Designing an experience that’s uniquely yours.",
    "Bringing each element into harmony, tailored for you.",
    "Translating your ideas into a polished digital solution.",
    "Just a moment—finalizing the details of your request.",
    "Every pixel, every element is being fine-tuned for you.",
    "Working behind the scenes to bring your request to life.",
    "Creating with precision and attention to your specifications.",
    "Setting up the perfect digital experience, as requested.",
    "Your customized build is in progress—almost there!",
    "Balancing creativity and precision to meet your goals.",
    "Nearly there! Crafting a personalized experience for you.",
  ],
  [MessageGroupTypes.AUTOMATING]: [
   "Setting up your custom site, tailored to your brand’s unique identity.",
    "Automating your template for a seamless, ready-to-go experience.",
    "Building the foundation for your business’s online presence.",
    "Crafting a site that speaks directly to your brand’s vision.",
    "Personalizing every detail to showcase your brand’s personality.",
    "Applying your business details to create a truly unique site.",
    "Bringing your template to life with your brand in mind.",
    "Making adjustments to align perfectly with your business goals.",
    "Your site is coming together—unique, professional, and ready.",
    "Spinning up a custom site tailored precisely for you.",
    "Generating a polished, brand-ready site for immediate deployment.",
    "Crafting your online presence with automated precision.",
    "Creating a website that represents your brand beautifully.",
    "Optimizing the template to match your unique business style.",
    "Finalizing your custom site—just a moment more!",
    "Setting up your brand’s digital stage for launch.",
    "Refining the final touches for a cohesive, branded look.",
    "Automating your brand’s story into a ready-to-go site.",
    "Transforming your chosen template into a fully customized site.",
    "Building a site that’s ready to make a lasting impression.",
    "Just a moment—your site is nearly ready for the spotlight!",
  ],
  [MessageGroupTypes.TRANSLATING]: [
    "Adapting your message for a global audience—almost there!",
    "Breaking language barriers for your brand, one phrase at a time.",
    "Crafting a seamless experience for every language.",
    "Your site is about to speak the language of your customers.",
    "Connecting with a wider world, please hold tight!",
    "Good localization means a better user experience everywhere.",
    "Translating to create a genuine connection with new audiences.",
    "Making your message clear across cultures—just a moment!",
    "Building trust with accurate, thoughtful translations.",
    "Creating a tailored experience for each language, nearly done!",
    "Opening doors to new markets with the power of localization.",
    "Going global starts here—finalizing your multilingual experience.",
    "Preparing your site to engage users everywhere.",
    "Translation underway—expanding your brand’s reach.",
    "Building bridges to new customers in their language.",
    "Empowering your message to resonate globally.",
    "Aligning your content with each culture’s unique flavor.",
    "Translating with care for a polished, natural experience.",
    "Your site is transforming to connect with the world—hang tight!",
    "Final touches for a seamless multilingual experience."
  ],
};

// Selector for getting current message group
export const CurrentMessageGroup = selector({
  key: 'current-message-group',
  get: ({get}) => {
    const groupType = get(CurrentMessageGroupType);
    return messageGroups[groupType];
  },
});