// deno-lint-ignore-file no-explicit-any

export function isAddEventListener(node: any) {
    return (
        node.expression &&
        node.expression.callee &&
        node.expression.callee.property &&
        node.expression.callee.property.name === 'addEventListener'
    );
}

export function isFunctionCall(node: any) {
    return (
        node.expression &&
        node.expression.callee &&
        node.expression.callee.name && 
        node.expression.type === 'CallExpression'
    );
}