import { atom } from 'recoil';

export const ConversationHistory = atom({
  key: 'conversation-history',
  default: [
    {
      role: 'system',
      content: ''
    },
  ]
});

// export const ConversationHistory = atom({
//   key: 'conversation-history',
//   default: [
//     {
//       role: 'system',
//       content: defaultSystemMessage
//     },
//   ]
// });
