import React, { useState, useRef, useEffect } from 'react';
import { Badge, Button } from '@chakra-ui/react';
import { Flex, Tooltip, Input, IconButton, Heading, Select, Text, Stack, Radio, RadioGroup } from '@chakra-ui/react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { PagesPaths, GoToPageFromLink, CreatePageFromLink, PageLinks, LocalContext, LocalContextId, selectedPageSelector, PageLinksHaveBeenUpdated, pagesState, UserPreferences } from '../../state';
import { SoftCard } from '../../components/generic/SoftCard';
import { IoLinkSharp } from "react-icons/io5";
import { MdSave } from "react-icons/md";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

import '../../lib/anim.css';
import { usePageActions } from '../../hooks';

const LinkComponent = ({
  link,
  index,
  handleInputChange,
  handleEnterKeyPress,
  pages,
  handleSaveChanges,
  inputValues,
  setInputValues,

  setPageLinks,
  selectedPage,
  updateLinks,
  setPageLinksHaveBeenUpdated
 }: any) => {

  // console.log(link);
  // console.log(link.parent_id);
  const pagesPaths = useRecoilValue<any>(PagesPaths);
  const [createPageFromLink, setCreatePageFromLink] = useRecoilState<any>(CreatePageFromLink);
  const [goToPageFromLink, setGoToPageFromLink] = useRecoilState<any>(GoToPageFromLink);
  // const [isCreatedPageFromLink, setIsCreatedPageFromLink] = useRecoilState<any>(IsCreatedPageFromLink);

  // console.log(pagesPaths, link.href, pagesPaths.includes(link.href));

  const handleGoToPage = (pagePath: string) => {
    setGoToPageFromLink(pagePath);
  }

  const handleCreatePageFromLink = async (pageName: string) => {
    setCreatePageFromLink(pageName);

    // Modify inputValues here and save...
    const newInputValues = inputValues.map((iv: any, i: number) => {
      if (index === i) {
        return {
          ...iv,
          type: 'internal',
          href: `${iv.inner.toLowerCase().split(' ').join('-')}.html`
        }
      } else {
        return iv;
      }
    })

    setInputValues(newInputValues);

    setPageLinks(newInputValues);

    console.log(selectedPage);

    if (selectedPage) await updateLinks(selectedPage.id, newInputValues);
    
    setPageLinksHaveBeenUpdated(true);

    // await handleSaveChanges();

  }

  // useEffect(() => {
  //   if (isCreatedPageFromLink) {
  //     // Need to change its radio to page
  //     // And its path
  //     // Keep its inner

  //     // Update inputValues
  //     const newInputValues = inputValues.map((iv: any, i: number) => {
  //       if (index === i) {
  //         console.log(iv);
  //       }
  //     })

  //     // console.log(inputValues);

  //     // setInputValues((previousValue: any) => {

  //     //   return previousValue.map((individualValue: any, i: number) => {

  //     //     console.log(individualValue);
  //     //     // Need to find link by index
  //     //     if (i === index) {
  //     //       return {
  //     //         ...individualValue,
  //     //         type: 'internal',
  //     //         href: `${individualValue.inner.toLowerCase().split(' ').join('-')}.html`
  //     //       };
  //     //     }
  //     //   })

  //     // }
  //     // );

  //     // Save changes and reflect on DOM.
  //     // handleSaveChanges();
  //     setIsCreatedPageFromLink(null);
  //   }
  // }, [isCreatedPageFromLink])

  return (
    <Flex key={index} gap={3} flexDir='column' alignItems='flex-start'>
      <Breadcrumb alignSelf="center" fontSize='xs' separator={<ChevronRightIcon color='gray.500' />}>
        {link.parent_id && (
          <BreadcrumbItem>
            <BreadcrumbLink>{link.parent_id}</BreadcrumbLink>
          </BreadcrumbItem>
        )}

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{link.id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <RadioGroup size='sm' colorScheme='purple' onChange={(e) => handleInputChange(e, index, 'type', true)} value={link.type}>
        <Stack direction='row' justifyContent="space-between">
          <Radio value='external'>URL</Radio>
          <Radio value='internal'>Page</Radio>
        </Stack>
      </RadioGroup>

      <Flex gap={2} flexDir='column' w='full'>
        <Input size={'xs'} value={link.inner} onChange={(e) => handleInputChange(e, index, 'inner')} onKeyPress={handleEnterKeyPress} />
        {link.type === 'internal' ? (
          <Select placeholder='Select' variant='filled' size={'xs'} value={link.href} onChange={(e) => handleInputChange(e, index, 'href')}>
            {(pages.length > 0) && pages.map((page: any, index: number) => (
              <option key={index} value={page.path}>{page.title}{page.locale !== 'Default' ? ' | ' + page.locale : ''}</option>
            ))}
          </Select>
        ) : (
          <Input size={'xs'} value={link.href} onChange={(e) => handleInputChange(e, index, 'href')} onKeyPress={handleEnterKeyPress} />
        )}
      </Flex>

      {/* Only display this button if Link isnt linking to current page, like a link for Home while we are in Home */}
      <Flex w="full" gap={2} justifyContent="space-between">
        <Button isDisabled={pagesPaths.includes(link.href)} flexGrow={1} size="xs" onClick={() => handleCreatePageFromLink(link.inner)} colorScheme="purple" variant='outline'>Create</Button>
        <Button isDisabled={!pagesPaths.includes(link.href)} flexGrow={1} size="xs" onClick={() => handleGoToPage(link.href)} colorScheme="purple" variant='outline'>Go to</Button>
      </Flex>
    </Flex>
  );
}

export const Links: React.FC<any> = () => {

  const userPreferences = useRecoilValue(UserPreferences);

  const selectedPage = useRecoilValue(selectedPageSelector);
  const [pageLinks, setPageLinks] = useRecoilState(PageLinks);
  const [inputValues, setInputValues] = useState(pageLinks);
  const { updateLinks } = usePageActions();
  const pages = useRecoilValue(pagesState);

  const localContextId = useRecoilValue(LocalContextId);
  const localContext = useRecoilValue<any>(LocalContext);

  const setPageLinksHaveBeenUpdated = useSetRecoilState(PageLinksHaveBeenUpdated);


  useEffect(() => {
    if (pageLinks.length > 0) setInputValues(pageLinks);
  }, [pageLinks]);

  const handleInputChange = (event: any, index: number, property: string, is_radio: boolean = false) => {
    setInputValues((previousValue: any) =>
      previousValue.map((value: any, i: number) =>
        i === index
          ? { ...value, [property]: is_radio ? event : event.target.value }
          : value
      )
    );
  };

  // const handleInputChange = (event: any, index: number, property: string, is_radio: boolean = false) => {
  //   setInputValues((previousValues: any) => {
  //     return previousValues.map((currentValue: any, i: number) => {
  //       if (i === index) {
  //         if (is_radio) {
  //           // If it's a radio input, set the entire event as the property value
  //           return { ...currentValue, [property]: event };
  //         } else {
  //           // If it's not a radio input, set the value of the target element of the event
  //           return { ...currentValue, [property]: event.target.value };
  //         }
  //       } else {
  //         // For other elements, keep the value unchanged
  //         return currentValue;
  //       }
  //     });
  //   });
  // };


  const handleSaveChanges = () => {
    setPageLinks(inputValues);
    // Also I need to actually update database, not only local state.
    if (selectedPage) updateLinks(selectedPage.id, inputValues);
    // Then reflect it in the dom somewhere, like devmode is updated or something, whenever these change maybe..
    setPageLinksHaveBeenUpdated(true);
  }

  const handleEnterKeyPress = (event: any) => {
    if (event.key === 'Enter')
      handleSaveChanges();
  }

  // useEffect(() => {
  //   if (!localContextId && !localContextHTML) return;

  // }, [localContextId, localContextHTML])

  return (
    <SoftCard h='100%' minH='0' flex={2}>
      <Flex className='zoom' flexDir='column' justify='space-between' gap={4} h='full'>

        <Flex alignItems='end' gap={2}>
          <IoLinkSharp />
          <Heading as='h5' size='sm'>
            Links
          </Heading>
        </Flex>

        <Flex flex={1} flexDir='column' gap={4} overflowY='auto' css={{ "::-webkit-scrollbar": { display: "none" } }}>
          {pageLinks.length > 0 ? (
            <>
              {
                (inputValues.length > 0) && inputValues.map((link: any, index: number) => {
                  if (!link) return;

                  if (localContext !== null && localContext.tagName === 'A') {
                    if (link.id === localContextId) {
                      return <LinkComponent key={index} link={link} index={index} handleInputChange={handleInputChange} handleEnterKeyPress={handleEnterKeyPress} pages={pages} handleSaveChanges={handleSaveChanges} inputValues={inputValues} setInputValues={setInputValues} setPageLinks={setPageLinks} updateLinks={updateLinks} selectedPage={selectedPage} setPageLinksHaveBeenUpdated={setPageLinksHaveBeenUpdated} />;
                    }
                  } else {
                    return <LinkComponent key={index} link={link} index={index} handleInputChange={handleInputChange} handleEnterKeyPress={handleEnterKeyPress} pages={pages} handleSaveChanges={handleSaveChanges} inputValues={inputValues} setInputValues={setInputValues} setPageLinks={setPageLinks} updateLinks={updateLinks} selectedPage={selectedPage} setPageLinksHaveBeenUpdated={setPageLinksHaveBeenUpdated} />;
                  }
                })
              }
            </>
          ) : (
            <>
              <Flex flexDir='column' justifyContent='center' alignItems='center' p={4}>
                <Text fontSize='sm' >There are no links in the current page.</Text>
              </Flex>
            </>
          )}
        </Flex>

        <Flex justifyContent='flex-end'>
          <Tooltip gutter={11} placement='auto' label='Update: Save and apply changes.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              onClick={handleSaveChanges}
              size="sm"
              colorScheme='purple'
              aria-label="Update"
              title="Update"
              icon={<MdSave />}
            />
          </Tooltip>
        </Flex>

      </Flex>
    </SoftCard>
  );
};
