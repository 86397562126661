import { atom, selector } from 'recoil';

export const LocalContext = atom({
    key: 'local-context',
    default: null
});

export const LocalContextId = selector({
    key: 'local-context-id-selector',
    get: ({ get }) => {
        const localContext: any = get(LocalContext);
        if (localContext) {
            return localContext.id;
        }
        return null;
    }
});

export const LocalContextOuterHTML = selector({
    key: 'local-context-outer-html-selector',
    get: ({ get }) => {
        const localContext: any = get(LocalContext);
        if (localContext) {
            return localContext.outerHTML;
        }
        return null;
    }
});

export const LocalContextCSS = atom({
    key: 'local-context-css',
    default: ''
});

export const GlobalContextOuterHTML = atom({
    key: 'global-context-outer-html',
    default: ''
});