import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { projectsState, currentUserState, UserAllottedCredits } from '../state';
import { useApiActions } from './api-actions';

export const useCloudFrontActions = () => {
    const { executeApiAction } = useApiActions();
    const setProjects = useSetRecoilState(projectsState);
    const setUserAllottedCredits = useSetRecoilState(UserAllottedCredits);

    const createSite = async (userId: string, projectId: string, name: string) =>
        await executeApiAction({
            action: () => ApiClient.createCloudFrontSite(userId, projectId, name),
            errorMessage: 'Failed to create site.',
            successMessage: 'Site created successfully.',
        });

    const deploySite = async (projectFormData: any) =>
        await executeApiAction({
            action: () => ApiClient.deployCloudFrontSite(projectFormData),
            errorMessage: 'Failed to deploy site.',
            successMessage: 'Site deployed successfully.',
        });
        const updateSite = async (projectFormData: any) => {
            console.log('Updating site with form data:', projectFormData);
            return await executeApiAction({
              action: () => ApiClient.updateCloudFrontSite(projectFormData),
              errorMessage: 'Failed to update site.',
              successMessage: 'Site updated successfully.',
            });
          };

    const saveSite = async (site_id: string, user_id: string, project_id: string, name: string, url: string) =>
        await executeApiAction({
            action: () => ApiClient.saveCloudFrontSite(site_id, user_id, project_id, name, url),
            onSuccess: async () => {
                setProjects((previousProjects: any) => {
                    return previousProjects.map((project: any) => {
                        if (project.id === project_id) {
                            return {
                                ...project,
                                is_published: true,
                            };
                        }
                        return project;
                    });
                });

                const { me } = await ApiClient.getMe();
                setUserAllottedCredits({
                    projects_allowed: me.projects_allowed,
                    sites_allowed: me.sites_allowed,
                    forms_allowed: me.forms_allowed,
                    exports_allowed: me.exports_allowed,
                    submissions_allowed: me.submissions_allowed,
                    bandwidth_allowed: me.bandwidth_allowed,
                    pages_allowed: me.pages_allowed,
                    translations_allowed: me.translations_allowed
                })
            },
            errorMessage: 'Failed to save site.',
            successMessage: 'Site saved successfully.',
        });

    const deleteSite = async (site_id: string, project_id: string) =>
        await executeApiAction({
            action: () => ApiClient.deleteCloudFrontSite(site_id, project_id),
            onSuccess: () => {
                setProjects((previousProjects: any) => {
                    return previousProjects.map((project: any) => {
                        if (project.id === project_id) {
                            return {
                                ...project,
                                is_published: false,
                            };
                        }
                        return project;
                    });
                });
            },
            errorMessage: 'Failed to delete site.',
            successMessage: 'Site deleted',
        });

    const fetchSite = async (project_id: string) =>
        await executeApiAction({
            action: () => ApiClient.fetchCloudFrontSite(project_id),
            errorMessage: 'Failed to fetch site.',
            successMessage: 'Site fetched successfully.',
        });

    const fetchSites = async (user_id: string) =>
        await executeApiAction({
            action: () => ApiClient.fetchCloudFrontSites(user_id),
            errorMessage: 'Failed to fetch sites.',
            successMessage: 'Sites fetched successfully.',
        });

    return {
        createSite: useCallback(createSite, [executeApiAction]),
        deploySite: useCallback(deploySite, [executeApiAction]),
        updateSite: useCallback(updateSite, [executeApiAction]),
        saveSite: useCallback(saveSite, [executeApiAction]),
        deleteSite: useCallback(deleteSite, [executeApiAction]),
        fetchSite: useCallback(fetchSite, [executeApiAction]),
        fetchSites: useCallback(fetchSites, [executeApiAction]),
    };
};