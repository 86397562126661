import React, { useRef, useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { ViewportTree, PageImages, ConversationHistory, HTMLCode, CSSCode, JavaScriptCode, ContentUndoHistory, ConversationHistoryUndoHistory, ViewportSourceDocument, UndoHistoryStepIndex, DidIUndo, selectedPageSelector, PageLinks, PageForms, currentUserState, GlobalContextOuterHTML } from '../../state';
import { Flex, Box, useColorModeValue, Text, Heading, useBreakpointValue } from '@chakra-ui/react';
import { SoftCard } from '../generic/SoftCard';
import { usePageActions, useSIFOActions } from '../../hooks';
import { Badge } from '@chakra-ui/react'
import { MdUndo } from "react-icons/md";
import '../../lib/anim.css';
import { hasBackgroundImage, constructPageLinksArray, constructPageFormsArray, constructPageImagesArray } from './links-tools';

export const Undo: React.FC<any> = () => {

  const isWideScreen = useBreakpointValue({ base: false, md: true });


  const { updatePageContent, updatePageConversationHistory } = usePageActions();

  const [contentUndoHistory, setContentUndoHistory] = useRecoilState(ContentUndoHistory);
  // console.log('contentUndoHistory: ', contentUndoHistory);

  const [conversationHistoryUndoHistory, setConversationHistoryUndoHistory] = useRecoilState(ConversationHistoryUndoHistory);
  const [viewportSourceDocument, setViewportSourceDocument] = useRecoilState<any>(ViewportSourceDocument);
  const [undoHistoryStepIndex, setUndoHistoryStepIndex] = useRecoilState(UndoHistoryStepIndex);
  const [didIUndo, setDidIUndo] = useRecoilState(DidIUndo);
  const scrollBarColor = useColorModeValue('#38B2AC', '#81E6D9');

  const user = useRecoilValue(currentUserState);
  const selected_page = useRecoilValue(selectedPageSelector);

  const { buildContextTree } = useSIFOActions();
  const setViewportTree = useSetRecoilState(ViewportTree);
  const setGlobalContextOuterHTML = useSetRecoilState(GlobalContextOuterHTML);

  const [pageLinks, setPageLinks] = useRecoilState(PageLinks);
  const [pageForms, setPageForms] = useRecoilState(PageForms);
  const [pageImages, setPageImages] = useRecoilState(PageImages);
  const { updateLinks, updateForms, updateImages } = usePageActions();

  const [conversationHistory, setConversationHistory] = useRecoilState(ConversationHistory);

  const [htmlCode, setHTMLCode] = useRecoilState(HTMLCode);
  const [cssCode, setCSSCode] = useRecoilState(CSSCode);
  const [javascriptCode, setJavaScriptCode] = useRecoilState(JavaScriptCode);

  const undoStepColor = useColorModeValue('purple.500', 'purple.200');
  const undoStepHoverBackgroundColor = useColorModeValue('gray.200', 'gray.600');

  const handleGotoUndoStep = (contentUndoStep: any, stepIndex: number) => {

    // setConversationHistory(conversationHistoryUndoHistory[stepIndex]);

    console.log("contentUndoHistory: ", contentUndoHistory);
    console.log("contentUndoStep: ", contentUndoStep);
    // setViewportSourceDocument(null);
    setViewportSourceDocument(contentUndoStep);

    const parser = new DOMParser();
    let pageDocument = parser.parseFromString(contentUndoStep, 'text/html');

    /*
    Legacy way of doing things, using the viewportTree for context,
    and a specific format of completions.
    */

    // const viewportTree = buildContextTree(pageDocument);
    // setViewportTree(viewportTree);

    /*
    New method of doing things, context is plain html,
    and we remove contenteditable from context because it is not needed,
    as it is injected afterwards.
    */

    const bodyElement = pageDocument.querySelector("div#body");

    if (bodyElement) {
      bodyElement.querySelectorAll('[contenteditable]').forEach(element => element.removeAttribute('contenteditable'));
      setGlobalContextOuterHTML(bodyElement.innerHTML);
    }

    // setGlobalContextOuterHTML(pageDocument.querySelector("div#body")!.innerHTML);
    // setGlobalContextOuterHTML(pageDocument.querySelector("div#body")!.outerHTML);

    // const bodyElement = pageDocument.querySelector('div#body'); // already declared.
    const styleElement = pageDocument.querySelector('style[data-dev-tag="inject-into"]');
    const scriptElement = pageDocument.querySelector('script[data-dev-tag="inject-into"]');

    if (bodyElement) setHTMLCode(bodyElement.innerHTML);
    if (styleElement) setCSSCode(String(styleElement.textContent));
    if (scriptElement) setJavaScriptCode(scriptElement.innerHTML);


    // Forms
    const pageFormTags: any = pageDocument.querySelectorAll('form');
    if (pageFormTags.length > 0 && selected_page && user) {
      const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
      setPageForms(pageForms);
      if (selected_page) updateForms(selected_page.id, pageForms);
    } else {
      setPageForms([]);
      if (selected_page) updateForms(selected_page.id, pageForms);
    }

    const pageAmchorTags: any = pageDocument.querySelectorAll('a');
    if (pageAmchorTags.length > 0) {
      const pageLinks: any = constructPageLinksArray(pageAmchorTags);
      setPageLinks(pageLinks);
      if (selected_page) updateLinks(selected_page.id, pageLinks);
    } else {
      setPageLinks([]);
      if (selected_page) updateLinks(selected_page.id, pageLinks);
    }

    const allElements: any = pageDocument.querySelectorAll('*');

    const imageElements: any = Array.from(allElements).filter((element: any) => {
      if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
        return true;
      }
    })

    if (imageElements.length > 0) {
      const pageImages: any = constructPageImagesArray(imageElements);
      setPageImages(pageImages);
      if (selected_page) updateImages(selected_page.id, pageImages);
    } else {
      setPageImages([]);
      if (selected_page) updateImages(selected_page.id, pageImages);
    }



    // const pageImgTags: any = viewport.querySelectorAll('img');
    // if (pageImgTags.length > 0) {
    //   const pageImages: any = constructPageImagesArray(pageImgTags);
    //   setPageImages(pageImages);
    //   if (selected_page) updateImages(selected_page.id, pageImages);
    // }

    if (selected_page) {
      updatePageContent(selected_page.id, contentUndoStep);
      updatePageConversationHistory(selected_page.id, conversationHistoryUndoHistory[stepIndex]);
    }

    setUndoHistoryStepIndex(stepIndex);
    setDidIUndo(true);
  }

  const lastUndoStepRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastUndoStepRef.current) {
      if (isWideScreen) lastUndoStepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [contentUndoHistory]);

  return (
    <>
      <SoftCard h='100%' minH='0' flex={1}>
        {/* <Box className='zoom'> */}
        <Flex className='zoom' flexDir='column' h='full' gap={4}>
          <Flex gap={2} alignItems='start'>
            <MdUndo />
            <Heading as='h5' size='sm'>
              History
            </Heading>
          </Flex>
          <Flex flexDirection="column" gap={3} overflowY="auto" css={{ "::-webkit-scrollbar": { width: "5px", display: "none" }, "::-webkit-scrollbar-thumb": { backgroundColor: scrollBarColor } }}>
            {
              contentUndoHistory.map((contentUndoStep: any, index) => (
                <Flex
                  cursor='pointer'
                  onClick={() => handleGotoUndoStep(contentUndoStep.content, index)}
                  key={index}
                  gap={4}
                  alignItems='center'
                  _hover={{ bg: undoStepHoverBackgroundColor }}
                  ref={index === contentUndoHistory.length - 1 ? lastUndoStepRef : null}

                >
                  {index === undoHistoryStepIndex ? (
                    <Badge fontSize='xs' variant='solid' colorScheme='purple'>&nbsp;↲</Badge>
                  ) : (
                    <Badge fontSize='xs' variant='solid' colorScheme='purple'>&nbsp;&nbsp;&nbsp;</Badge>
                  )}

                  <Flex flexDir='column'>
                    <Text fontWeight='bold' rounded='sm' fontSize={'sm'}>{contentUndoStep.label}</Text>
                    <Text rounded='sm' fontSize={'sm'}>{contentUndoStep.timestamp}</Text>
                  </Flex>

                </Flex>
              ))
            }
          </Flex>
        </Flex>
        {/* </Box> */}
      </SoftCard>
    </>
  );
};