import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
  colors: {
    gray: {
      900: '#0d0d0d',
      800: '#121212',
      700: '#151515',
      600: '#2c2c2c',
      500: '#737373',
      400: '#8c8c8c',
      300: '#a6a6a6',
      200: '#ececec',
      100: '#d9d9d9',
      // 50: '#f2f2f2'
    },
    purple: {

    }
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'system-ui, sans-serif',
    mono: 'Consolas, monospace',
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode('gray.50', 'gray.900')(props),
      },
      'html, body, #root': {
        minHeight: '100vh',
      },
    }),
  },
};
