import { atom } from 'recoil';

export const HTMLCode = atom({
    key: 'html-code',
    default: ''
});

export const CSSCode = atom({
    key: 'css-code',
    default: ''
});

export const JavaScriptCode = atom({
    key: 'javascript-code',
    default: ''
});

export const IsUpdated = atom({
    key: 'is-updated',
    default: false
});