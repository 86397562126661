import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Tooltip, useColorModeValue, IconButton } from '@chakra-ui/react';
import { SoftCard } from '../generic/SoftCard';
import { HTMLCode, CSSCode, JavaScriptCode, LocalContext, LocalContextId, LocalContextCSS, LocalContextOuterHTML, IsUpdated, UserPreferences } from '../../state';
import { MdSave, MdRefresh, MdCleaningServices } from "react-icons/md";
import { IoLogoHtml5, IoLogoCss3 } from "react-icons/io";
import { IoLogoJavascript } from "react-icons/io5";
import beautify_js from 'js-beautify';
import { css as beautify_css, html as beautify_html } from 'js-beautify';
import { cssjs } from 'jotform-css.js';
import { getIDsRecursively, deleteCSSRulesBySelectors, writeCSSFromRules } from './css-tools';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/base16-dark.css';
import 'codemirror/theme/base16-light.css';
import './cm.css';
import '../../lib/anim.css';

export const DevMode: React.FC<any> = () => {
    const [cssCode, setCSSCode] = useRecoilState(CSSCode);
    const [htmlCode, setHTMLCode] = useRecoilState(HTMLCode);
    const [javascriptCode, setJavaScriptCode] = useRecoilState(JavaScriptCode);

    const [isCSSEditorVisible, setIsCSSEditorVisible] = useState(true);
    const [isHTMLEditorVisible, setIsHTMLEditorVisible] = useState(true);
    const [isJavascriptEditorVisible, setIsJavascriptEditorVisible] = useState(true);
    const [htmlEditorValue, setHTMLEditorValue] = useState(beautify_html(htmlCode));
    const [cssEditorValue, setCSSEditorValue] = useState(beautify_css(cssCode));
    const [javascriptEditorValue, setJavaScriptEditorValue] = useState(beautify_js(javascriptCode));

    const userPreferences = useRecoilValue(UserPreferences);
    const localContextCSS = useRecoilValue(LocalContextCSS);
    const localContext: any = useRecoilValue(LocalContext);
    const localContextId: any = useRecoilValue(LocalContextId);
    const localContextOuterHTML: any = useRecoilValue(LocalContextOuterHTML);

    const setIsUpdated = useSetRecoilState(IsUpdated);

    const editorTheme = useColorModeValue('base16-light', 'base16-dark');

    const handleUnfocusAll = () => {
        setIsHTMLEditorVisible(true);
        setIsCSSEditorVisible(true);
        setIsJavascriptEditorVisible(true);
    }

    const handleFocusHTML = () => {
        if (isHTMLEditorVisible === false) {
            setIsHTMLEditorVisible(true);
        }

        setIsCSSEditorVisible(false);
        setIsJavascriptEditorVisible(false);
    }

    const handleFocusCSS = () => {
        if (isCSSEditorVisible === false) {
            setIsCSSEditorVisible(true);
        }

        setIsHTMLEditorVisible(false);
        setIsJavascriptEditorVisible(false);
    }

    const handleFocusJavascript = () => {
        if (isJavascriptEditorVisible === false) {
            setIsJavascriptEditorVisible(true);
        }

        setIsHTMLEditorVisible(false);
        setIsCSSEditorVisible(false);
    }

    const cleanDevelopmentAttributes = (html: string) => {
        const parser = new DOMParser();
        const parsed = parser.parseFromString(html, 'text/html');

        const elemsWithDevAttribs = parsed.querySelectorAll('[data-parent]');
        elemsWithDevAttribs.forEach(elem => {
            elem.removeAttribute('data-parent');
            elem.removeAttribute('data-is-before');
            elem.removeAttribute('data-is-after');
        });

        const contentEditableElements = parsed.querySelectorAll('[contenteditable]');
        contentEditableElements.forEach(elem => {
            elem.removeAttribute('contenteditable');
        });

        const manualEditorElements = parsed.querySelectorAll('.sifo-manual-editor');
        manualEditorElements.forEach((meElement: any) => meElement.remove());

        return parsed.body.innerHTML;
    }

    useEffect(() => {
        if (!localContextId) {
            setHTMLEditorValue(beautify_html(
                cleanDevelopmentAttributes(htmlCode)
            ));
        }
    }, [htmlCode])

    useEffect(() => {
        if (!localContextId)
            setCSSEditorValue(beautify_css(cssCode));
    }, [cssCode])

    useEffect(() => {
        setJavaScriptEditorValue(beautify_js(javascriptCode));
    }, [javascriptCode])

    useEffect(() => {
        if (localContextId !== null && localContextOuterHTML !== null) {
            console.log('localContextOuterHTML: ', localContextOuterHTML);
            setHTMLEditorValue(beautify_html(
                cleanDevelopmentAttributes(localContextOuterHTML)
            ));
            setCSSEditorValue(beautify_css(localContextCSS));
        } else {
            setHTMLEditorValue(beautify_html(
                cleanDevelopmentAttributes(htmlCode)
            ));
            setCSSEditorValue(beautify_css(cssCode));
        }
    }, [localContextId, localContextOuterHTML])
    // }, [localContextId])

    useEffect(() => {
        if (localContextId && localContextCSS)
            setCSSEditorValue(beautify_css(localContextCSS));
    }, [localContextCSS])

    const handleUpdate = () => {
        const parser = new DOMParser();

        if (localContextId) {
            const parsedGlobalHTML = parser.parseFromString(htmlCode, 'text/html');
            const parsedLocalHTML = parser.parseFromString(htmlEditorValue, 'text/html');

            const oldChild = parsedGlobalHTML.querySelector(`#${localContextId}`)
            const parent = oldChild?.parentNode;

            const newChild = parsedLocalHTML.querySelector(`#${localContextId}`);

            if (newChild && oldChild) {
                parent?.replaceChild(newChild, oldChild);
                setHTMLCode(parsedGlobalHTML.documentElement.innerHTML);
            }

            const css_parser = new cssjs();

            const selectors = getIDsRecursively(localContext);
            const parsed = css_parser.parseCSS(cssCode);
            const filtered = deleteCSSRulesBySelectors(parsed, selectors);
            const edited = css_parser.parseCSS(cssEditorValue);
            const newCSS = writeCSSFromRules(filtered.concat(edited));

            setCSSCode(newCSS);
        } else {
            const parsedHtmlEditorValue = parser.parseFromString(htmlEditorValue, 'text/html');
            // console.log('parsedHtmlEditorValue:', parsedHtmlEditorValue);

            const elems = parsedHtmlEditorValue.querySelectorAll('*');
            if (elems.length > 0) {
              elems.forEach((elem: any) => {
                const elemId = elem.getAttribute('id');
      
                if (!elemId) {
                  const tag = elem.tagName.toLowerCase() || 'unknown';
                  const randomId = Math.floor(Math.random() * 1000000);
                  elem.setAttribute('id', `${tag}-${randomId}`)
                }
              })
            };

            const newValue = parsedHtmlEditorValue.documentElement.outerHTML;
            // console.log('newValue: ', newValue);

            setHTMLCode(newValue);

            // setHTMLCode(htmlEditorValue);
            setCSSCode(cssEditorValue);
        }

        setJavaScriptCode(javascriptEditorValue);
        setIsUpdated(true);
    }

    const [hasBeenResetJS, setHasBeenResetJS] = useState(false);

    const handleResetJavascript = () => {
        setJavaScriptEditorValue('');
        setHasBeenResetJS(true);
    }

    useEffect(() => {
        if (hasBeenResetJS) {
            handleUpdate();
            setHasBeenResetJS(false);
        }
    }, [hasBeenResetJS])

    return (
        <SoftCard>
            <Flex className='zoom' flexDirection={{ base: "column", md: "row" }} gap={2} flexGrow={1}>
                {isHTMLEditorVisible && (
                    <CodeMirror
                        value={htmlEditorValue}
                        options={{
                            mode: 'xml',
                            theme: editorTheme,
                            lineNumbers: true,
                            lineWrapping: true
                        }}
                        onBeforeChange={(editor, data, value) => {
                            setHTMLEditorValue(value);
                        }}
                    />
                )}

                {isCSSEditorVisible && (
                    <CodeMirror
                        value={cssEditorValue}
                        options={{
                            mode: 'css',
                            theme: editorTheme,
                            lineNumbers: true,
                            lineWrapping: true
                        }}
                        onBeforeChange={(editor, data, value) => {
                            setCSSEditorValue(value);
                        }}
                    />
                )}

                {isJavascriptEditorVisible && (
                    <CodeMirror
                        value={javascriptEditorValue}
                        options={{
                            mode: 'javascript',
                            theme: editorTheme,
                            lineNumbers: true,
                            lineWrapping: true
                        }}
                        onBeforeChange={(editor, data, value) => {
                            setJavaScriptEditorValue(value);
                        }}
                    />
                )}

                <Flex gap={2} flexDir='column-reverse' justifyContent='space-between' alignItems='center'>
                    <Flex flexDir='column' gap={2}>
                        <Tooltip gutter={11} placement='auto' label='Unfocus all: Go back to default view.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                            <IconButton
                                onClick={handleUnfocusAll}
                                size="xs"
                                variant='ghost'
                                aria-label="Unfocus all"
                                title="Unfocus all"
                                icon={<MdRefresh />}
                            />
                        </Tooltip>

                        <Tooltip gutter={11} placement='auto' label='Focus HTML: Maximize content editor.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                            <IconButton
                                onClick={handleFocusHTML}
                                size="xs"
                                variant='ghost'
                                aria-label="Focus HTML editor"
                                title="Focus HTML editor"
                                icon={<IoLogoHtml5 />}
                            />
                        </Tooltip>

                        <Tooltip gutter={11} placement='auto' label='Focus CSS: Maximize styles editor.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                            <IconButton
                                onClick={handleFocusCSS}
                                size="xs"
                                variant='ghost'
                                aria-label="Focus CSS editor"
                                title="Focus CSS editor"
                                icon={<IoLogoCss3 />}
                            />
                        </Tooltip>

                        <Tooltip gutter={11} placement='auto' label='Focus JavaScript: Maximize logic editor.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                            <IconButton
                                onClick={handleFocusJavascript}
                                size="xs"
                                variant='ghost'
                                aria-label="Focus Javascript editor"
                                title="Focus Javascript editor"
                                icon={<IoLogoJavascript />}
                            />
                        </Tooltip>

                        <Tooltip gutter={11} placement='auto' label='Reset logic: Clear JavaScript and start from scratch.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                            <IconButton
                                onClick={handleResetJavascript}
                                size="xs"
                                variant='ghost'
                                aria-label="Reset Javascript"
                                title="Reset Javascript"
                                icon={<MdCleaningServices />}
                            />
                        </Tooltip>
                    </Flex>

                    <Tooltip gutter={11} placement='auto' label='Update: Save and apply changes.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                        <IconButton
                            onClick={handleUpdate}
                            size="sm"
                            colorScheme='purple'
                            aria-label="Update"
                            title="Update"
                            icon={<MdSave />}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </SoftCard>
    );
};
