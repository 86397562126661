export const model2 = [
    'PROFESSIONAL_MONTHLY',
    'PROFESSIONAL_ANNUAL',
    'ELITE_MONTHLY',
    'ELITE_ANNUAL',
];

export const model3 = [
    'ELITE_MONTHLY',
    'ELITE_ANNUAL',
];

export const model4 = [
    'PROFESSIONAL_MONTHLY',
    'PROFESSIONAL_ANNUAL',
    'ELITE_MONTHLY',
    'ELITE_ANNUAL',
];

export const imageGeneration = [
    'PROFESSIONAL_MONTHLY',
    'ELITE_MONTHLY',
    'PROFESSIONAL_ANNUAL',
    'ELITE_ANNUAL',
];

export const image1 = [
    'PROFESSIONAL_MONTHLY',
    'ELITE_MONTHLY',
    'PROFESSIONAL_ANNUAL',
    'ELITE_ANNUAL',
];

export const image2 = [
    'ELITE_MONTHLY',
    'ELITE_ANNUAL',
];

export const exportLocally = [
    'ELITE_MONTHLY',
    'ELITE_ANNUAL',
];

export const isAvailableInPlanDeveloperMode = [
    'PROFESSIONAL_MONTHLY',
    'ELITE_MONTHLY',
    'PROFESSIONAL_ANNUAL',
    'ELITE_ANNUAL',
];

export const retryWithModel2 = [
    'PROFESSIONAL_MONTHLY',
    'ELITE_MONTHLY',
    'PROFESSIONAL_ANNUAL',
    'ELITE_ANNUAL',
];

export const voiceMode = [
    'PROFESSIONAL_MONTHLY',
    'ELITE_MONTHLY',
    'PROFESSIONAL_ANNUAL',
    'ELITE_ANNUAL',
];

export const minimalStylingDetection = [
    'PROFESSIONAL_MONTHLY',
    'ELITE_MONTHLY',
    'PROFESSIONAL_ANNUAL',
    'ELITE_ANNUAL',
];

export const aiActionsFunctionCalling = [
    'ELITE_MONTHLY',
    'ELITE_ANNUAL',
];

// export const JavascriptFixer = [
//     'PROFESSIONAL_MONTHLY',
//     'ELITE_MONTHLY',
//     'PROFESSIONAL_ANNUAL',
//     'ELITE_ANNUAL',
// ];

// Per plan, what libraries are available?
// or per library, what plan it is available on?