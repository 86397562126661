import { jsonrepair as repairJson } from 'jsonrepair'
import { parse as parseTolerant } from 'tolerant-json-parser';
import extraBracketJsonParser from './extra-bracket-json-parser';

export const completionParser = (str: string, wrapInResponseProperty = false) => {

    str = str.trim();
    if (str.startsWith('```json')) {
        str = str.slice(7);
    }
    if (str.endsWith('```')) {
        str = str.slice(0, -3);
    }
    str = str.trim();

    str = clearMultipleIsBeforeWhenFound(str);
    let parsedJson;

    // str = clearMultipleIsBeforeWhenFound(str);

    // let parsedJson;

    // Strict
    try {
        parsedJson = JSON.parse(str);
    } catch (error: any) {
        console.error('Strict parser failed: ', error.message);
        parsedJson = null;
    }

    if (parsedJson !== null) return parsedJson;


    // Strict + repair
    try {
        parsedJson = JSON.parse(
            repairJson(
                regexRepairJson(str)
            )
        );
    } catch (error: any) {
        console.error('Strict parser + repair failed: ', error.message);
        parsedJson = null;
    }

    if (parsedJson !== null) return parsedJson;

    // Tolerant + repair
    try {
        parsedJson = parseTolerant(
            repairJson(
                regexRepairJavascript(str)
            )
        );
    } catch (error: any) {
        console.error('Tolerant parser + repair failed: ', error.message);
        parsedJson = null;
    }

    if (parsedJson !== null) return parsedJson;

    // Extract HTML, CSS, Javascript blocks
    try {
        const htmlParser = new DOMParser();

        const tree = extractCodeBlock(str).map((block) => {

            if (block.language === "json") {
                throw new Error("Json, passing it to the rest.");
            }

            const returnObject: any = {};

            if (block.language === "html") {
                const htmlDocument = htmlParser.parseFromString(
                    block.code,
                    "text/html",
                );
                const customTree = customTreeBuilder(htmlDocument);

                returnObject.type = block.language;
                returnObject.element = customTree;
            } else {
                returnObject.type = block.language.toLowerCase() === 'js' ? 'javascript' : block.language;
                returnObject.code = block.code.trim();
            }

            return returnObject;
        });

        if (tree.length === 0) throw new Error("Parsing failed.");
        const sortedTree = tree.sort(sortPreferHtml);

        if (wrapInResponseProperty) parsedJson = { response: sortedTree };
        else parsedJson = sortedTree;
    } catch (error: any) {
        console.error('Extract HTML, CSS, and Javascript blocks failed: ', error.message);
        parsedJson = null;
    }

    if (parsedJson !== null) return parsedJson;

    // Extra bracket handling or 2 brackets handling
    try {
        parsedJson = extraBracketJsonParser.parse(str);
        if (!validateProprietaryFormat(parsedJson)) throw new Error("Invalid format.");
    } catch (error: any) {
        console.error('An error has occured and we could\'t parse the supplied data.', error.message);
        parsedJson = null;
    }

    if (parsedJson !== null) return parsedJson;

    // Multiple Extra bracket handling
    try {
        parsedJson = extraBracketJsonParser.parseMulti(str);
        if (!validateProprietaryFormat(parsedJson)) throw new Error("Invalid format.");
    } catch (error: any) {
        console.error('An error has occured and we could\'t parse the supplied data.', error.message);
        parsedJson = null;
    }

    if (parsedJson !== null) return parsedJson;

    // Extract unwrapped JSON 
    try {
        parsedJson = JSON.parse(
            extractUnwrappedJson(str)
        );
    } catch (error: any) {
        console.error('Extract unwrapped JSON failed: ', error.message);
        parsedJson = null;
    }

    if (parsedJson !== null) return parsedJson;

    // Extract backticks wrapped JSON 
    try {
        parsedJson = extractBackticksWrappedJson(str);
        if (parsedJson === null) throw new Error("Parsing failed..");
    } catch (error: any) {
        console.error('Extract Backticks wrapped JSON failed: ', error.message);
        parsedJson = null;
    }

    if (parsedJson) return parsedJson;
    else throw new Error("Failed to parse through multiple steps. Please try again.");
}

const regexRepairJavascript = (str: string): string => {
    const regex = /({)?\s*"type"\s*:\s*"javascript"\s*,\s*"code"\s*:\s*"(.*)"\s*(})?/g;

    let cleanedInput: string = str;
    let match;

    while (match = regex.exec(str)) {

        const matchedSubstring = match[0];

        const index = cleanedInput.indexOf(matchedSubstring);
        if (index === -1) {
            throw new Error("Substring not found.");
        }

        const leftPart = cleanedInput.substring(0, index);
        const rightPart = cleanedInput.substring(index + matchedSubstring.length);

        const openingBrace = !match[1] ? "{" : "";
        const closingBrace = !match[3] ? "}" : "";

        cleanedInput = `${leftPart}${openingBrace}${matchedSubstring}${closingBrace}${rightPart}`;

        if (match[2]) {
            const codeSnippet = match[2];
            const sanitizedCodeSnippet = codeSnippet.replace(/\\?"/g, "'");
            cleanedInput = cleanedInput.replace(codeSnippet, sanitizedCodeSnippet);
        }

    }

    return cleanedInput;
};

// const regexRepairJavascript = (str: string) => {
//     const regex =
//         /({)?\s*"type"\s*:\s*"javascript"\s*,\s*"code"\s*:\s*"(.*)"\s*(})?/s;

//     let cleanedInput: string = str;
//     let match;

//     if ((match = regex.exec(str)) !== null) {

//         // console.log(match[0]);

//         const index = cleanedInput.indexOf(match[0]);
//         if (index === -1) throw new Error("Substring not found.");

//         const leftPart = cleanedInput.substring(0, index);
//         const rightPart = cleanedInput.substring(index + match[0].length);

//         const openingBrace = !match[1] ? "{" : "";
//         const closingBrace = !match[3] ? "}" : "";

//         cleanedInput = `${leftPart}${openingBrace}${match[0]
//             }${closingBrace}${rightPart}`;

//         if (match[2]) {
//             cleanedInput = cleanedInput.replace(
//                 match[2],
//                 match[2].replace(/\\?"/g, "'"),
//             );
//         }
//     }

//     return cleanedInput;
// };

const regexRepairCss = (str: string) => {
    const regex =
        /({)?\s*"type"\s*:\s*"css"\s*,\s*"code"\s*:\s*"(.*?)"\s*(})?/gs;

    let cleanedInput: string = str;
    let match;

    while ((match = regex.exec(str)) !== null) {

        // console.log(match[0]);

        const index = cleanedInput.indexOf(match[0]);
        if (index === -1) throw new Error("Substring not found.");

        const leftPart = cleanedInput.substring(0, index);
        const rightPart = cleanedInput.substring(index + match[0].length);

        const openingBrace = !match[1] ? "{" : "";
        const closingBrace = !match[3] ? "}" : "";

        cleanedInput = `${leftPart}${openingBrace}${match[0]
            }${closingBrace}${rightPart}`;
    }

    return cleanedInput;
};

const regexRepairJson = (str: string) => {
    return regexRepairCss(
        regexRepairJavascript(str),
    );
};

const extractUnwrappedJson = (str: string) => {
    let openingBracketIndex = -1;
    let closingBracketIndex = -1;

    for (let i = 0; i < str.length; i++) {
        if (str[i] === "[" || str[i] === "{") {
            openingBracketIndex = i;
            break;
        }
    }

    if (openingBracketIndex === -1) {
        return "";
    }

    for (let i = str.length - 1; i >= 0; i--) {
        if (str[i] === "]" || str[i] === "}") {
            closingBracketIndex = i;
            break;
        }
    }

    if (closingBracketIndex === -1 || closingBracketIndex < openingBracketIndex) {
        return "";
    }

    return str.substring(openingBracketIndex, closingBracketIndex + 1);
};

function extractBackticksWrappedJson(inputString: string) {
    let result: any = [];
    const backticksRegex = /```(\w+)\s*([\s\S]+?)\s*```/gs;
    let match;

    while ((match = backticksRegex.exec(inputString)) !== null) {
        let jsonString = match[2];
        let parsedData;

        try {
            parsedData = JSON.parse(jsonString);
        } catch {
            parsedData = completionParser(jsonString);
        }

        if (!Array.isArray(parsedData)) {
            parsedData = [parsedData];
        }

        result = result.concat(parsedData);
    }

    if (result.length === 0) return null;
    else return result;
}

function extractCodeBlock(str: string): any[] {
    const codeBlocks: any[] = [];
    const regex = /```(\w+)\n([\s\S]+?)\n```/g;
    let match;

    while ((match = regex.exec(str)) !== null) {
        const [, language, code] = match;
        codeBlocks.push({ language, code });
    }

    return codeBlocks;
}

const customTreeBuilder = (htmlDocument: any) => {
    const loop = (node: any) => {
        const element: any = {
            tag: node.tagName.toLowerCase(),
            attributes: {},
            text: "",
            // text: node.textContent.trim(),
            parent: node.parentElement ? (node.parentElement.id || "") : "",
            is_before: "",
            is_after: "",
            children: [],
        };

        const cn = Array.from(node.childNodes);

        const hasPlainTextChild = cn.some((node: any) => {
            return node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '';
        });

        if (hasPlainTextChild) element.text = node.textContent.trim();

        for (let i = 0; i < node.attributes.length; i++) {
            const attr = node.attributes[i];
            const attributeName = attr.name.toLowerCase();

            if (
                attributeName !== "data-parent" &&
                attributeName !== "data-is-before" &&
                attributeName !== "data-is-after"
            ) {
                element.attributes[attr.name] = attr.value;
            }
        }

        const children = node.children;
        for (let i = 0; i < children.length; i++) {
            element.children.push(loop(children[i]));
        }

        return element;
    };

    const rootElement = htmlDocument.documentElement;

    let rootChildren: any = [];
    if (rootElement) rootChildren = Array.from(rootElement.children);

    const tree = [...rootChildren.map(loop)];

    return tree;
};

const sortPreferHtml = (a: any, b: any) => {
    if (a.type === "html" && b.type !== "html") {
        return -1;
    } else if (a.type !== "html" && b.type === "html") {
        return 1;
    } else {
        return a.type.localeCompare(b.type);
    }
}

function clearMultipleIsBeforeWhenFound(jsonString: string): string {
    const isBeforeWithValuePattern = /"is_before"\s*:\s*"([^"]+)"/g;
    const parentBodyPattern = /"parent"\s*:\s*"body",/g;

    const isBeforeWithValueMatches = jsonString.match(isBeforeWithValuePattern) || [];
    const parentBodyMatches = jsonString.match(parentBodyPattern) || [];

    // console.log('jsonString: ', jsonString);
    // console.log('isBeforeWithValueMatches: ', isBeforeWithValueMatches);
    // console.log('parentBodyMatches: ', parentBodyMatches);

    // I need to find the number of generated sections.

    // if (isBeforeWithValueMatches.length >= 2) {
    if (parentBodyMatches.length === isBeforeWithValueMatches.length + 1) {
        const isBeforePattern = /"is_before"\s*:\s*"[^"]*"/g;
        // const isAfterPattern = /"is_after"\s*:\s*"[^"]*"/g;

        jsonString = jsonString.replace(isBeforePattern, '"is_before":""');
        // jsonString = jsonString.replace(isAfterPattern, '"is_after":""');
    }

    return jsonString;
}

// function isParsedJSON(value: any) {
//     if (typeof value === 'object' && value !== null) {
//         if (Array.isArray(value) || Object.prototype.toString.call(value) === '[object Object]') {
//             return true;
//         }
//     }
//     return false;
// }

function validateProprietaryFormat(blocks: any) {
    if (!Array.isArray(blocks) || blocks.length === 0) {
        return false;
    }

    for (let block of blocks) {
        if (typeof block !== 'object' || block === null) {
            return false;
        }

        if (!['html', 'css', 'javascript'].includes(block.type)) {
            return false;
        }

        if (block.type === 'html') {
            if (typeof block.element !== 'object' || block.element === null) {
                return false;
            }
            if (typeof block.element.tag !== 'string') {
                return false;
            }
        }

        if (block.type === 'css') {
            if (typeof block.code !== 'string') {
                return false;
            }
        }

        if (block.type === 'javascript') {
            if (typeof block.code !== 'string') {
                return false;
            }
        }
    }

    return true;
}