import * as React from 'react';
import { useEffect } from 'react';
import { useColorMode, useColorModeValue, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { MdDarkMode, MdBrightness7 } from 'react-icons/md';
import { useRecoilValue, useRecoilState } from 'recoil';
import { UserPreferences, TriggeredFunction } from '../../state'

type ThemeSwitcherProps = Omit<IconButtonProps, 'aria-label'>;

export const ThemeSwitcher: React.FC<ThemeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(MdDarkMode, MdBrightness7);
  const userPreferences = useRecoilValue(UserPreferences);

  const [triggeredFunction, setTriggeredFunction] = useRecoilState<any>(TriggeredFunction);

  useEffect(() => {
    if (triggeredFunction) {
      if (triggeredFunction.name === 'toggle_theme') {
        toggleColorMode();
      }

      setTriggeredFunction(null);
    }
  }, [triggeredFunction])

  return (
    <Tooltip label='Theme: Toggle between light and dark modes.' placement='bottom-end' hasArrow isDisabled={!userPreferences?.display_tooltips || false}>
      <IconButton
        size="xs"
        variant="ghost"
        color="current"
        // marginLeft="2"
        onClick={toggleColorMode}
        icon={<SwitchIcon />}
        aria-label={`Switch to ${text} mode`}
        {...props}
      />
    </Tooltip>
  );
};
