import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ReactElement } from 'react';

export const DangerButton = ({
  title,
  icon,
  onClick,
  body,
  extraButtonText,
  extraButtonIcon,
  extraButtonOnClick,
  colorScheme = "red" // Add this prop with a default value
}: {
  title: string;
  icon?: ReactElement<any>;
  onClick: () => void;
  body: string;
  extraButtonText?: string;
  extraButtonIcon?: ReactElement<any>;
  extraButtonOnClick?: () => void;
  colorScheme?: string; // Add this to the type definition
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <>
      <Button w={'auto'} onClick={onOpen} leftIcon={icon} size="sm" colorScheme={colorScheme} variant="outline">
        {title}
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered size="lg">
        <AlertDialogOverlay>
          <AlertDialogContent maxWidth={{ base: "90%", md: "500px" }}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>
              <Flex w="full" flexDirection={{ base: "column", sm: "row" }} justifyContent="space-between" alignItems="center" gap={3}>
                {extraButtonText && (
                  <Button 
                    colorScheme="purple" 
                    size={'sm'} 
                    rightIcon={extraButtonIcon} 
                    onClick={extraButtonOnClick}
                    w={{ base: "full", sm: "auto" }}
                    mb={{ base: 2, sm: 0 }}
                    variant="outline"
                  >
                    {extraButtonText}
                  </Button>
                )}

                <Flex gap={2} w={{ base: "full", sm: "auto" }} justifyContent="flex-end">
                  <Button ref={cancelRef} size="sm" onClick={onClose} w={{ base: "full", sm: "auto" }} variant="outline">
                    Cancel
                  </Button>
                  <Button colorScheme={colorScheme} size="sm" onClick={onClick} w={{ base: "full", sm: "auto" }} variant="outline">
                    {title}
                  </Button>
                </Flex>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};