import React, { useState, useRef } from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUserState, loadingState, ChatBubbles, IsTranscribingSpinner, UserPreferences, IsGeneratingSpinner } from '../../state';
import { TbMicrophone, TbMicrophoneOff } from "react-icons/tb";
import { TranscriptionOutput } from '../../state';
import { useOpenaiActions } from '../../hooks';
import { RiVoiceprintFill } from "react-icons/ri";
import { voiceMode } from '../../lib/feature-availability';

export const Transcriber: React.FC<any> = () => {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const setTranscriptionOutput = useSetRecoilState(TranscriptionOutput);
  const setChatBubbles = useSetRecoilState(ChatBubbles);
  const setIsTranscribingSpinner = useSetRecoilState(IsTranscribingSpinner);

  const userPreferences = useRecoilValue(UserPreferences);

  const isGeneratingSpinner = useRecoilValue(IsGeneratingSpinner);
  const isTranscribingSpinner = useRecoilValue(IsTranscribingSpinner);

  const { getTranscription } = useOpenaiActions();

  const user = useRecoilValue(currentUserState);

  const startRecording = async () => {
    try {
      setChatBubbles((prev) => [...prev, {
        date: Date.now(),
        type: 'message',
        source: 'assistant',
        content: "Go on, I'm listening..."
      }]);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks: any[] = [];
      mediaRecorder.addEventListener("dataavailable", (event) => {
        chunks.push(event.data);
      });

      mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(chunks, { type: "audio/mp4" });
        setRecording(false);
        processFormData(audioBlob);
      });

      const userAgent = navigator.userAgent;
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) || /Macintosh/.test(userAgent);


      if (isIOS) {
        console.log("iOS device detected, starting mediaRecorder with interval.");
        mediaRecorder.start(1000);
      } else {
        console.log("Non-iOS device detected, starting mediaRecorder without interval.");
        mediaRecorder.start();
      }

      setRecording(true);
    } catch (error) {
      console.error(error);
    }
  };

  // const startRecording = async () => {
  //   try {
  //     setChatBubbles((prev) => [...prev, {
  //       date: Date.now(),
  //       type: 'message',
  //       source: 'assistant',
  //       content: "Go on, I'm listening..."
  //     }]);
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     const mediaRecorder = new MediaRecorder(stream);
  //     mediaRecorderRef.current = mediaRecorder;

  //     const chunks: any[] = [];
  //     mediaRecorder.addEventListener("dataavailable", (event) => {
  //       chunks.push(event.data);
  //     });

  //     mediaRecorder.addEventListener("stop", () => {
  //       const audioBlob = new Blob(chunks, { type: "audio/mp4" });
  //       setRecording(false);
  //       processFormData(audioBlob);
  //     });

  //     const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  //     if (isIOS) mediaRecorder.start(1000); 
  //     else mediaRecorder.start();

  //     setRecording(true);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const stopRecording = async () => {
    mediaRecorderRef.current?.stop();
  };

  const processFormData = async (audioBlob: Blob) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.mp4");
    setIsTranscribingSpinner(true);

    const transcription: any = await getTranscription(formData);
    setTranscriptionOutput(transcription.text);

    setIsTranscribingSpinner(false);
  };

  return (
    <>
      <Tooltip gutter={11} placement='auto' label='Talk: Use your voice.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
        {recording ? (

          <IconButton
            size="sm"
            // fontSize="lg"
            colorScheme='red'
            aria-label='Stop recording'
            icon={<RiVoiceprintFill />}
            // icon={<TbMicrophoneOff />}
            onClick={stopRecording}
            isDisabled={!voiceMode.includes(user?.plan_id as string) || isTranscribingSpinner || isGeneratingSpinner}
          />
        ) : (
          <IconButton
            colorScheme='pink'
            size="sm"
            // fontSize="lg"
            aria-label='Start recording'
            icon={<RiVoiceprintFill />}
            // icon={<TbMicrophone />}
            onClick={startRecording}
            isDisabled={!voiceMode.includes(user?.plan_id as string) || isTranscribingSpinner || isGeneratingSpinner}
          />
        )}
      </Tooltip>
    </>
  );
};
