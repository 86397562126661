import { atom,selector } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { User } from '../types';

export const currentUserState = atom<User | null>({
  key: 'current-user',
  default: (async () => {
    try {
      const { me } = await ApiClient.getMe();
      // ApiClient.fetchUserPreferences(me.id);
      return me;
    } catch (err: unknown) {
      return null;
    }
  })(),
});
export const iconDisabledState = atom({
  key: 'iconDisabledState',
  default: false,
});
export const combinedUserState = selector({
  key: 'combinedUserState',
  get: ({get}) => {
    const user = get(currentUserState);
    const iconDisabled = get(iconDisabledState);
    return {
      ...user,
      iconDisabled: iconDisabled || (user && user.trial_used),
    };
  },
});