import React, { useState, useRef, useEffect } from 'react';
import { Button, SimpleGrid } from '@chakra-ui/react';
import { Flex, Input, IconButton, Heading, Avatar, useColorModeValue, useBreakpointValue, Text } from '@chakra-ui/react'
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { ChatBubbles, TranscriptionOutput, currentUserState, selectedProjectSelector, IsGeneratingSpinner, selectedPageSelector } from '../../state';
import { TbSend } from "react-icons/tb";
import { SoftCard } from '../../components/generic/SoftCard';
import { prompts as promptLibrary } from '../../lib/prompts';
import { projectLibraries } from '../../lib/project-modes/libraries';
import { TbMessageCode } from "react-icons/tb";
import '../../lib/anim.css';

export const Chat: React.FC<any> = () => {
  const [textInput, settextInput] = useState('');
  const [starterPrompts, setStarterPrompts] = useState([{}]);

  const lastBubbleRef = useRef<HTMLDivElement>(null);

  const bubbleColor = useColorModeValue('purple.500', 'purple.200');
  const bubbleTextColor = useColorModeValue('white', 'gray.900');
  const noBubbleColor = useColorModeValue('gray.200', 'gray.700');
  const noBubbleTextColor = useColorModeValue('gray.900', 'white');
  const scrollBarColor = useColorModeValue('#38B2AC', '#81E6D9');

  const isGeneratingSpinner = useRecoilValue(IsGeneratingSpinner);
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const currentPage = useRecoilValue(selectedPageSelector);
  const chatBubbles = useRecoilValue(ChatBubbles);
  const user = useRecoilValue(currentUserState);

  const setTranscriptionOutput = useSetRecoilState(TranscriptionOutput);

  const isWideScreen = useBreakpointValue({ base: false, md: true });

  const handleTextInputChange = (event: any) => settextInput(event.target.value);
  const handleStarterPrompt = (prompt: any) => setTranscriptionOutput(prompt.long);

  useEffect(() => {
    if (lastBubbleRef.current) {
      if (isWideScreen) lastBubbleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatBubbles]);

  const handleEnterKeyPress = (event: any) => {
    if (event.key === 'Enter')
      handleSendChat();
  }

  const handleSendChat = () => {
    setTranscriptionOutput(textInput);
    settextInput('');
  }

  useEffect(() => {
    setStarterPrompts(
      promptLibrary.sort(() => Math.random() - 0.5).slice(0, 4)
    );
  }, [])

  return (
    // maxH={{ base: '50%', md: '100%' }}
    <SoftCard h='100%' minH='0' flexBasis={{ base: '80%', md: '330px' }}>
      <Flex className='zoom' flexDir='column' justify='space-between' gap={4} h='full'>
        <Flex alignItems='end' gap={2}>
          <TbMessageCode />
          <Heading as='h5' size='sm'>
            Chat
          </Heading>
        </Flex>
        <Flex justifyContent={chatBubbles && chatBubbles.length === 1 ? 'space-between' : 'initial'} flex={1} flexDir='column' gap={4} overflowY='auto' css={{ "::-webkit-scrollbar": { width: "5px", display: "none" }, "::-webkit-scrollbar-thumb": { backgroundColor: scrollBarColor } }}>
          {
            chatBubbles && chatBubbles.map((bubble: any, index: number) => (
              <Flex
                key={index} maxW='85%'
                ref={index === chatBubbles.length - 1 ? lastBubbleRef : null}
                flexDir='column' gap={1}
                alignSelf={`${bubble.source === 'assistant' ? 'start' : 'end'}`}
              >
                <Flex
                  p={3} rounded='xl' gap={4}
                  flexDir={`${bubble.source === 'assistant' ? 'row' : 'row-reverse'}`}
                  bg={`${bubble.source === 'assistant' ? bubbleColor : noBubbleColor}`}
                  boxShadow='md'
                >
                  {(bubble.source === 'user' ?
                    <Avatar bg="purple.600" size="xs"/>
                    :
                    <Avatar src='/illustrations/sifo-avatar-2.jpg' size="xs" name='SIFO' />
                  )}
                  <Text wordBreak={'break-word'} fontSize={'sm'} color={`${bubble.source === 'assistant' ? bubbleTextColor : noBubbleTextColor}`}>{bubble.content}</Text>
                </Flex>
                <Text fontSize='xs' alignSelf='flex-end' >
                  {new Date(bubble.date).toLocaleString()}
                </Text>
              </Flex>
            ))
          }

          {
            (projectLibraries.map(l => l.name) as any).includes(selectedProject?.mode) && (chatBubbles && chatBubbles.length === 1) && (currentPage?.is_index === true) && (
              <Flex gap={4} flexDir={'column'}>
                <Text px={1} fontSize={'sm'}>No idea where to start? Here are some starter prompts to get you going.</Text>
                <SimpleGrid gridTemplateColumns={'1fr 1fr'} gridTemplateRows={'1fr 1fr'} gridGap={2}>
                  {starterPrompts.map((prompt: any, index: number) => (
                    <Button key={index} padding={3} onClick={() => handleStarterPrompt(prompt)} size="sm" whiteSpace="normal" height="auto" variant={'outline'} fontWeight={'normal'} textAlign={'left'}>
                      <Text>{prompt.short}</Text>
                    </Button>
                  ))}
                </SimpleGrid>
              </Flex>
            )
          }
        </Flex>

        <Flex gap={'2'}>
          <Input size={'sm'} placeholder='What are we going to make today?' value={textInput} onChange={handleTextInputChange} onKeyPress={handleEnterKeyPress} isDisabled={isGeneratingSpinner} />
          <IconButton
            colorScheme='purple'
            size="sm"
            fontSize="lg"
            aria-label='Send'
            title='Send'
            icon={<TbSend />}
            onClick={handleSendChat}
          />
        </Flex>
      </Flex>
    </SoftCard>
  );
};
