import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { AuthenticatedLayout } from '../layouts';
import { usePageActions } from '../hooks';
import { selectedPageSelector, pagesState } from '../state';
import { languages } from '../lib/languages';
// import { html as beautify_html } from 'js-beautify';

export const PreviewPage: React.FC = () => {

  const [previewDocument, setPreviewDocument] = useState('');

  const page = useRecoilValue(selectedPageSelector);
  const pages = useRecoilValue(pagesState);

  const { fetchPageContent } = usePageActions();

  useEffect(() => {

    const preview = async () => {
      if (page) {
        const pageContent = await fetchPageContent(page.id) as string;
        if (!pageContent) return;

        const parser = new DOMParser();
        let pageDocument = parser.parseFromString(pageContent, 'text/html');

        const contentEditableElements = pageDocument.querySelectorAll('[contenteditable]');
        contentEditableElements.forEach((ceElement: any) => ceElement.removeAttribute('contenteditable'));

        const manualEditorElements = pageDocument.querySelectorAll('.sifo-manual-editor');
        manualEditorElements.forEach((meElement: any) => meElement.remove());

        const sectionLabels = pageDocument.querySelectorAll('.sifo-section-label');
        sectionLabels.forEach((sectionLabel: any) => sectionLabel.remove());

        // const previewElements = pageDocument.querySelectorAll('[data-dev-tag="sifo-preview"]');
        // previewElements.forEach((previewElement: any) => previewElement.remove());

        const developmentElements = pageDocument.querySelectorAll('[data-dev-tag="sifo-dev"]');
        developmentElements.forEach(devElement => devElement.remove());

        const injectIntoScript = pageDocument.querySelector('[data-dev-tag="inject-into"]');
        injectIntoScript?.removeAttribute('data-dev-tag');
        if (!injectIntoScript?.textContent) injectIntoScript?.remove();

        const elementsWithPositioningAttributes = pageDocument.querySelectorAll('[data-parent]');
        elementsWithPositioningAttributes.forEach(elementWithPositioningAttributes => {
          elementWithPositioningAttributes.removeAttribute('data-parent');
          elementWithPositioningAttributes.removeAttribute('data-is-before');
          elementWithPositioningAttributes.removeAttribute('data-is-after');
        });

        const pageDocumentStringified = pageDocument.documentElement.outerHTML
        setPreviewDocument(pageDocumentStringified);
      }
    };

    preview();
  }, []);

  useEffect(() => {

    console.log(`
      We are in preview, and we are loggin "pages",
      The only potential explanation is that it is undefined here,
      rendering this code useless, but why would it be?
    `, pages);

    if (pages.length === 0) return;

    const handleClick = (e: any) => {
      if (!page) return;

      if (typeof e.data === 'string') {
        if (e.data.endsWith('.html')) {

          console.log(`
            We are in preview, and we detected a click,
            on a link that ends with .html
          `);

          const href = e.data;

          const pageToSwitchTo = pages.find((_page: any) => {

            console.log(`
              We are in preview, and this is inside the page search loop,
              we are looking the href we got from the click on the link.
            `, href);

            // I need to automatically translate links to in applyTranslation
            // so what i need is on export, on all pages that are localized, i remove prefixes from their links?

            if (href.includes('/')) {

              const dividedHref = href.split('/');

              const localeShortForm = dividedHref[0];
              const path = dividedHref[1];
              const locale = languages.find(lang => lang.shortForm === localeShortForm)?.name;

              if (_page.locale === locale && _page.path === path) {
                return true;
              }
            }

            if (_page.path === href) {
              return true;
            }

          });

          console.log(`
            We are in preview, and after we searched for the page to switch it,
            we are trying to log it to make sure it is it. But, this will probably
            be undefined.
          `, pageToSwitchTo);

          // console.log("PreviewDocument pageToSwitchTo: ", pageToSwitchTo);

          if (pageToSwitchTo) {
            setPreviewDocument(pageToSwitchTo.content);
          }
        }
      }
    };

    window.addEventListener('message', handleClick);

    return () => {
      window.removeEventListener('message', handleClick);
    };
  }, [pages]);

  return (
    <AuthenticatedLayout>
      <Flex flex={1} flexDirection={'column'} gap={3} w='full'>
        <Flex flex={1} flexDirection={{ base: "column", md: "row" }} gap={3} overflowY={'auto'}>
          <iframe
            id='sifo-preview-iframe'
            style={{ height: '100%', width: '100%', borderRadius: '3px', backgroundColor: 'white' }}
            title="Preview"
            allowFullScreen
            srcDoc={previewDocument}
          />

          <iframe
            name="submission_target_frame"
            style={{ display: 'none' }}
          />
        </Flex>
      </Flex>
    </AuthenticatedLayout>
  );
};