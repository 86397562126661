export const languages = [
    { name: "English", shortForm: "en" },
    { name: "Spanish", shortForm: "es" },
    { name: "French", shortForm: "fr" },
    { name: "German", shortForm: "de" },
    { name: "Portuguese", shortForm: "pt" },
    { name: "Russian", shortForm: "ru" },
    { name: "Chinese", shortForm: "zh" },
    { name: "Japanese", shortForm: "ja" },
    { name: "Arabic", shortForm: "ar" },
    { name: "Hindi", shortForm: "hi" },
    { name: "Bengali", shortForm: "bn" },
    { name: "Urdu", shortForm: "ur" },
    { name: "Korean", shortForm: "ko" },
    { name: "Italian", shortForm: "it" },
  ];