import React, { useState, useRef, useEffect } from 'react';
import { Badge } from '@chakra-ui/react';
import { Flex, Switch, Tooltip, Input, IconButton, Heading, Select, Text, Stack, Radio, RadioGroup, Breadcrumb, BreadcrumbItem, BreadcrumbLink, useToast } from '@chakra-ui/react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { PageForms, selectedPageSelector, PageFormsHaveBeenUpdated, pagesState, UserPreferences, currentUserState, selectedProjectSelector, TriggeredFunction, LocalContext, LocalContextId } from '../../state';
import { SoftCard } from '../../components/generic/SoftCard';

import { MdSave } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { ChevronRightIcon } from '@chakra-ui/icons'

import '../../lib/anim.css';
import { usePageActions, useFormActions } from '../../hooks';

import { Icon } from '@chakra-ui/react'
import { FaRegCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

import { MdFormatListBulletedAdd } from "react-icons/md";

export const Forms: React.FC<any> = ({ handleEnableForm }) => {

  const userPreferences = useRecoilValue(UserPreferences);

  const currentUser = useRecoilValue(currentUserState);
  const currentProject = useRecoilValue(selectedProjectSelector);
  const currentPage = useRecoilValue(selectedPageSelector);

  const [pageForms, setPageForms] = useRecoilState(PageForms);
  const [inputValues, setInputValues] = useState(pageForms);
  const { updateForms } = usePageActions();
  const { enableForm } = useFormActions();

  const localContextId = useRecoilValue(LocalContextId);
  const localContext = useRecoilValue<any>(LocalContext);

  const setPageFormsHaveBeenUpdated = useSetRecoilState(PageFormsHaveBeenUpdated);

  useEffect(() => {
    if (pageForms.length > 0) {
      console.log('Setting input values to: ', pageForms);
      setInputValues(pageForms);
    }
  }, [pageForms]);

  const handleInputChange = (event: any, index: number, property: string, is_radio: boolean = false) => {
    setInputValues((previousValue: any) =>
      previousValue.map((value: any, i: number) =>
        i === index
          ? { ...value, [property]: is_radio ? event : event.target.value }
          : value
      )
    );
  };

  return (
    <SoftCard h='100%' minH='0' flex={2}>
      <Flex className='zoom' flexDir='column' justify='space-between' gap={4} h='full'>

        <Flex alignItems='end' gap={2}>
          <MdFormatListBulletedAdd />
          <Heading as='h5' size='sm'>
            Forms
          </Heading>
        </Flex>

        <Flex flex={1} flexDir='column' gap={4} overflowY='auto' css={{ "::-webkit-scrollbar": { display: "none" } }}>
          {pageForms.length > 0 ? (
            <>
              {
                (inputValues.length > 0) && inputValues.map((form: any, index: number) => {
                  if (localContext !== null && localContext.tagName === 'FORM') {
                    if (form.dom_id === localContextId) {
                      return (
                        <Flex key={index} gap={3} flexDir='column' alignItems='flex-start'>
                          {/* <Switch size='sm' colorScheme='purple' isChecked={form.is_active} onChange={(e) => handleInputChange(e, index, 'is_active')} /> */}
      
                          <Flex justifyContent='space-between' align-items='center' w='full'>
                            <Breadcrumb fontSize='xs' separator={<ChevronRightIcon color='gray.500' />}>
                              {form.parent_id && (
                                <BreadcrumbItem>
                                  <BreadcrumbLink>{form.parent_id}</BreadcrumbLink>
                                </BreadcrumbItem>
                              )}
      
                              <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink>{form.dom_id}</BreadcrumbLink>
                              </BreadcrumbItem>
                            </Breadcrumb>
      
                            <Tooltip gutter={11} placement='auto' label='Shows whether the form is enabled for submissions or not.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                              <Icon as={form.is_active ? FaCheckCircle : FaRegCircle} />
                            </Tooltip>
                            {/* <Badge variant='solid' colorScheme='purple' size='xxs'>{form.is_active ? 'Active' : 'Inactive'}</Badge> */}
                          </Flex>
      
                          <Flex gap={2} w='full'>
                            {/* <Input placeholder='Destination email..' type='email' size='sm' value={form.destination_email} onChange={(e) => handleInputChange(e, index, 'destination_email')} onKeyPress={handleEnterKeyPress} /> */}
                            <Input placeholder='Destination email..' type='email' size='sm' value={form.destination_email} onChange={(e) => handleInputChange(e, index, 'destination_email')} />
      
                            <IconButton
                              size="sm"
                              variant='solid'
                              colorScheme='purple'
                              aria-label='Enable'
                              icon={<VscActivateBreakpoints />}
                              onClick={() => handleEnableForm(form.dom_id, form.destination_email)}
                              isDisabled={!form.destination_email || form.is_active}
                              // isDisabled={form.destination_email.length === 0 || form.is_active}
                            />
      
                          </Flex>
      
                        </Flex>
                      )
                    }
                  } else {
                    return (
                      <Flex key={index} gap={3} flexDir='column' alignItems='flex-start'>
                        {/* <Switch size='sm' colorScheme='purple' isChecked={form.is_active} onChange={(e) => handleInputChange(e, index, 'is_active')} /> */}
    
                        <Flex justifyContent='space-between' align-items='center' w='full'>
                          <Breadcrumb fontSize='xs' separator={<ChevronRightIcon color='gray.500' />}>
                            {form.parent_id && (
                              <BreadcrumbItem>
                                <BreadcrumbLink>{form.parent_id}</BreadcrumbLink>
                              </BreadcrumbItem>
                            )}
    
                            <BreadcrumbItem isCurrentPage>
                              <BreadcrumbLink>{form.dom_id}</BreadcrumbLink>
                            </BreadcrumbItem>
                          </Breadcrumb>
    
                          <Tooltip gutter={11} placement='auto' label='Shows whether the form is enabled for submissions or not.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                            <Icon as={form.is_active ? FaCheckCircle : FaRegCircle} />
                          </Tooltip>
                          {/* <Badge variant='solid' colorScheme='purple' size='xxs'>{form.is_active ? 'Active' : 'Inactive'}</Badge> */}
                        </Flex>
    
                        <Flex gap={2} w='full'>
                          {/* <Input placeholder='Destination email..' type='email' size='sm' value={form.destination_email} onChange={(e) => handleInputChange(e, index, 'destination_email')} onKeyPress={handleEnterKeyPress} /> */}
                          <Input placeholder='Destination email..' type='email' size='sm' value={form.destination_email} onChange={(e) => handleInputChange(e, index, 'destination_email')} />
    
                          <IconButton
                            size="sm"
                            variant='solid'
                            colorScheme='purple'
                            aria-label='Enable'
                            icon={<VscActivateBreakpoints />}
                            onClick={() => handleEnableForm(form.dom_id, form.destination_email)}
                            isDisabled={!form.destination_email || form.is_active}
                            // isDisabled={form.destination_email.length === 0 || form.is_active}
                          />
    
                        </Flex>
    
                      </Flex>
                    )
                  }
                })
              }
            </>
          ) : (
            <>
              <Flex flexDir='column' justifyContent='center' alignItems='center' p={4}>
                <Text fontSize='sm' >There are no forms in the current page.</Text>
              </Flex>
            </>
          )}
        </Flex>

        {/* <Flex justifyContent='flex-end'>
          <Tooltip gutter={11} placement='auto' label='Update: Save and apply changes.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              onClick={handleSaveChanges}
              size="sm"
              colorScheme='purple'
              aria-label="Update"
              title="Update"
              icon={<MdSave />}
            />
          </Tooltip>
        </Flex> */}

      </Flex>
    </SoftCard>
  );
};
