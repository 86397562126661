// deno-lint-ignore-file no-explicit-any

import hash from "object-hash";

import { isAddEventListener, isFunctionCall } from "./helper";

// To be potentially turned into `compareScopes` as it might be re-used for all scopes, and not just the global one.
// because what is the global context, if not just a giant block statement, or in other words, a `main` function.
export function compareScope(existingNodes: any, incomingNodes: any): any[] {
  const mergedNodes = [];

  for (const node1 of existingNodes) {
    let matched = false;
    for (const node2 of incomingNodes) {
      if (node1.type !== node2.type) continue;

      if (node1.node_hash_label === node2.node_hash_label) {
        mergedNodes.push(node2);
        matched = true;
        break;
      }
    }

    if (!matched) {
      mergedNodes.push(node1);
    }
  }

  // Add remaining nodes from incomingNodes
  for (const node2 of incomingNodes) {
    let matched = false;
    for (const node1 of existingNodes) {
      if (node1.type !== node2.type) continue;

      if (node1.node_hash_label === node2.node_hash_label) {
        matched = true;
        break;
      }
    }

    if (!matched) {
      mergedNodes.push(node2);
    }
  }

  return mergedNodes;
}

export function normalizeLocationProperties(ast: any) {
  function traverse(node: any) {
    if (node && typeof node === 'object') {
      delete node.start;
      delete node.end;

      for (const key in node) {
        if (Object.prototype.hasOwnProperty.call(node, key)) {
          const childNode = node[key];
          if (Array.isArray(childNode)) {
            childNode.forEach(traverse);
          } else {
            traverse(childNode);
          }
        }
      }

    }
  }

  traverse(ast);
  return ast;
}

export function createIdentifyingProperty(ast: any) {
  function traverse(node: any) {
    if (node && typeof node === 'object') {
      node.node_hash_label = createNodeHashLabel(node);

      for (const key in node) {
        if (Object.prototype.hasOwnProperty.call(node, key)) {
          const childNode = node[key];
          if (Array.isArray(childNode)) {
            childNode.forEach(traverse);
          } else {
            traverse(childNode);
          }
        }
      }

    }
  }

  traverse(ast);
  return ast;
}

function createNodeHashLabel(node: any) {
  if (node.type === 'Identifier') {
    return hash(node.name);
  }

  if (node.type === 'Literal') {
    return hash(node.value);
  }

  if (node.type === 'FunctionDeclaration') {
    return hash(node.id.name);
  }

  if (node.type === 'FunctionExpression') {
    /*
    It is an anonymous function, so there is really not a way to differentiate as it is.
    although, we will be able to differentiate using VariableDeclaration, or VariableDeclarator.
    */
  }

  if (node.type === 'ArrowFunctionExpression') {
    /*
    It is an anonymous function, so there is really not a way to differentiate as it is.
    although, we will be able to differentiate using VariableDeclaration, or VariableDeclarator.
    */
  }

  if (node.type === 'BlockStatement') {
    return hash(node.body);
  }

  if (node.type === 'ExpressionStatement') {
    // if EventListener, this is the hash you generate, as in: out of these.. properties.
    // and technically, the same could be done for CallExpression.
    // otherwise, feel free to use your regular prejeduce.
    // so yes, this is, extensible.

    if (isAddEventListener(node)) {
      return hash({ callee: node.expression.callee, event: node.expression.arguments[0] });
    } else if (isFunctionCall(node)) {
      return hash(node.expression.callee.name);
    } else {
      return hash(node.expression);
    }
  }

  if (node.type === 'ReturnStatement') {
    return hash(node.argument);
  }

  if (node.type === 'VariableDeclaration') {
    return hash(node.declarations);
  }

  if (node.type === 'VariableDeclarator') {
    return hash({ kind: node.kind, name: node.id.name })
  }

  if (node.type === 'ThisExpression') {
    // Seemingly nothing to do here.
  }

  if (node.type === 'ArrayExpression') {
    // Falls under VariableDeclaration and VariableDeclarator.
  }

  if (node.type === 'ObjectExpression') {
    // Falls under VariableDeclaration and VariableDeclarator.
  }

  // Might be skipped.
  if (node.type === 'Property') {
    // Falls under Identifier.
  }

  if (node.type === 'FunctionCallExpression') {
    return hash(node.expression.callee);
  }

  if (node.type === 'NewExpression') {
    // One could say that it also falls under VariableDeclaration and VariableDeclarator, but for the sake of it.
    return hash(node.callee);
  }

  if (node.type === 'MemberExpression') {
    return hash({ object: node.object, property: node.property });
  }

  if (node.type === 'ConditionalExpression') {
    // One could say that it also falls under VariableDeclaration and VariableDeclarator, but for the sake of it.
    return hash(node.test);
  }

  if (node.type === 'AssignmentExpression') {
    return hash({ left: node.left, operator: node.operator });
  }

  if (node.type === 'UnaryExpression') {
    return hash({ argument: node.argument, operator: node.operator });
  }

  if (node.type === 'BinaryExpression') {
    return hash({ left: node.left, right: node.right, operator: node.operator });
  }

  if (node.type === 'LogicalExpression') {
    return hash({ left: node.left, right: node.right, operator: node.operator });
  }

  if (node.type === 'UpdateExpression') {
    return hash({ argument: node.argument, operator: node.operator });
  }

  if (node.type === 'SequenceExpression') {
    return hash(node.expressions)
  }

  if (node.type === 'IfStatement') {
    return hash(node.test);
  }

  if (node.type === 'SwitchStatement') {
    return hash(node.discriminant);
  }

  if (node.type === 'WhileStatement') {
    return hash(node.test);
  }

  if (node.type === 'DoWhileStatement') {
    return hash(node.test);
  }

  if (node.type === 'ForStatement') {
    return hash({ init: node.init, test: node.test, update: node.update });
  }

  if (node.type === 'ForInStatement') {
    return hash({ left: node.left, right: node.right });
  }

  if (node.type === 'ForOfStatement') {
    return hash({ left: node.left, right: node.right });
  }

  if (node.type === 'TryStatement') {
    return hash({ block: node.block, handler: node.handler, finalizer: node.finalizer });
  }

  if (node.type === 'CatchClause') {
    // Probably falls under TryStatement.
  }

  if (node.type === 'ThrowStatement') {
    return hash(node.argument);
  }

  if (node.type === 'ClassDeclaration') {
    return hash(node.id.name);
  }

  if (node.type === 'ClassExpression') {
    // Falls under VariableDeclaration and VariableDeclarator.
  }

  if (node.type === 'MethodDefinition') {
    return hash({ kind: node.kind, key: node.key });
  }

  if (node.type === 'TemplateLiteral') {
    return hash(node.quasis);
  }

  if (node.type === 'TaggedTemplateExpression') {
    return hash({ tag: node.tag, quasi: node.quasi });
  }

  if (node.type === 'TemplateElement') {
    return hash(node.value);
  }

  if (node.type === 'AwaitExpression') {
    return hash(node.argument);
  }

  if (node.type === 'ImportDeclaration') {
    return hash({ specifiers: node.specifiers, source: node.source });
  }

  if (node.type === 'ExportNamedDeclaration') {
    return hash({ specifiers: node.specifiers, source: node.source });
  }

  if (node.type === 'ExportDefaultDeclaration') {
    return hash(node.declaration);
  }

  if (node.type === 'ExportAllDeclaration') {
    return hash(node.source); // Potentially add node.exported as well? It is null, in say: export * from 'module';
  }
}