import { atom, selector } from 'recoil';
import { currentUserState } from './current-user';

const DefaultUserAllottedCredits = selector({
    key: 'default-user-allotted-credits',
    get: async ({ get }) => {
        try {
            const currentUser = get(currentUserState);
            if (currentUser) {
                return {
                    projects_allowed: currentUser.projects_allowed,
                    sites_allowed: currentUser.sites_allowed,
                    forms_allowed: currentUser.forms_allowed,
                    exports_allowed: currentUser.exports_allowed,
                    submissions_allowed: currentUser.submissions_allowed,
                    bandwidth_allowed: currentUser.bandwidth_allowed,
                    pages_allowed: currentUser.pages_allowed,
                    translations_allowed: currentUser.translations_allowed,
                }
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    },
});

export const UserAllottedCredits = atom({
    key: 'user-allotted-credits',
    default: DefaultUserAllottedCredits,
});

