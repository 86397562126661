import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Select,
  Box,
  Flex,
  useColorModeValue,
  Text,
  useDisclosure,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button, IconButton,
  Image, Switch, Spinner, Badge, Tooltip, useBreakpointValue, SimpleGrid, Icon
} from '@chakra-ui/react';
import { MdKeyboardArrowDown, MdPlayArrow, MdStop, MdOutlineDraw, MdPerson, MdTableRows, MdOutlineFilterCenterFocus, MdDashboard, MdRefresh, MdOutlineCardTravel, MdOutlineAssignment, MdOutlineCloud, MdOutlineSettings, MdCreate, MdOutlinePreview, MdOutlineRocket, MdOutlinePeople } from 'react-icons/md';
import { IconType } from 'react-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { TriggeredFunction, DisplaySectionLabels, DisplayVisualEditor, pagesState, selectedProjectSelector, currentUserState, LocalContext, CSSCode, LocalContextId, UserPreferences, LocalContextCSS, IsGeneratingSpinner, IsTranscribingSpinner, IsDeployingSpinner, SelectedOutlinerNode, IsUploadingSpinner } from '../../state';
import { Input, Checkbox, Divider } from '@chakra-ui/react'
import { RiVoiceprintFill } from "react-icons/ri";
import { MdMenu } from "react-icons/md";
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { SignOutButton } from '../authentication/SignOutButton';
import { ThemeSwitcher } from './theme-switcher';
import { Tips } from './tips';
import { SupportButton } from '../generic/SupportButton';
import { useState, useEffect } from 'react';
import { useProjectActions, useUserActions } from '../../hooks';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'

import { model2, model3, model4 } from '../../lib/feature-availability';

interface LinkItemProps {
  name: string;
  onClick: () => void;
  icon: IconType;
}

export const Sidebar = ({ hidden = false }: { hidden: boolean }): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <MobileNav onOpen={onOpen} full={hidden} />
  );
};
const MotionBox = motion(Box);

const Logo = () => (
  <RouterLink to="/">
    <Flex alignItems='flex-end' gap={1}>
      <Image src={useColorModeValue('/logo-type-light.png', '/logo-type-dark.png')} height="18px" />
      {/* <Badge variant='solid' colorScheme='purple' fontSize='0.55em'>beta</Badge> */}
      {/* <Badge variant='solid' colorScheme='purple' fontSize='0.55em'>RC</Badge> */}
    </Flex>
  </RouterLink>
);

interface MobileProps extends FlexProps {
  onOpen: () => void;
  full?: boolean;
}

const MobileNav = ({ onOpen, full = false, ...rest }: MobileProps) => {

  const { fetchProjectPages } = useProjectActions();
  const resetPages = useResetRecoilState(pagesState);

  const cancelRef = useRef<any | null>();
  const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure();

  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure()

  const isWideScreen = useBreakpointValue({ base: false, md: true });

  const navigate = useNavigate();
  const user = useRecoilValue(currentUserState);


  const isUploadingSpinner = useRecoilValue(IsUploadingSpinner);
  const isDeployingSpinner = useRecoilValue(IsDeployingSpinner);
  const isGeneratingSpinner = useRecoilValue(IsGeneratingSpinner);
  const isTranscribingSpinner = useRecoilValue(IsTranscribingSpinner);
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const navbarColor = useColorModeValue('gray.100', 'gray.900');

  const localContextId: any = useRecoilValue(LocalContextId);

  const [triggeredFunction, setTriggeredFunction] = useRecoilState<any>(TriggeredFunction);
  const [displayVisualEditor, setDisplayVisualEditor] = useRecoilState(DisplayVisualEditor);
  const [displaySectionLabels, setDisplaySectionLabels] = useRecoilState(DisplaySectionLabels);

  const handleDisplayVisualEditorOnChange = () => {
    setDisplayVisualEditor(!displayVisualEditor);
  }

  const handleDisplaySectionLabelsOnChange = () => {
    setDisplaySectionLabels(!displaySectionLabels);
  }

  // const [isPreviewMode, setIsPreviewMode] = useState(false);

  const handleIsPreviewModeOnChange = () => {
    navigate(selectedProject ? `/projects/${selectedProject.id}/${window.location.href.endsWith('dashboard') ? 'preview' : 'dashboard'}` : '/');
    // setIsPreviewMode(!isPreviewMode);
  }


  // useEffect(() => {
  //   if (isPreviewMode) navigate(selectedProject ? `/projects/${selectedProject.id}/preview` : '/');
  //   else navigate(selectedProject ? `/projects/${selectedProject.id}/dashboard` : '/');
  // }, [isPreviewMode])

  // const handleIsPreviewModeOnChange = () => {
  //   setIsPreviewMode((prevIsPreviewMode) => {
  //     const newIsPreviewMode = !prevIsPreviewMode;

  //     if (newIsPreviewMode) {
  //       navigate(selectedProject ? `/projects/${selectedProject.id}/preview` : '/');
  //     } else {
  //       navigate(selectedProject ? `/projects/${selectedProject.id}/dashboard` : '/');
  //     }

  //     return newIsPreviewMode;
  //   });
  // };


  const resetLocalContext = useResetRecoilState(LocalContext);
  const resetLocalContextCSS = useResetRecoilState(LocalContextCSS);
  const resetSelectedOutlinerNode = useResetRecoilState(SelectedOutlinerNode);

  const handleResetLocalContext = () => {
    resetLocalContext();
    resetLocalContextCSS();
    resetSelectedOutlinerNode();
  }
  // const { fetchUserPreferences } = useUserActions();

  // useEffect(() => {
  //   if (user) fetchUserPreferences(user.id);
  // }, [])

  const [userPreferences, setUserPreferences] = useRecoilState(UserPreferences);
  const { updateUserPreferences } = useUserActions();

  const spinnerColor = useColorModeValue('purple.500', 'purple.200');

  useEffect(() => {
    if (user && userPreferences)
      updateUserPreferences(user.id, userPreferences);
  }, [userPreferences])

  useEffect(() => {
    if (triggeredFunction) {

      if (triggeredFunction.name === 'reset_local_context') {
        handleResetLocalContext();
      }

      if (triggeredFunction.name === 'toggle_visual_editor') {
        handleDisplayVisualEditorOnChange();
      }

      if (triggeredFunction.name === 'toggle_preview') {
        handleIsPreviewModeOnChange();
      }

      setTriggeredFunction(null);
    }
  }, [triggeredFunction])

  const handleModelOnChange = (event: any) => {
    if (event.target.value === "model-1") {
      setUserPreferences((previousPreferences: any) => {
        return {
          ...previousPreferences,
          ai_model: event.target.value
        }
      });
    } else if (event.target.value === "model-2") {
      if (model2.includes(user?.plan_id as string)) {
        setUserPreferences((previousPreferences: any) => {
          return {
            ...previousPreferences,
            ai_model: event.target.value
          }
        });
      } else {
        onUpgradeOpen();
      }

    } else if (event.target.value === "model-3") {
      if (model3.includes(user?.plan_id as string)) {
        setUserPreferences((previousPreferences: any) => {
          return {
            ...previousPreferences,
            ai_model: event.target.value
          }
        });
      } else {
        onUpgradeOpen();
      }
    } else if (event.target.value === "model-4") {
      if (model4.includes(user?.plan_id as string)) {
        setUserPreferences((previousPreferences: any) => {
          return {
            ...previousPreferences,
            ai_model: event.target.value
          }
        });
      } else {
        onUpgradeOpen();
      }
    }

    // if (model2.includes(user?.plan_id as string)) {
    //   setUserPreferences((previousPreferences: any) => {
    //     return {
    //       ...previousPreferences,
    //       ai_model: event.target.value
    //     }
    //   });
    // } else {
    //   onUpgradeOpen();
    // }

  }

  // const handleModelOnChange = (event: any) => {
  //   if (gpt4.includes(user?.plan_id as string)) {
  //     setUserPreferences((previousPreferences: any) => {
  //       return {
  //         ...previousPreferences,
  //         ai_model: event.target.value
  //       }
  //     });
  //   } else {
  //     onUpgradeOpen();
  //   }

  // }

  const handleGoBackToProject = () => {
    if (selectedProject) {
      // setSelectedProjectMode(selectedProject.mode); // Because it's already selected.
      resetPages();
      fetchProjectPages(selectedProject.id);
      navigate(`/projects/${selectedProject.id}/dashboard`);
    }
  }

  return (
    <>
      <SimpleGrid
        // bg='tomato'
        pt={4}
        pb={2}
        px={4}
        gridTemplateColumns='repeat(3, 1fr)'
        alignItems='center'
        gap={2}
        {...rest}
      >
        <Flex alignItems={'center'} gap={4}>
          <Logo />
          {/* {isWideScreen && (<Logo />)} */}
          {(!full && isWideScreen) && (
            <Flex gap={2} alignItems='center'>
              <Menu>
                {/* <Card py={2} px={4}> */}
                <Tooltip placement='bottom-end' label='Preview mode, hosting, and project settings.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                  <MenuButton as={Button} size='xs' variant='ghost' transition="all 0.3s" _focus={{ boxShadow: 'none' }} leftIcon={<MdOutlineDraw />} isDisabled={window.location.href.endsWith('preview')}>
                    <Flex alignItems={'center'} gap={1}>
                      <Text>{selectedProject?.name || 'No Project'}</Text>
                      <Box display={{ base: 'flex', md: 'flex' }}>
                        <MdKeyboardArrowDown />
                      </Box>
                    </Flex>
                  </MenuButton>
                </Tooltip>
                {/* </Card> */}
                <MenuList fontSize={'sm'} boxShadow='xl'>
                  <MenuItem icon={<MdCreate />} onClick={handleGoBackToProject}>Editor</MenuItem>
                  {/* <MenuItem icon={<MdCreate />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/dashboard` : '/')}>Editor</MenuItem> */}
                  <MenuItem icon={<MdOutlinePreview />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/preview` : '/')}>Preview</MenuItem>
                  <MenuItem icon={<MdOutlineRocket />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/hosting` : '/')}>Publish</MenuItem>
                  <MenuItem icon={<MdOutlineSettings />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/settings` : '/')}>Settings</MenuItem>
                  <MenuItem isDisabled icon={<MdOutlinePeople />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/users` : '/')}>Collaborators</MenuItem>
                </MenuList>
              </Menu>
              <Tooltip placement='bottom-start' label='Current project mode, UI library or template.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                <Button variant='outline' size='xs' leftIcon={<MdDashboard />} isDisabled={window.location.href.endsWith('preview')}>
                  {selectedProject?.mode}
                </Button>
              </Tooltip>
            </Flex>
          )}

          {!full && (
            (isTranscribingSpinner || isGeneratingSpinner || isDeployingSpinner || isUploadingSpinner) && (
              <Spinner size='sm' colorScheme='purple' />
            )
          )}

          {/* {!full && (

        )} */}



        </Flex>

        <Flex alignItems={'center'} justifyContent='center' gap={4}>
          {(!full && (window.location.href.endsWith('dashboard') || window.location.href.endsWith('preview'))) && (
            <>




              {isWideScreen && (
                <>

                  <Select size="xs" width="6rem" onChange={handleModelOnChange} value={userPreferences.ai_model}>
                    <option value='model-1'>MODEL-1</option>
                    <option value='model-2'>MODEL-2</option>
                    <option value='model-3'>MODEL-3</option>
                    <option value='model-4'>MODEL-4</option>
                  </Select>

                  <Tooltip label='Context: What the AI sees.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                    {/* <Text fontSize='xs'>Editing</Text> */}
                    <Flex gap={2}>
                      <Button variant='solid' size='xs' colorScheme='purple' pointerEvents={localContextId ? 'initial' : 'none'} rightIcon={localContextId ? <MdRefresh /> : <MdOutlineFilterCenterFocus />} onClick={handleResetLocalContext} isDisabled={window.location.href.endsWith('preview')}>
                        {'Editing · '} {localContextId ? localContextId : "Page"}
                      </Button>
                    </Flex>
                  </Tooltip>

                  <Tooltip label='Labels: Toggles section labels.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                    <Text fontSize='xs'>Labels</Text>
                  </Tooltip>
                  <Switch size='sm' colorScheme='purple' isChecked={displaySectionLabels} onChange={handleDisplaySectionLabelsOnChange} isDisabled={window.location.href.endsWith('preview')} />


                  <Tooltip label='Highlights: Toggles viewport editor highlighting.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                    <Text fontSize='xs'>Select</Text>
                  </Tooltip>
                  <Switch size='sm' colorScheme='purple' isChecked={displayVisualEditor} onChange={handleDisplayVisualEditorOnChange} isDisabled={window.location.href.endsWith('preview')} />
                </>
              )}

              <Tooltip label='Preview mode: Shows what the project will look like.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                <Text fontSize='xs'>Preview</Text>
              </Tooltip>
              <Switch size='sm' colorScheme='purple' id='toggle-preview-editor-modes' onChange={handleIsPreviewModeOnChange} isChecked={window.location.href.endsWith('preview')} />

              {/* {(!full && isWideScreen) && (
              isGeneratingSpinner && (
                <>
                  <Text fontSize='sm'>Generating</Text>
                  <Spinner size='sm' colorScheme='purple' />
                </>
              )
            )}

            {(!full && isWideScreen) && (
              isDeployingSpinner && (
                <>
                  <Text fontSize='sm'>Deploying</Text>
                  <Spinner size='sm' colorScheme='purple' />
                </>
              )
            )}

            {(!full && isWideScreen) && (
              isTranscribingSpinner && (
                <>
                  <Text fontSize='sm'>Transcribing</Text>
                  <Spinner size='sm' colorScheme='purple' />
                </>
              )
            )} */}


            </>
          )}
        </Flex>

        <Flex gap={3} alignItems={'center'} justifyContent='end'>
          {/* <Button title='Help' colorScheme={'purple'} variant={'link'} size={'sm'}>
          Help
        </Button> */}
          {/* <IconButton
          size="sm"
          variant="ghost"
          color="current"
          icon={<MdHelpOutline />}
          aria-label="Help"
        /> */}

          {/* {!full && (
          (isTranscribingSpinner || isGeneratingSpinner || isDeployingSpinner) && (
              <Spinner size='sm' colorScheme='purple' />
          )
        )} */}

          {isWideScreen && (
            <>

              {!window.location.href.endsWith('onboarding') && (
                <>
                  <Tips full={full} />
                </>
              )}

              <ThemeSwitcher />

              <Menu>
                <Tooltip placement='bottom-start' label='Sites, user preferences, and sign out.' hasArrow isDisabled={!userPreferences?.display_tooltips || false}>
                  <MenuButton as={Button} variant='ghost' size='xs' transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                    <Flex alignItems={'center'} gap={1}>
                      <Text>{user && `${user.first_name} ${user.last_name}`}</Text>
                      <Box display='flex'>
                        <MdKeyboardArrowDown />
                      </Box>
                    </Flex>
                  </MenuButton>
                </Tooltip>
                <MenuList fontSize={'sm'} boxShadow='xl'>
                  <MenuItem icon={<MdOutlineCardTravel />} onClick={() => navigate('/')}>Projects</MenuItem>
                  <MenuItem icon={<MdOutlineAssignment />} onClick={() => navigate('/forms')}>Forms</MenuItem>
                  <MenuItem icon={<MdOutlineCloud />} onClick={() => navigate('/sites')}>Sites</MenuItem>
                  <MenuItem icon={<MdOutlineSettings />} onClick={() => navigate('/settings')}>Settings</MenuItem>
                  <MenuDivider />
                  <SignOutButton>Sign out</SignOutButton>
                </MenuList>
              </Menu>
            </>
          )}

          {/* <Button size="sm" leftIcon={<MdMenu />} colorScheme="purple" onClick={drawerOnOpen}>Menu</Button> */}

          {!isWideScreen && (
            <IconButton
              colorScheme='purple'
              size="sm"
              variant="outline"
              aria-label='Menu'
              icon={<MdMenu />}
              onClick={drawerOnOpen}
            />
          )}

          {(!window.location.href.endsWith('onboarding') && isWideScreen) && (
            <>
              {/* <Box>
              <Input size='xs' placeholder='What to do?' />
            </Box>

            <Tooltip placement='bottom-start' label='Talk: Tell the assistant to do things for you.' hasArrow isDisabled={!userPreferences?.display_tooltips || false}>
              <IconButton
                size="xs"
                variant="solid"
                colorScheme="purple"
                icon={<RiVoiceprintFill />}
                aria-label="Talk"
                onClick={handleTriggerfunctionCall}
              />
            </Tooltip> */}

              <Tooltip placement='bottom-start' label='Upgrade: Switch to a different tier.' hasArrow isDisabled={!userPreferences?.display_tooltips || false}>
                <Button size={'xs'} onClick={() => navigate('/upgrade')}>
                  Upgrade
                </Button>
              </Tooltip>
            </>
          )}
        </Flex>
      </SimpleGrid>

      <Drawer
        placement="left"
        size="xs"
        isOpen={drawerIsOpen}
        onClose={drawerOnClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerCloseButton /> */}
          {/* <DrawerHeader>Create your account</DrawerHeader> */}

          <DrawerBody>
            <Flex h="100%" py={4} px={2} flexDir="column" gap={4} justifyContent="space-between">
              <Flex flexDir="column" gap={8}>
                <Flex justifyContent="space-between" alignItems="center" gap={4}>
                  <Logo />

                  {window.location.href.endsWith('dashboard') && (
                    <Menu>
                      <MenuButton as={Button} size='xs' variant='ghost' transition="all 0.3s" _focus={{ boxShadow: 'none' }} leftIcon={<MdOutlineDraw />} isDisabled={window.location.href.endsWith('preview')}>
                        <Flex alignItems={'center'} gap={1}>
                          <Text>{selectedProject?.name || 'No Project'}</Text>
                          <Box display={{ base: 'flex', md: 'flex' }}>
                            <MdKeyboardArrowDown />
                          </Box>
                        </Flex>
                      </MenuButton>
                      <MenuList fontSize={'sm'} boxShadow='xl'>
                        <MenuItem icon={<MdCreate />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/dashboard` : '/')}>Editor</MenuItem>
                        <MenuItem icon={<MdOutlinePreview />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/preview` : '/')}>Preview</MenuItem>
                        <MenuItem icon={<MdOutlineRocket />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/hosting` : '/')}>Publish</MenuItem>
                        <MenuItem icon={<MdOutlineSettings />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/settings` : '/')}>Settings</MenuItem>
                        <MenuItem isDisabled icon={<MdOutlinePeople />} onClick={() => navigate(selectedProject ? `/projects/${selectedProject.id}/users` : '/')}>Collaborators</MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Flex>

                {window.location.href.endsWith('dashboard') && (
                  <Flex flexDir="column" gap={4}>

                    <Button variant='outline' size='xs' leftIcon={<MdDashboard />} isDisabled={window.location.href.endsWith('preview')}>
                      {selectedProject?.mode}
                    </Button>

                    <Flex justifyContent="space-between" alignItems="center" gap={4}>
                      {/* <Flex gap={2} alignItems="center">
                        <Text fontSize='xs'>Select</Text>
                        <Switch size='sm' colorScheme='purple' isChecked={displayVisualEditor} onChange={handleDisplayVisualEditorOnChange} isDisabled={window.location.href.endsWith('preview')} />
                      </Flex> */}

                      <Button flexGrow={1} variant='solid' size='xs' colorScheme='purple' pointerEvents={localContextId ? 'initial' : 'none'} rightIcon={localContextId ? <MdRefresh /> : <MdOutlineFilterCenterFocus />} onClick={handleResetLocalContext} isDisabled={window.location.href.endsWith('preview')}>
                        {'Editing · '} {localContextId ? localContextId : "Page"}
                      </Button>
                    </Flex>

                    <Select size="xs" onChange={handleModelOnChange} value={userPreferences.ai_model}>
                      <option value='model-1'>MODEL-1</option>
                      <option value='model-2'>MODEL-2</option>
                      <option value='model-3'>MODEL-3</option>
                      <option value='model-4'>MODEL-4</option>
                    </Select>

                    <Flex gap={2} alignItems="center" justifyContent="space-between">
                      <Tooltip label='Labels: Toggles section labels.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                        <Text fontSize='xs'>Labels</Text>
                      </Tooltip>
                      <Switch size='sm' colorScheme='purple' isChecked={displaySectionLabels} onChange={handleDisplaySectionLabelsOnChange} isDisabled={window.location.href.endsWith('preview')} />
                    </Flex>
                  </Flex>
                )}

              </Flex>


              {/* <Flex gap={2} alignItems="center">
                <Text fontSize='xs'>Preview</Text>
                <Switch size='sm' colorScheme='purple' id='toggle-preview-editor-modes' onChange={handleIsPreviewModeOnChange} isChecked={window.location.href.endsWith('preview')} />
              </Flex> */}

              <Flex flexDir="column" gap={4}>
                <Flex gap={4} justifyContent="space-between">
                  <Flex gap={2} alignItems="center">
                    <Tips full={full} />
                    <ThemeSwitcher />
                  </Flex>

                  <Menu>
                    <MenuButton as={Button} variant='ghost' size='xs' transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                      <Flex alignItems={'center'} gap={1}>
                        <Text>{user && `${user.first_name} ${user.last_name}`}</Text>
                        {/* <Text>{user && `${user.first_name} ${isWideScreen ? user.last_name : ''}`}</Text> */}
                        <Box display='flex'>
                          <MdKeyboardArrowDown />
                        </Box>
                      </Flex>
                    </MenuButton>
                    <MenuList fontSize={'sm'} boxShadow='xl'>
                      <MenuItem icon={<MdOutlineCardTravel />} onClick={() => navigate('/')}>Projects</MenuItem>
                      <MenuItem icon={<MdOutlineAssignment />} onClick={() => navigate('/forms')}>Forms</MenuItem>
                      <MenuItem icon={<MdOutlineCloud />} onClick={() => navigate('/sites')}>Sites</MenuItem>
                      <MenuItem icon={<MdOutlineSettings />} onClick={() => navigate('/settings')}>Settings</MenuItem>
                      <MenuDivider />
                      <SignOutButton>Sign out</SignOutButton>
                    </MenuList>
                  </Menu>
                </Flex>

                <Button size={'xs'} onClick={() => navigate('/upgrade')}>
                  Upgrade
                </Button>
              </Flex>

            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <AlertDialog
  isOpen={isUpgradeOpen}
  leastDestructiveRef={cancelRef}
  onClose={onUpgradeClose}
  isCentered
  size="lg"
>
<AlertDialogOverlay>
    <AlertDialogContent boxShadow="lg" p={5} borderRadius="md" bg="gray.800">
      <AlertDialogHeader
        fontSize="2xl"
        fontWeight="bold"
        display="flex"
        alignItems="center"
        color="white"
      >
        <Tooltip label="Upgrade to unlock all features" aria-label="Upgrade tooltip">
          <Icon as={InfoOutlineIcon} color="purple.500" mr={3} />
        </Tooltip>
        Upgrade
      </AlertDialogHeader>

      <AlertDialogBody fontSize="lg" mt={4} display="flex" flexDirection="column" alignItems="center" color="gray.200">
        <MotionBox
          animate={{ rotate: [0, 360] }}
          transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }}
          mb={6}
        >
          <Icon as={InfoOutlineIcon} w={16} h={16} color="purple.400" />
        </MotionBox>
        <Text textAlign="center">
          <Text as="span" fontWeight="bold">
          This action is not available in your current plan.
          </Text>{" "}
          Upgrade now to unlock it and get the most out of SIFO's full suite of features.
        </Text>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent="center" mt={6}>
        <Button
          size="md"
          variant="outline"
          ref={cancelRef}
          onClick={onUpgradeClose}
          mr={3}
          color="white"
          _hover={{ bg: "gray.700" }}
        >
          Cancel
        </Button>
        <Button
          size="md"
          colorScheme="purple"
          onClick={() => navigate('/upgrade')}
          _hover={{ boxShadow: "0 0 10px rgba(128, 90, 213, 0.8)" }} variant='outline'
        >
          Unlock Now
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>
    </>
  );
};