import { useEffect, useRef } from 'react';
import { Text, Button, useColorModeValue, Flex, Link, Heading, Box, Divider } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import FacebookLogin from 'react-facebook-login';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import { SignInForm } from '../components/forms';
import { DefaultLayout } from '../layouts';
import { useUserActions } from '../hooks';
import { SoftCard } from '../components/generic/SoftCard';
import { currentUserState } from '../state';

import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";

import '../lib/anim.css';

const SignInPageContent: React.FC = () => {
  const facebookLoginRef = useRef<any>(null);

  const { authnPassword, getRememberInfo, socialAuth } = useUserActions();
  const user = useRecoilValue(currentUserState);
  const navigate = useNavigate();

  const titleColor = useColorModeValue('purple.400', 'purple.200');
  const textColor = useColorModeValue('gray.600', 'white');
  const linkColor = useColorModeValue('purple.400', 'purple.200');

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const onSubmit = async ({ email, password, remember }: { email: string; password: string; remember: boolean }) =>
    await authnPassword(email, password, remember);

  const handleSocialLogin = async (socialData: any, provider: string) => {
    let email, firstName, lastName;

    if (provider === 'google') {
      email = socialData.email;
      firstName = socialData.given_name || ''; 
      lastName = socialData.family_name || ''; 
    } else if (provider === 'facebook') {
      email = socialData.email;
      firstName = socialData.first_name;
      lastName = socialData.last_name;
    }

    try {
      await socialAuth(email, firstName, lastName, true);
    } catch (err) {
      console.error('Federated sign in error:', err);
    }
  };

  const responseFacebook = async (response: any) => {
    if (response.accessToken) {
      console.log("Facebook login successful:", response);
      await handleSocialLogin(response, 'facebook');
    } else {
      console.log('User cancelled login or did not fully authorize.');
    }
  };

  const responseGoogle = async (tokenResponse: any) => {
    console.log("Google login response:", tokenResponse);
    if (tokenResponse.access_token) {
      try {
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        });
        const userInfo = await userInfoResponse.json();
        console.log("Google user info:", userInfo);
        
        const socialData = {
          accessToken: tokenResponse.access_token,
          expiresIn: tokenResponse.expires_in,
          email: userInfo.email,
          given_name: userInfo.given_name,
          family_name: userInfo.family_name
        };
        
        await handleSocialLogin(socialData, 'google');
      } catch (error) {
        console.error("Error fetching Google user info:", error);
      }
    } else {
      console.log('Google login failed or was cancelled.');
    }
  };

  const login = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: () => console.log('Google login error occurred'),
  });

  const { identifier, remember } = getRememberInfo();

  return (
    <DefaultLayout displayLogo>
      <Flex width="100%" alignSelf="center" flexGrow={1} justifyContent="center">
        <Flex direction="column" justifyContent={{ base: 'flex-start ', md: 'center' }} flexGrow={1} maxW={600}>
          <Box paddingY="4">
            <Heading color={titleColor} mb={6}>
              Sign in
            </Heading>
            <Text fontSize="sm" color={textColor}>
              Use your Facebook or Google account to get going instantly.
            </Text>
            <Text fontSize="sm" color={textColor}>
              Or, you can {' '}
              <Link
                color={linkColor}
                textDecoration="underline"
                textUnderlineOffset={1.5}
                to="/register"
                as={RouterLink}
              >
                Register an account instead
              </Link>
            </Text>
          </Box>
          <SoftCard>
            <Flex flexDir='column' gap={8}>
              <Flex gap={4} w='full' justifyContent="center" flexDir={{ base: "column", md: "row" }}>
                <Button 
                  width={{ base: "full", md: "50%" }} 
                  height="40px" 
                  fontSize="14px" 
                  fontWeight="500"
                  rounded="4px" 
                  bg='white' 
                  color='#757575'
                  border="1px solid #dadce0"
                  _hover={{ boxShadow: '0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15)' }}
                  onClick={() => login()} 
                  leftIcon={<FcGoogle size="18px" />}
                  iconSpacing={3}
                >
                  Sign in with Google
                </Button>

                <Box ref={facebookLoginRef} display='none'>
                  <FacebookLogin
                    appId="956170292456889"
                    autoLoad={false}
                    fields="first_name,last_name,email,picture"
                    scope="public_profile,email"
                    callback={responseFacebook}
                    cssClass="facebook-auth-button"
                  />
                </Box>

                <Button 
                  width={{ base: "full", md: "50%" }} 
                  height="40px" 
                  fontSize="14px" 
                  fontWeight="unset" 
                  rounded="4px" 
                  bg='#0866FF' 
                  _hover={{ bg: '#0055e0' }} 
                  color='#FFFFFF' 
                  onClick={() => facebookLoginRef.current.querySelector('.facebook-auth-button').click()} 
                  leftIcon={<FaFacebook />}
                >
                  Sign in with Facebook
                </Button>
              </Flex>
              <Flex align="center" justify="center" my={4}>
              <Divider flex="1" />
              <Text px={4} fontWeight="bold" color="gray.500" fontSize="sm">
                OR
              </Text>
              <Divider flex="1" />
            </Flex>
              <Box>
                <SignInForm defaultIdentifier={identifier} defaultRemember={remember} onSubmit={onSubmit} />
                <Flex justifyContent="space-between" mt={5}>
                  <Text fontSize="sm" color={textColor}>
                    <Link
                      color={linkColor}
                      textDecoration="underline"
                      textUnderlineOffset={1.5}
                      to="/reset-password"
                      as={RouterLink}
                    >
                      Forgot your password?
                    </Link>
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </SoftCard>
        </Flex>
      </Flex>
    </DefaultLayout>
  );
};

export const SignInPage: React.FC = () => (
  <GoogleOAuthProvider clientId="991217074511-5se9o78cvtbi2v2nn2n5su10gmp1nid4.apps.googleusercontent.com">
    <SignInPageContent />
  </GoogleOAuthProvider>
);