import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../state';
import { Box, Flex, Image, useColorModeValue, Badge, useColorMode } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ThemeSwitcher } from '../components/layout/theme-switcher';
import { SupportButton } from '../components/generic/SupportButton';

interface DefaultLayoutProps {
  displayLogo?: boolean;
  children: React.ReactNode;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ displayLogo = false, children }) => {
  const user = useRecoilValue(currentUserState);
  const logoSource = useColorModeValue('/logo-type-light.png', '/logo-type-dark.png');
  
  const { colorMode } = useColorMode()
  const [backgroundGradient, setBackgroundGradient] = useState('');

  useEffect(() => {
    if (colorMode === 'light') setBackgroundGradient(`
    radial-gradient(
      circle at center 190%,
      rgba(68, 20, 180, 0.5) 0,
      #00000000 65em
    ) 
    `)
    else if  (colorMode === 'dark') setBackgroundGradient(`
    radial-gradient(
      circle at 50% -3em,
      #b25affff 0,
      #7539d7ff 5em,
      #4414bdff 10em,
      #00000000 30em
    )
    `)
  }, [colorMode])

  return (
    <Flex
      // css={{ background: !user ? backgroundGradient : '' }}
      flexDirection="column"
      height="100vh"
      p={{
        base: 4,
        sm: 8,
      }}
    >
      {displayLogo && (
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <Link to="/">
            <Flex alignItems='flex-end' gap={1}>
              <Image src={logoSource} height="18px" />
              {/* <Badge colorScheme='purple' variant='solid' fontSize='0.55em'>beta</Badge> */}
              {/* <Badge colorScheme='purple' variant='solid' fontSize='0.55em'>RC</Badge> */}
            </Flex>
          </Link>
          <Flex gap={3}>
            <ThemeSwitcher />
          </Flex>
        </Flex>
      )}
      {children}
    </Flex>
  );
};