import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Flex, Image, Progress, Heading, Text, useDisclosure, useColorModeValue } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react'
import { IsGeneratingLoadingScreen, IsGeneratingSpinner,CurrentMessageGroup } from '../../state';
import { useRecoilValue } from 'recoil';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as Pulse1 } from './loading-animations/Pulse1.svg';
import { ReactComponent as Pulse2 } from './loading-animations/Pulse2.svg';
import { ReactComponent as Pulse3 } from './loading-animations/Pulse3.svg';
import { ReactComponent as Pulse4 } from './loading-animations/Pulse4.svg';
import { ReactComponent as Pulse5 } from './loading-animations/Pulse5.svg';
import { ReactComponent as Pulse6 } from './loading-animations/Pulse6.svg';
import { ReactComponent as Pulse7 } from './loading-animations/Pulse7.svg';
import { ReactComponent as Pulse8 } from './loading-animations/Pulse8.svg';

const Loader = () => {
  const animations = [Pulse1, Pulse2, Pulse3, Pulse4, Pulse5, Pulse6, Pulse7, Pulse8];
  const RandomAnimation = animations[Math.floor(Math.random() * animations.length)];

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <RandomAnimation />
    </motion.div>
  );
};

const ProgressBar = () => {
  const progressBg = useColorModeValue(
    'rgba(157, 47, 255, 0.1)',
    'rgba(157, 47, 255, 0.2)'
  );
  
  return (
    <motion.div
      style={{ width: '100%', overflow: 'hidden' }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <Progress
        size="xs"
        colorScheme="purple"
        isIndeterminate
        background={progressBg}
        sx={{
          '& > div': {
            background: 'linear-gradient(90deg, rgba(157, 47, 255, 0.5) 0%, rgba(157, 47, 255, 1) 100%)',
          },
        }}
      />
    </motion.div>
  );
};

export const CompletionLoadingScreen: React.FC<any> = () => {

  const [displayedLoadingMessage, setDisplayedLoadingMessage] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { isOpen: isCompletionLoadScreenOpen, onOpen: onCompletionLoadScreenOpen, onClose: onCompletionLoadScreenClose } = useDisclosure();
  const isGeneratingLoadingScreen = useRecoilValue(IsGeneratingLoadingScreen);
  const isGeneratingSpinner = useRecoilValue(IsGeneratingSpinner);
  const currentMessageGroup = useRecoilValue(CurrentMessageGroup);

  const loadingMessages = useMemo(() => [
    "Patience is a virtue... and a necessity!",
    "Grab a snack, we'll be done soon!",
    "Rome wasn't built in a day, neither is this.",
    "Time to practice your deep breathing.",
    "Counting pixels... Just kidding, almost there!",
    "Making the digital universe align just for you.",
    "Hang tight, we're almost ready to dazzle you!",
    "Good things come to those who wait, right?",
    "Somewhere, a progress bar is smiling.",
    "Stay calm and watch the loading wheel spin!",
    "The suspense is killing us too!",
    "Waiting... The slowest form of time travel.",
    "In the meantime, why not ponder the meaning of life?",
    "Almost there... And by almost, we mean not quite yet.",
    "Hold on tight, we're taking a detour through cyberspace.",
    "The digital hamsters are working overtime!",
    "Just a moment... We're conjuring digital magic for you.",
    "Cue the dramatic music!",
    "Don't blink! Well, maybe just until we finish loading.",
    "We promise it's worth the wait. Probably.",
    "Time flies when you're... oh wait, no it doesn't.",
    "Keep calm and let the loading screen do its thing."
  ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = Math.floor(Math.random() * currentMessageGroup.length);
      setCurrentIndex(newIndex);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentMessageGroup]);

  useEffect(() => {
    setDisplayedLoadingMessage(currentMessageGroup[currentIndex]);
  }, [currentIndex, currentMessageGroup]);


  useEffect(() => {
    if (isGeneratingLoadingScreen === true) {
      if (isGeneratingSpinner === true) {
        onCompletionLoadScreenOpen()
      }
    }

    if (isGeneratingLoadingScreen === false) {
      onCompletionLoadScreenClose()
    }
  }, [isGeneratingLoadingScreen])

  const modalBg = useColorModeValue(
    'rgba(255, 255, 255, 0.95)', 
    'rgba(26, 32, 44, 0.95)'
  );
  const textColor = useColorModeValue('gray.600', 'white');
  const overlayBg = useColorModeValue(
    'rgba(0, 0, 0, 0.8)',
    'rgba(0, 0, 0, 0.9)'
  );
  const headingGradient = useColorModeValue(
    'linear(to-r, purple.500, pink.500)',
    'linear(to-r, purple.400, pink.400)'
  );

  const modalContentRef = useRef<HTMLInputElement>(null);

  return (
    <Modal 
      size="sm" 
      isOpen={isCompletionLoadScreenOpen} 
      onClose={onCompletionLoadScreenClose} 
      initialFocusRef={modalContentRef}
      isCentered
    >
      <ModalOverlay
        bg={overlayBg}
        backdropFilter="blur(8px)"
      />
      <ModalContent
        ref={modalContentRef}
        as={motion.div}
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        bg={modalBg}
        boxShadow="xl"
        borderRadius="2xl"
        overflow="hidden"
      >
        <ModalBody p={8}>
          <Flex
            flexDir="column"
            gap={6}
            alignItems="center"
          >
            <Loader />
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <Heading 
                fontSize="xl" 
                bgGradient={headingGradient}
                bgClip="text"
              >
                Please wait...
              </Heading>
            </motion.div>

            <AnimatePresence>
              <motion.div
                key={displayedLoadingMessage}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <Text 
                  textAlign="center" 
                  color={textColor}
                  fontSize="md"
                  maxW="280px"
                >
                  {displayedLoadingMessage}
                </Text>
              </motion.div>
            </AnimatePresence>

            <ProgressBar />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};