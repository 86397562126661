import { jsonrepair } from 'jsonrepair';
import { parse } from 'tolerant-json-parser';

export default new class ExtraBracketJsonParser {
    private repairedJson: string | null = null;

    removeCharactersInRange(str: string, startIndex: number, endIndex: number) {
        if (startIndex < 0 || endIndex >= str.length || startIndex > endIndex) {
            throw new Error("Invalid indices");
        }
        return str.slice(0, startIndex) + str.slice(endIndex + 1);
    }

    parse(jsonString: string) {
        try {
            this.repairedJson = jsonrepair(jsonString);
            return parse(this.repairedJson);
        } catch (error: any) {

            const splitErrorMessage = error.message.split(' ');
            const faultyCharacterIndex = splitErrorMessage[splitErrorMessage.length - 1] - 1;
            const jsonStringWithoutFaultyCharacter = this.removeCharactersInRange(jsonString, faultyCharacterIndex, faultyCharacterIndex);

            try {
                this.repairedJson = jsonrepair(jsonStringWithoutFaultyCharacter);
            } catch {
                const faultyCharacterIndex = splitErrorMessage[splitErrorMessage.length - 1] - 1;
                const jsonStringWithoutFaultyCharacter = this.removeCharactersInRange(jsonString, faultyCharacterIndex - 1, faultyCharacterIndex);

                this.repairedJson = jsonrepair(jsonStringWithoutFaultyCharacter);

            }
            
            return parse(this.repairedJson);
        }
    }

    parseMulti(jsonString: string) {
        let successfulParsing = false;
    
        while (!successfulParsing) {
            try {
                this.repairedJson = jsonrepair(jsonString);
                return parse(this.repairedJson);
            } catch (error: any) {
                const splitErrorMessage = error.message.split(' ');
                const faultyCharacterIndex = parseInt(splitErrorMessage[splitErrorMessage.length - 1], 10) - 1;
    
                jsonString = this.removeCharactersInRange(jsonString, faultyCharacterIndex, faultyCharacterIndex);
    
                if (isNaN(faultyCharacterIndex) || faultyCharacterIndex < 0) {
                    throw new Error("Unable to repair JSON string.");
                }
            }
        }
    }
}

    // removeCharacterAtIndex(str: string, index: number) {
    //     if (index < 0 || index >= str.length) {
    //         return str;
    //     } else {
    //         let before = str.slice(0, index);
    //         let after = str.slice(index + 1);
    //         return before + after;
    //     }
    // }

        // parse(jsonString: string) {
    //     try {
    //         this.repairedJson = jsonrepair(jsonString);
    //         return parse(this.repairedJson);
    //     } catch (error: any) {
    //         const splitErrorMessage = error.message.split(' ');
    //         const faultyCharacterIndex = splitErrorMessage[splitErrorMessage.length - 1] - 1;
    //         const jsonStringWithoutFaultyCharacter = this.removeCharacterAtIndex(jsonString, faultyCharacterIndex);

    //         this.repairedJson = jsonrepair(jsonStringWithoutFaultyCharacter);
    //         return parse(this.repairedJson);
    //     }
    // }