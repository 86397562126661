import React, { useRef, useEffect, useState } from 'react';
import { Box, Button, Flex, IconButton, Tooltip, useColorModeValue, useBreakpointValue } from '@chakra-ui/react';
import { usePageActions, useOpenaiActions, useSIFOActions } from '../../hooks';
import { WillRepairJavascript, Autofixer, AutofixerContext, OutlinerDeleteElementId, OutlinerTree, IsGeneratingSpinner, PageForms, PageImages, PageLinks, WipeIframeContent, IsUpdated, DisplaySectionLabels, DisplayVisualEditor, UserPreferences, HTMLCode, CSSCode, JavaScriptCode, selectedPageSelector, ViewportSourceDocument, ContentUndoHistory, ConversationHistoryUndoHistory, UndoHistoryStepIndex, DidIUndo, LocalContext, LocalContextCSS, ViewportTree, PageLinksHaveBeenUpdated, PageImagesHaveBeenUpdated, SelectedOutlinerNode, SelectedProjectMode, PageFormsHaveBeenUpdated, currentUserState, GlobalContextOuterHTML } from '../../state';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { cssjs } from 'jotform-css.js';
import { insertStyles, getIDsRecursively, getClassesRecursively, getTagsRecursively, extractCSSRulesBySelectors, extractCSSRulesBySelectorsNoKeyfranes, deleteCSSRulesBySelectors, writeCSSFromRules, fixCssSelectors, fixCssSelectorsInJs } from './css-tools';
import { compareTailwindClasses } from './compare-tw-classes';
import { hasBackgroundImage, constructPageLinksArray, constructPageFormsArray, constructPageImagesArray } from './links-tools';
import { contentEditableElements } from './content-editable-elements';
import { CssToTailwindTranslator } from "css-to-tailwind-translator";
import { useToast } from '@chakra-ui/react';
import { debounce } from 'lodash';

// import { insertJavascript } from './js-tools';
import LogicEngine from './logic-engine/engine';


import { FaDesktop } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { SlMagnifierAdd } from "react-icons/sl";
import { SlMagnifierRemove } from "react-icons/sl";
import { SlMagnifier } from "react-icons/sl";
import { GiConfirmed } from "react-icons/gi";
import { IoText } from "react-icons/io5";
import { IoMdMove } from "react-icons/io";
import { GiResize } from "react-icons/gi";
import { MdGTranslate } from "react-icons/md";

import { projectTemplatesAsLowerCaseStrings } from '../../lib/project-modes/templates';

// import { processTailwindClass, insertStyles, getIDsRecursively, extractCSSRulesBySelectors, extractCSSRulesBySelectorsNoKeyfranes, deleteCSSRulesBySelectors, writeCSSFromRules } from './css-tools';
// import dragula from 'dragula';


// DisplayVisualEditor,

export const Renderer: React.FC<any> = ({ objects, tryToFixErrorWithAi, handleClickInProcessorWhenMobile, isEnhanceCoolingDown }) => {



  const setAutofixer = useSetRecoilState<any>(Autofixer);
  const setAutofixerContext = useSetRecoilState<any>(AutofixerContext);


  const toast = useToast();

  const setOutlinerTree = useSetRecoilState<any>(OutlinerTree);

  const [hasBeenManuallyEdited, setHasBeenManuallyEdited] = useState(false);

  const setIsGeneratingSpinner = useSetRecoilState(IsGeneratingSpinner);

  const [wipeIframeContent, setWipeIframeContent] = useRecoilState<any>(WipeIframeContent);
  const [outlinerDeleteElementId, setOutlinerDeleteElementId] = useRecoilState<any>(OutlinerDeleteElementId);

  const userPreferences = useRecoilValue(UserPreferences);
  const selectedProjectMode = useRecoilValue(SelectedProjectMode);
  const [mobileView, setMobileView] = useState(false);

  const iframeReference = useRef<HTMLIFrameElement | null>(null);
  const editorColor = useColorModeValue('#805AD5', '#D6BCFA');
  const editorDeleteButtonColor = useColorModeValue('#e53e3e', '#feb2b2');

  const { updatePageContent } = usePageActions();

  const debouncedUpdatePage = debounce((viewport, pageId) => {
    // Get viewport content string
    const viewportContent = viewport.documentElement.outerHTML;

    // Create undo step
    setContentUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

      return [
        ...modifiedPrev,
        {
          timestamp: new Date().toLocaleString(),
          label: 'Edit manually',
          content: viewportContent
        }
      ]
    });

    // Increment undo step pointer/index
    setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

    // Update developer mode content
    const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
    const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
    const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

    if (htmlContent) setHTMLCode(htmlContent);
    if (cssContent) setCSSCode(cssContent);
    if (javascriptContent) setJavaScriptCode(javascriptContent);

    // Update page content in the database
    updatePageContent(pageId, viewportContent);
  }, 3500);

  const { buildLocalizationMap, buildContextTree, buildOutlinerTree } = useSIFOActions();
  // const { getTranslation } = useOpenaiActions();

  const user = useRecoilValue(currentUserState);
  const selected_page = useRecoilValue(selectedPageSelector);
  // const viewportSourceDocument = useRecoilValue(ViewportSourceDocument);
  const [viewportSourceDocument, setViewportSourceDocument] = useRecoilState<any>(ViewportSourceDocument);

  const [willRepairJavascript, setWillRepairJavascript] = useRecoilState(WillRepairJavascript);

  const [isUpdated, setIsUpdated] = useRecoilState(IsUpdated);
  const [pageLinksHaveBeenUpdated, setPageLinksHaveBeenUpdated] = useRecoilState(PageLinksHaveBeenUpdated);
  const [pageFormsHaveBeenUpdated, setPageFormsHaveBeenUpdated] = useRecoilState(PageFormsHaveBeenUpdated);
  const [pageImagesHaveBeenUpdated, setPageImagesHaveBeenUpdated] = useRecoilState(PageImagesHaveBeenUpdated);
  const [pageLinks, setPageLinks] = useRecoilState(PageLinks);
  const [pageForms, setPageForms] = useRecoilState(PageForms);
  const [pageImages, setPageImages] = useRecoilState(PageImages);

  const [didIUndo, setDidIUndo] = useRecoilState(DidIUndo);
  const [contentUndoHistory, setContentUndoHistory] = useRecoilState(ContentUndoHistory);
  // const setContentUndoHistory = useSetRecoilState(ContentUndoHistory);
  const setConversationHistoryUndoHistory = useSetRecoilState(ConversationHistoryUndoHistory);
  const [undoHistoryStepIndex, setUndoHistoryStepIndex] = useRecoilState(UndoHistoryStepIndex);

  const resetLocalContext = useResetRecoilState(LocalContext);
  const setLocalContext = useSetRecoilState(LocalContext);
  const setLocalContextCSS = useSetRecoilState(LocalContextCSS);

  const { updateLinks, updateForms, updateImages } = usePageActions();

  const isWideScreen = useBreakpointValue({ base: false, md: true });

  const isInitialMount = useRef(true);

  const [htmlCode, setHTMLCode] = useRecoilState(HTMLCode);
  const [cssCode, setCSSCode] = useRecoilState(CSSCode);
  const [javascriptCode, setJavaScriptCode] = useRecoilState(JavaScriptCode);

  const setViewportTree = useSetRecoilState(ViewportTree);
  const setGlobalContextOuterHTML = useSetRecoilState(GlobalContextOuterHTML)

  const [displayVisualEditor, setDisplayVisualEditor] = useRecoilState(DisplayVisualEditor);
  const [displaySectionLabels, setDisplaySectionLabels] = useRecoilState(DisplaySectionLabels);

  const [isDirectEditEnabled, setIsDirectEditEnabled] = useState(false);
  const [isDirectMoveEnabled, setIsDirectMoveEnabled] = useState(false);
  const [isDirectResizeEnabled, setIsDirectResizeEnabled] = useState(false);

  const insertElement = (element: HTMLElement) => {

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const parentElementId = element.getAttribute("data-parent");
    if (!parentElementId) return;

    const parentElement = viewport.getElementById(parentElementId);
    if (!parentElement) return;

    const existingElement = viewport.querySelector(`#${element.id}`);
    const previousElementID = element.getAttribute("data-is-after");
    const nextElementID = element.getAttribute("data-is-before");

    if (nextElementID) {
      const nextElement = viewport.querySelector(`#${nextElementID}`);
      if (nextElement) {
        try {
          parentElement.insertBefore(element, nextElement);
        } catch {
          parentElement.append(element);
        }
      } else {
        // Append
        parentElement.append(element);
      }

      if (existingElement) existingElement.remove();
    } else if (previousElementID) {
      const previousElement = viewport.querySelector(`#${previousElementID}`);
      if (previousElement) {
        try {
          previousElement.insertAdjacentElement('afterend', element);
        } catch {
          parentElement.append(element);
        }
      } else {
        // Append
        parentElement.append(element);
      }

      if (existingElement) existingElement.remove();
    } else {
      if (existingElement) existingElement.replaceWith(element);
      else parentElement.append(element);
    }

  }

  const createEditorElement = (type: string, styles: any, textContent?: string): Element => {

    const element = document.createElement(type);
    if (textContent) element.textContent = textContent;
    element.classList.add('sifo-manual-editor');

    element.style.userSelect = 'none';
    element.setAttribute('contenteditable', 'false');

    Object.entries(styles).forEach((e: any) => {
      element.style[e[0]] = e[1]
    })

    return element;

  }

  const clearEditor = () => {

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const elements = Array.from(viewport.querySelectorAll('.sifo-manual-editor'));
    elements.forEach((element: Element) => {
      if (element.parentNode) element.parentNode.removeChild(element);
    });

  }

  const isPropertyWritable = (obj: any, property: any) => {
    const descriptor = Object.getOwnPropertyDescriptor(obj, property);
    return descriptor ? !!descriptor.writable : true; // If descriptor is undefined, assume writable
  }

  const undrawSectionLabels = () => {
    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const existingLabels = viewport.querySelectorAll('.sifo-section-label');
    existingLabels.forEach((label: any) => {
      label.remove();
    })
  }

  const drawSectionLabels = () => {

    if (!DisplaySectionLabels) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const sections = viewport.querySelectorAll('div#body > *');
    if (sections.length < 1) return;

    // Check if it exists already, and delete it.

    const existingLabels = viewport.querySelectorAll('.sifo-section-label');
    existingLabels.forEach((label: any) => {
      label.remove();
    })

    sections.forEach((section: any) => {
      const sectionId = section.getAttribute("id");

      // const rect = section.getBoundingClientRect();
      const label = document.createElement('div');
      label.classList.add('sifo-section-label');
      label.textContent = sectionId;

      label.style.position = 'absolute';
      // label.style.left = `${rect.left + 10}px`;
      // label.style.top = `${rect.top + 10}px`;
      label.style.left = `${section.offsetLeft + 10}px`;
      label.style.top = `${section.offsetTop + 10}px`;

      label.style.color = '#fff';
      label.style.backgroundColor = '#805ad5';
      label.style.padding = '0.2rem 0.5rem';
      label.style.borderRadius = '0.3rem';
      label.style.opacity = '0.3';
      label.style.fontSize = '0.8rem';

      viewport.body.appendChild(label);
    })

  }

  const drawVisualEditor = () => {
    if (!displayVisualEditor && isWideScreen) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');

    const handleElementClick = (e: any) => {
      const localContextElement: any = viewport.querySelector(`#${e.id}`);

      const extras = localContextElement.querySelectorAll('.sifo-manual-editor');
      extras.forEach((extra: any) => {
        extra.remove();
      });

      // More cleanup here..
      const elementsWithPositioningAttributes = localContextElement.querySelectorAll('[data-parent]');
      elementsWithPositioningAttributes.forEach((elementWithPositioningAttributes: any) => {
        elementWithPositioningAttributes.removeAttribute('data-parent');
        elementWithPositioningAttributes.removeAttribute('data-is-before');
        elementWithPositioningAttributes.removeAttribute('data-is-after');
      });

      localContextElement.removeAttribute('data-parent');
      localContextElement.removeAttribute('data-is-before');
      localContextElement.removeAttribute('data-is-after');

      e.style.position = null;
      e.style.cursor = null;

      if (e.getAttribute('style') === "")
        e.removeAttribute('style')

      setLocalContext(localContextElement);

      const viewportCSS = viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
      console.log('Viewport CSS: ', viewportCSS);
      if (!viewportCSS) return;


      // Here is the CSS local context bread and butter...
      // (Okay, might not waste too much time on it.)
      // When a selector does not use the element ID, for example: the selector is a class, .service-item, and the selected element has an id
      // of #div-808284, does clicking on it trigger the proper behaviour? The answer is No.
      // how have we not noticed this before? I think it's because the AI was using IDs for styling, but as our examples grow better and more comple,
      // it is now apparent that it is in fact missing something.

      const parser = new cssjs();

      let selectors = getIDsRecursively(e);
      console.log('Selectors (getIDsRecursively(e)): ', selectors);

      let classes = getClassesRecursively(e);
      console.log('Selectors (getClassesRecursively(e)): ', classes);

      let tags = getTagsRecursively(e);
      console.log('Selectors (getTagsRecursively(e)): ', classes);

      selectors = [...selectors, ...classes, ...tags];

      console.log("selectors: ", selectors);

      // console.log('IDs and Classes: ', selectors);

      // I grab an element,
      // I get all the IDs of all its children, including itself.
      // Then I extract the rules via selectors (ids, no classes so far)
      // Then I write the wanted CSS.

      // What I need is to broaden the selectors reach. The selectors array need to include the proper selectors being used in the actual CSS.

      const parsed = parser.parseCSS(viewportCSS);

      // const elementCSS = extractCSSRulesBySelectors(parsed, selectors)
      const elementCSS = extractCSSRulesBySelectorsNoKeyfranes(parsed, selectors)
      console.log("Elements CSS: ", elementCSS);
      if (!elementCSS) return;

      const elementCSSString = writeCSSFromRules(elementCSS);
      console.log("Elements CSS String: ", elementCSSString);

      if (!elementCSSString) return;

      setLocalContextCSS(elementCSSString);

    }

    elements.forEach((e: any) => {

      e.style.userSelect = 'none';
      e.style.webkitUserSelect = 'none';
      e.style.msUserSelect = 'none';
      e.style.mozUserSelect = 'none';

      e.oncontextmenu = (event: Event) => {
        event.preventDefault();
      }

      let timer: any;

      if (!isWideScreen) {
        if (Object.isExtensible(e)) {
          if (isPropertyWritable(e, 'ontouchstart')) {
            e.ontouchstart = (event: Event) => {
              event.stopPropagation();
              // event.preventDefault();

              timer = setTimeout(() => {
                handleElementClick(e);
              }, 800)
            }

          }

          if (isPropertyWritable(e, 'ontouchend')) {
            e.ontouchend = (event: Event) => {
              clearTimeout(timer);
            }
          }
        }
      }

      // e.ontouchmove = (event: Event) => {
      //   clearTimeout(timer);
      // }

      if (!isWideScreen) return;

      e.onclick = (event: Event) => {
        event.stopPropagation();
        event.preventDefault();

        handleElementClick(e);
      }

      const highlight = createEditorElement('div', {
        position: 'absolute', top: '0', left: '0',
        height: '100%', width: '100%',
        backgroundColor: editorColor, boxShadow: `0 15px 35px ${editorColor}`, opacity: '0.2',
        zIndex: '10', pointerEvents: 'none', animation: 'none'
      });

      const actionsContainer = createEditorElement('div', {
        display: 'flex', gap: '5px',
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        fontFamily: 'sans-serif', fontWeight: 'normal', whiteSpace: 'nowrap',
        zIndex: '11', animation: 'none'
      });

      const idTag = createEditorElement('button', {
        borderRadius: '0.4em',
        color: editorColor === '#805AD5' ? '#fff' : '#000',
        backgroundColor: editorColor,
        border: 'none',
        animation: 'none', fontSize: '13px', height: '1.5rem'
      }, `#${e.id}`);

      const deleteButton = createEditorElement('button', {
        borderRadius: '0.4em',
        color: editorColor === '#805AD5' ? '#fff' : '#000',
        backgroundColor: editorDeleteButtonColor, cursor: 'pointer',
        border: 'none', animation: 'none', fontSize: '13px', height: '1.5rem'
      }, '✖ Delete');

      deleteButton.addEventListener('click', () => {
        e.remove();

        // Forms
        const pageFormTags: any = viewport.querySelectorAll('form');

        if (pageFormTags.length > 0 && selected_page && user) {
          const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
          setPageForms(pageForms);
          updateForms(selected_page.id, pageForms);
        }

        const pageAmchorTags: any = viewport.querySelectorAll('a');
        if (pageAmchorTags.length > 0) {
          const pageLinks: any = constructPageLinksArray(pageAmchorTags);
          setPageLinks(pageLinks);
          if (selected_page) updateLinks(selected_page.id, pageLinks);
        }

        // const pageImgTags: any = viewport.querySelectorAll('img');

        const allElements: any = viewport.querySelectorAll('*');

        const imageElements: any = Array.from(allElements).filter((element: any) => {
          if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
            return true;
          }
        })

        if (imageElements.length > 0) {
          const pageImages: any = constructPageImagesArray(imageElements);
          setPageImages(pageImages);
          if (selected_page) updateImages(selected_page.id, pageImages);
        }

        if (selected_page) {
          const viewportContent = viewport.documentElement.outerHTML;

          setContentUndoHistory((prev) => {
            let modifiedPrev = prev;
            if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

            return [
              ...modifiedPrev,
              {
                timestamp: new Date().toLocaleString(),
                label: 'Delete element',
                content: viewportContent
              }
            ]
          });

          updatePageContent(selected_page.id, viewportContent);

          setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

          const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
          const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
          const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

          const css_parser = new cssjs();

          const selectors = getIDsRecursively(e);
          const parsed = css_parser.parseCSS(cssContent as string);
          const filtered = deleteCSSRulesBySelectors(parsed, selectors);
          const newCSS = writeCSSFromRules(filtered);

          if (htmlContent)
            setHTMLCode(htmlContent);

          if (cssContent)
            setCSSCode(newCSS);

          if (javascriptContent)
            setJavaScriptCode(javascriptContent);
        }
      })

      e.onmouseover = (event: Event) => {
        event.stopPropagation();
        e.style.position = 'relative';

        clearEditor();

        actionsContainer.appendChild(idTag);
        actionsContainer.appendChild(deleteButton);

        e.appendChild(highlight);
        if (e.hasChildNodes())
          e.appendChild(actionsContainer);
      }

      e.onmouseleave = (event: Event) => {
        e.style.position = null; // Is this enough, or should I add removeAttribute style?

        // Check if style attribute is empty, delete it.
        if (e.getAttribute('style') === '') {
          e.removeAttribute('style');
        }

        highlight.remove();
        actionsContainer.remove();
      }

    })
  }

  const clearVisualEditor = () => {
    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');

    elements.forEach((e: any) => {

      e.onclick = null;
      e.onmouseover = null;
      e.onmouseleave = null;

      // e.addEventListener('mouseover', (event: Event) => {
      //   event.stopPropagation();
      //   e.style.position = 'relative';

      //   clearEditor();

      //   actionsContainer.appendChild(idTag);
      //   actionsContainer.appendChild(deleteButton);

      //   e.appendChild(highlight);
      //   if (e.hasChildNodes())
      //     e.appendChild(actionsContainer);
      // })

      // e.addEventListener('mouseleave', (event: Event) => {
      //   e.style.position = null; // Is this enough, or should I add removeAttribute style?
      //   highlight.remove();
      //   actionsContainer.remove();
      // })


    })
  }


  const selectedOutlinerNode = useRecoilValue<any>(SelectedOutlinerNode);

  useEffect(() => {
    if (!selectedOutlinerNode) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const localContextElement: any = viewport.querySelector(`${selectedOutlinerNode.nodeTag}#${selectedOutlinerNode.nodeId}`);
    if (localContextElement) {
      setLocalContext(localContextElement);

      const viewportCSS = viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
      if (!viewportCSS) return;

      const parser = new cssjs();

      const selectors = getIDsRecursively(localContextElement);
      const parsed = parser.parseCSS(viewportCSS);

      // const elementCSS = extractCSSRulesBySelectors(parsed, selectors)
      const elementCSS = extractCSSRulesBySelectorsNoKeyfranes(parsed, selectors)
      if (!elementCSS) return;

      const elementCSSString = writeCSSFromRules(elementCSS);
      if (!elementCSSString) return;

      setLocalContextCSS(elementCSSString);
    }

  }, [selectedOutlinerNode])

  // const reloadJavaScript = (viewport: any, scriptContent: string) => {
  //   const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
  //   if (scriptElement) scriptElement.remove();

  //   // const errorDetectionScript = viewport.querySelector('script[data-id="error-detection-script"]');
  //   // if(errorDetectionScript) errorDetectionScript.remove();

  //   iframeReference.current!.srcdoc = viewport.documentElement.outerHTML;

  //   const newScriptElement = viewport.createElement('script');
  //   newScriptElement.setAttribute('data-dev-tag', 'inject-into');
  //   newScriptElement.innerHTML = scriptContent;

  //   // const newErrorDetectionScript = viewport.createElement('script');
  //   // newErrorDetectionScript.setAttribute('data-dev-tag', 'sifo-dev');
  //   // newErrorDetectionScript.setAttribute('data-id', 'error-detection-script');
  //   // newErrorDetectionScript.innerHTML = `
  //   // let errorDetected = false;

  //   // const errorLog = (event) => {
  //   //     if (errorDetected) return;
  //   //     errorDetected = true;

  //   //     event.preventDefault();
  //   //     event.stopPropagation();

  //   //     const errorMessage = event.message;

  //   //     window.parent.postMessage({
  //   //         type: 'js-exec-error',
  //   //         errmsg: errorMessage
  //   //     }, '*');
  //   // }

  //   // window.addEventListener("error", errorLog);
  //   // `;


  //   // viewport.body.appendChild(newErrorDetectionScript);
  //   viewport.body.appendChild(newScriptElement);
  // }

  const reloadJavaScript = (viewport: any, scriptContent: string) => {
    const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
    scriptElement.remove();

    const bodyDiv = viewport.querySelector('div#body');
    const clonedBodyDiv = bodyDiv.cloneNode(true);
    bodyDiv.remove();

    viewport.body.prepend(clonedBodyDiv);

    // const code = scriptElement.innerHTML;

    const newScriptElement = viewport.createElement('script');
    newScriptElement.setAttribute('data-dev-tag', 'inject-into');
    newScriptElement.innerHTML = scriptContent;

    viewport.body.appendChild(newScriptElement);

    // console.log(
    //   scriptElement,
    //   newScriptElement,
    //   viewport.body
    // );

  }

  // function findParentSection(element: any): any {
  //   if (element.parentElement && element.parentElement.id === 'body') {
  //     return element;
  //   } else if (element.parentElement) {
  //     return findParentSection(element.parentElement);
  //   } else {
  //     return null;
  //   }
  // }

  function joinSet(set: any) {
    const arrayFromSet = [...set];
    const concatenatedString = arrayFromSet.join('\n');
    return concatenatedString;
  }

  function createDependencyScriptTag(cdnLink: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = cdnLink;
    script.classList.add('sifo-lib-cdn')

    return script;
  }

  function createStylesheetLinkTag(cssLink: string) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = cssLink;
    link.classList.add('sifo-lib-css')

    return link;
  }

  function getIframeScrollPosition() {
    const iframeDocument = iframeReference.current!.contentWindow!.document;
    const scrollLeft = iframeDocument.documentElement.scrollLeft || iframeDocument.body.scrollLeft;
    const scrollTop = iframeDocument.documentElement.scrollTop || iframeDocument.body.scrollTop;

    return { scrollLeft, scrollTop };
  }

  function setIframeScrollPosition(x: any, y: any) {
    const iframeWindow = iframeReference.current!.contentWindow;
    if (iframeWindow) iframeWindow.scrollTo(x, y);
  }

  function refreshViewport() {
    const iframe = iframeReference.current;
    const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
    if (!viewport) return;

    const scrollPos = getIframeScrollPosition();
    iframe.srcdoc = viewport.documentElement.outerHTML;

    iframe.onload = () => {
      setIframeScrollPosition(scrollPos.scrollLeft, scrollPos.scrollTop);
    };
  }


  useEffect(() => {
    if (!isInitialMount.current) {
      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (viewport && objects) {
        if (objects.html) {
          objects.html.forEach((HTMLElement: HTMLElement): void => {
            insertElement(HTMLElement);

            // Check img here
            // console.log("HTMLElement.tagName, HTMLElement.tagName: ", HTMLElement.tagName, HTMLElement.tagName)
            // if (HTMLElement.tagName === 'IMG') {
            //   const imageSrc = HTMLElement.getAttribute("src");

            //   if (!imageSrc) return;
            //   if (imageSrc === "#") return;

            //   const placeholderImageDimensions = getPlaceholderDimensions(imageSrc);

            //   if (placeholderImageDimensions) {
            //     objects.html.push(`#${HTMLElement.getAttribute("id")} { max-height: ${placeholderImageDimensions.height}px; }`)
            //   } 
            // }

            // if (HTMLElement.tagName.toLowerCase() === 'div') iframeReference.current?.contentWindow?.postMessage(HTMLElement.id, '*');
          });
        }

        /*
        Insert libraries' stylesheets (dependencies)
        */

        if (objects.stylesheets && objects.stylesheets.length > 0) {
          objects.stylesheets.forEach((stylesheet: any) => {
            const stylesheetLink = createStylesheetLinkTag(stylesheet);

            const viewportHead = viewport.head;
            if (!viewportHead) return;

            const injectIntoStyle = viewport.querySelector('style[data-dev-tag="inject-into"]');
            if (!injectIntoStyle) return;

            const stylesheetHref = stylesheetLink.getAttribute("href");
            let existingStylesheetLink = viewport.querySelector(`style[href="${stylesheetHref}"]`);

            if (!existingStylesheetLink) {
              viewportHead.insertBefore(stylesheetLink, injectIntoStyle);
            }
          })
        }

        if (objects.css) {
          // console.log("objects.css: ", objects.css)


          // console.log("objects.css: ", objects.css);

          const mediaQueriesRegExp = /@media\s*\(.*\)-->(.*)/;
          const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');

          objects.css.forEach((CSSBlock: string) => {

            // console.log("CSSBlock: ", CSSBlock);
            // console.log("fixCssSelectors(CSSBlock): ", fixCssSelectors(CSSBlock));
            // console.log("styleElement: ", styleElement);
            // console.log("(1) styleElement.innerHTML: ", styleElement?.innerHTML);

            CSSBlock = fixCssSelectors(CSSBlock);
            if (styleElement) styleElement.innerHTML = insertStyles(styleElement.innerHTML, CSSBlock);

            // console.log("(2) styleElement.innerHTML: ", styleElement?.innerHTML);


            // if (
            //   userPreferences.ai_model === 'model-1' && (
            //     ['tailwind', 'daisyui'].includes(selectedProjectMode.toLowerCase()) ||
            //     projectTemplatesAsLowerCaseStrings.includes(selectedProjectMode.toLowerCase())
            //   )
            // ) {
            //   const conversionResult = CssToTailwindTranslator(CSSBlock);

            //   console.log('conversionResult: ', conversionResult);
            //   if (conversionResult.code === 'OK') {
            //     conversionResult.data.forEach((result: any) => {

            //       const classes = result.resultVal.split(' ');

            //       console.log('classes: ', classes);
            //       classes.forEach((className: string) => {

            //         console.log('className.trim(): ', className.trim(), className.trim().length);
            //         if (className.trim().length < 1) return;

            //         const selectorWithTwBgUrl = result.selectorName;
            //         const regex = /bg-\[url\(['"]?(.*?)['"]?\)]/;
            //         const extractedUrlFromTwBgUrl = regex.exec(className);

            //         if (extractedUrlFromTwBgUrl) {
            //           const generatedCssBlock = `${selectorWithTwBgUrl} { background-image: url("${extractedUrlFromTwBgUrl[1]}"); }`;
            //           if (styleElement) styleElement.innerHTML = insertStyles(styleElement.innerHTML, generatedCssBlock);
            //         } else {

            //           let selectorsBeforeSplitting: any = '';

            //           if (result.selectorName.startsWith('@media')) {
            //             const matches = result.selectorName.match(mediaQueriesRegExp);
            //             if (matches) {
            //               selectorsBeforeSplitting = matches[1];
            //             }
            //           } else {
            //             selectorsBeforeSplitting = result.selectorName;
            //           }

            //           const selectors = selectorsBeforeSplitting.split(',');
            //           console.log('selectors: ', selectors);

            //           selectors.forEach((selectorName: string) => {


            //             console.log('selectorName.trim(): ', selectorName.trim());

            //             let tailwindElements;

            //             try {
            //               tailwindElements = viewport.querySelectorAll(selectorName.trim());
            //             } catch {
            //               tailwindElements = null;
            //             }

            //             if (tailwindElements) {
            //               tailwindElements.forEach((tailwindElement: any) => {
            //                 const existingClasses = tailwindElement.classList;

            //                 console.log('existingClasses: ', existingClasses);
            //                 existingClasses.forEach((existingClassName: string) => {

            //                   console.log('We are about to compare: ', existingClassName, ' and ', className);
            //                   if (compareTailwindClasses(existingClassName, className)) {

            //                     console.log('They are supposedly affecting the same propety, so we\'re deleting: ', existingClassName);
            //                     tailwindElement.classList.remove(existingClassName);
            //                   }
            //                 });

            //                 console.log('Now we\'re adding: ', className);
            //                 tailwindElement.classList.add(className)
            //               })
            //             }


            //           });
            //         }

            //       });
            //     })
            //   }
            // } else {
            //   if (styleElement) styleElement.innerHTML = insertStyles(styleElement.innerHTML, CSSBlock);
            // }
          })
        }

        if (objects.dependencies && objects.dependencies.length > 0) {
          objects.dependencies.forEach((dependency: any) => {
            const dependencyScript = createDependencyScriptTag(dependency);

            const viewportBody = viewport.body;
            if (!viewportBody) return;

            const injectIntoScript = viewport.querySelector('script[data-dev-tag="inject-into"]');
            if (!injectIntoScript) return;

            const scriptSrc = dependencyScript.getAttribute("src");
            let existingDependencyScript = viewport.querySelector(`script[src="${scriptSrc}"]`);

            if (!existingDependencyScript) {
              viewportBody.insertBefore(dependencyScript, injectIntoScript);
            }
          })
        }

        if (objects.javascript) {
          const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
          if (!scriptElement) return;

          let scriptContent: string = scriptElement!.innerHTML;

          objects.javascript.forEach((JSCode: string) => {
            JSCode = fixCssSelectorsInJs(JSCode);

            if (willRepairJavascript) { // If repairing bad code, overwrite.
              // Overwrite.
              scriptContent = JSCode;
            } else { // Use logic engine
              scriptContent = LogicEngine(scriptContent, JSCode);
              // scriptContent = insertJavascript(scriptContent, JSCode);
            }
          })

          scriptElement.innerHTML = scriptContent;

          if (objects.javascript.length > 0) {
            refreshViewport();

            // iframeReference.current!.srcdoc = viewport.documentElement.outerHTML;
            // reloadJavaScript(viewport, scriptContent);
          }

          setWillRepairJavascript(false);

          // console.log('scriptContent: ', scriptContent);

        }

        detectPlaceholderImages();

        // setIsGeneratingSpinner(false);


        if (selected_page) {
          const viewportContent = viewport.documentElement.outerHTML;

          setContentUndoHistory((prev) => {
            let modifiedPrev = prev;
            if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

            return [
              ...modifiedPrev,
              {
                timestamp: new Date().toLocaleString(),
                label: 'Generate elements',
                content: viewportContent
              }
            ]
          });

          setConversationHistoryUndoHistory((prev) => {
            let modifiedPrev = prev;
            if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
            return modifiedPrev;
          })

          setDidIUndo(false);
          updatePageContent(selected_page.id, viewportContent);

        }

        setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

        // This is where the JS bug code lives:

        const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
        const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
        const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;


        // console.log('javascriptContent: ', javascriptContent);

        if (htmlContent)
          setHTMLCode(htmlContent);

        if (cssContent)
          setCSSCode(cssContent);

        if (javascriptContent)
          setJavaScriptCode(javascriptContent);

        // This is where the JS code potential location ends



        /*
        Legacy way of doing things, using the viewportTree for context,
        and a specific format of completions.
        */

        // const viewportTree = buildContextTree(viewport.documentElement);
        // setViewportTree(viewportTree);

        // Optimized version
        const bodyElement = viewport.querySelector("div#body");

        if (bodyElement) {
          const bodyClone: any = bodyElement.cloneNode(true);

          bodyClone.querySelectorAll('[contenteditable]')
            .forEach((element: any) => element.removeAttribute('contenteditable'));

          setGlobalContextOuterHTML(bodyClone.innerHTML);

          viewport.querySelectorAll('*:not(html):not(body):not(div#body)')
            .forEach((element: any) => {
              if (contentEditableElements.includes(element.tagName.toLowerCase())) {
                element.setAttribute('contenteditable', 'true');
              }
            });
        }

        // Unoptimized version
        // /*
        // New method of doing things, context is plain html,
        // and we remove contenteditable from context because it is not needed,
        // as it is injected afterwards.
        // */

        // const bodyElement = viewport.querySelector("div#body");

        // if (bodyElement) {
        //   bodyElement.querySelectorAll('[contenteditable]').forEach(element => element.removeAttribute('contenteditable'));
        //   setGlobalContextOuterHTML(bodyElement.innerHTML);
        // }

        // /* 
        // Enable direct edit.
        // Add contenteditable attribute to all elements.
        // */

        // const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');

        // elements.forEach((e: any) => {
        //   if (contentEditableElements.includes(
        //     e.tagName.toLowerCase()
        //   )) {
        //     e.setAttribute('contenteditable', 'true');
        //   }
        // })

        // setGlobalContextOuterHTML(viewport.querySelector("div#body")!.innerHTML);
        // setGlobalContextOuterHTML(viewport.querySelector("div#body")!.outerHTML);

        // Forms
        const pageFormTags: any = viewport.querySelectorAll('form');
        console.log('pageFormTags:', pageFormTags);

        if (pageFormTags.length > 0 && selected_page && user) {
          const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
          console.log('constructPageFormsArray(pageForms):', pageForms);
          setPageForms(pageForms);
          console.log('Setting page forms...');
          if (selected_page) updateForms(selected_page.id, pageForms);
          console.log('Updating forms in database...');
        }

        const pageAmchorTags: any = viewport.querySelectorAll('a');
        if (pageAmchorTags.length > 0) {
          const pageLinks: any = constructPageLinksArray(pageAmchorTags);
          setPageLinks(pageLinks);
          if (selected_page) updateLinks(selected_page.id, pageLinks);
        }

        const allElements: any = viewport.querySelectorAll('*');
        const imageElements: any = Array.from(allElements).filter((element: any) => {
          if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
            return true;
          }
        })

        if (imageElements.length > 0) {
          const pageImages: any = constructPageImagesArray(imageElements);
          setPageImages(pageImages);
          if (selected_page) updateImages(selected_page.id, pageImages);
        }

        // iframeReference.current!.srcdoc = viewport.documentElement.outerHTML;





        // Detect image elements with `auto` size.

        // const images = viewport.querySelectorAll('img');

        // let sectionsOuterHtml = new Set();
        // let willTriggerAutofix = false;

        // images.forEach((img: any) => {
        //   const heightStyle = window.getComputedStyle(img).height;
        //   if (heightStyle === 'auto' || !img.style.height) {

        //     // The current image is one of those images, gotta find its parent, and construct a set, and pass it as context. 
        //     const imageParentSection = findParentSection(img);
        //     if (imageParentSection) {
        //       sectionsOuterHtml.add(imageParentSection.outerHTML);
        //       // Push the outerHTML to the set.
        //     }

        //     willTriggerAutofix = true;
        //     // AAAAA,  it depends on the number of images, I see.
        //   }
        // });

        // Autofixer after completions
        // if (!isEnhanceCoolingDown) {
        //   const isUnlimitedImages: any = detectUnlimitedImages();

        //   if (isUnlimitedImages[0]) {
        //     const context = joinSet(isUnlimitedImages[1]);
        //     setAutofixerContext(context);
        //     setAutofixer("images-unlimited-height");
        //   }
        // }

        /* 
        Enable direct resize.
        Disable it, then enable it, to refresh.
        */

        // iframeReference.current?.contentWindow?.postMessage('direct-resize-disabled', '*');
        // iframeReference.current?.contentWindow?.postMessage('direct-resize-enabled', '*');

        // refreshViewport();

        // iframeReference.current?.contentWindow?.postMessage('direct-resize-refresh', '*');


      }
    } else isInitialMount.current = false;
  }, [objects])

  // useEffect(() => {

  //   // No IDs

  //   const iframe = iframeReference.current;
  //   const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
  //   if (!viewport) return;

  //   iframe.onload = () => {
  //     // Generate IDs for elements that do not have IDs.
  //     const elementsWithoutId = viewport.querySelectorAll('div#body *:not([id])');

  //     elementsWithoutId.forEach((element: any) => {
  //       const tag = element.tagName.toLowerCase() || 'unknown';
  //       const randomId = Math.floor(Math.random() * 1000000);
  //       element.setAttribute("id", `${tag}-${randomId}`);
  //     })

  //   }

  // }, [selected_page])

  /*
  Rebuild context on iframe load
  Generate parent IDs and data attributes for elements that don't have them.
  */

  useEffect(() => {
    const iframe = iframeReference.current;
    if (!iframe) return;

    iframe.onload = () => {
      const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
      if (!viewport) return;

      const elementsWithoutId = viewport.querySelectorAll('div#body *:not([id])');

      elementsWithoutId.forEach((element: any) => {
        const tag = element.tagName.toLowerCase() || 'unknown';
        const randomId = Math.floor(Math.random() * 1000000);
        element.setAttribute("id", `${tag}-${randomId}`);
      })

      /*
      New method of doing things, context is plain html,
      and we remove contenteditable from context because it is not needed,
      as it is injected afterwards.
      */

      const bodyElement = viewport.querySelector("div#body");

      if (bodyElement) {
        bodyElement.querySelectorAll('[contenteditable]').forEach(element => element.removeAttribute('contenteditable'));
        setGlobalContextOuterHTML(bodyElement.innerHTML);
      }

      const htmlContent = viewport.querySelector('div#body')?.innerHTML;
      const cssContent = viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
      const javascriptContent = viewport.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

      if (htmlContent) setHTMLCode(htmlContent);
      if (cssContent) setCSSCode(cssContent);
      if (javascriptContent) setJavaScriptCode(javascriptContent);

      /* 
      Enable direct edit.
      Add contenteditable attribute to all elements.
      */

      const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');

      elements.forEach((e: any) => {
        if (contentEditableElements.includes(
          e.tagName.toLowerCase()
        )) {
          e.setAttribute('contenteditable', 'true');
        }
      })

      /* 
      Enable direct resize.
      Disable it, then enable it, to refresh.
      */

      // iframeReference.current?.contentWindow?.postMessage('direct-resize-disabled', '*');
      // iframeReference.current?.contentWindow?.postMessage('direct-resize-enabled', '*');

      // iframeReference.current?.contentWindow?.postMessage('direct-resize-refresh', '*');
      // refreshViewport();
    }

  }, [selected_page])

  // Generate panels data for selected page

  // useEffect(() => {
  //   if (pageLinks.length === 0 && pageImages.length === 0 && pageForms.length === 0) {
  //     const iframe = iframeReference.current;
  //     const viewport = iframe?.contentDocument || iframe?.contentWindow?.document;
  //     if (!viewport) return;

  //     iframe.onload = () => {

  //       console.log("selected_page: About to generate and fetch now: ###### ", selected_page);

  //       const pageAmchorTags: any = viewport.querySelectorAll('a');
  //       if (pageAmchorTags.length > 0) {
  //         const pageLinks: any = constructPageLinksArray(pageAmchorTags);
  //         if (selected_page) updateLinks(selected_page.id, pageLinks);
  //         setPageLinks(pageLinks);
  //       }

  //       const allElements: any = viewport.querySelectorAll('*');
  //       const imageElements: any = Array.from(allElements).filter((element: any) => {
  //         if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
  //           return true;
  //         }
  //       })

  //       if (imageElements.length > 0) {
  //         const pageImages: any = constructPageImagesArray(imageElements);
  //         if (selected_page) updateImages(selected_page.id, pageImages);
  //         setPageImages(pageImages);
  //       }



  //       const pageFormTags: any = viewport.querySelectorAll('form');
  //       // console.log('pageFormTags:', pageFormTags);

  //       if (pageFormTags.length > 0 && selected_page && user) {
  //         const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
  //         // console.log('constructPageFormsArray(pageForms):', pageForms);
  //         // console.log('Setting page forms...');
  //         if (selected_page) updateForms(selected_page.id, pageForms);
  //         setPageForms(pageForms);
  //         // console.log('Updating forms in database...');
  //       }

  //     };
  //   }

  // }, [pageLinks, pageImages, pageForms, selected_page])

  // useEffect(() => {
  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;

  //   if (viewport && contentUndoHistory) {
  //     const htmlContent = viewport.querySelector('div#body')?.innerHTML;
  //     const cssContent = viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
  //     const javascriptContent = viewport.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

  //     if (htmlContent)
  //       setHTMLCode(htmlContent);

  //     if (cssContent)
  //       setCSSCode(cssContent);

  //     if (javascriptContent)
  //       setJavaScriptCode(javascriptContent);
  //   }

  // }, [contentUndoHistory])

  const confirmManualEdits = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const elements = viewport.querySelectorAll('[contenteditable]');
    elements.forEach((e: any) => {
      e.removeAttribute('contenteditable');
    })

    const viewportContent = viewport.documentElement.outerHTML;

    setContentUndoHistory((prev) => {
      let modifiedPrev = prev;
      if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

      return [
        ...modifiedPrev,
        {
          timestamp: new Date().toLocaleString(),
          label: 'Edit manually',
          content: viewportContent
        }
      ]
    });

    setDidIUndo(false);
    updatePageContent(selected_page.id, viewportContent);
    setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

    const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
    const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
    const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

    if (htmlContent)
      setHTMLCode(htmlContent);

    if (cssContent)
      setCSSCode(cssContent);

    if (javascriptContent)
      setJavaScriptCode(javascriptContent);

    setHasBeenManuallyEdited(false);
    // setIsDirectEditEnabled(false);

    // iframeReference.current?.contentWindow?.postMessage('move-mode-disabled', '*');
    // setIsDirectMoveEnabled(false);

    // iframeReference.current?.contentWindow?.postMessage('direct-resize-disabled', '*');
    // setIsDirectResizeEnabled(false);

    disableDirectEdit();
    disableDirectMove();
    disableDirectResize();
  }

  const enableDirectEdit = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    if (displayVisualEditor) setDisplayVisualEditor(false);

    // When enabled, button turns solid, like sidebar, or outline maybe, since smaller icons, and different

    if (isDirectMoveEnabled) {
      disableDirectMove();
    }

    if (isDirectResizeEnabled) {
      disableDirectResize();
    }

    setIsDirectEditEnabled(true);

    const elements = viewport.querySelectorAll('*:not(html):not(body):not(div#body)');
    elements.forEach((e: any) => {
      if (contentEditableElements.includes(
        e.tagName.toLowerCase()
      )) {
        e.setAttribute('contenteditable', 'true');
      }
    })

    // Put controls back in center in highlighter
    // Revert back to clicking on element to select context
  }

  const disableDirectEdit = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    setIsDirectEditEnabled(false);

    const elements = viewport.querySelectorAll('[contenteditable]');
    elements.forEach((e: any) => {
      e.removeAttribute('contenteditable');
    })
  }

  const enableDirectMove = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    if (displayVisualEditor) setDisplayVisualEditor(false);

    if (isDirectEditEnabled) {
      disableDirectEdit();
    }

    if (isDirectResizeEnabled) {
      disableDirectResize();
    }

    iframeReference.current?.contentWindow?.postMessage('move-mode-enabled', '*');
    setIsDirectMoveEnabled(true);
  }

  const disableDirectMove = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    iframeReference.current?.contentWindow?.postMessage('move-mode-disabled', '*');
    setIsDirectMoveEnabled(false);
  }

  const enableDirectResize = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    if (displayVisualEditor) setDisplayVisualEditor(false);

    if (isDirectEditEnabled) {
      disableDirectEdit();
    }

    if (isDirectMoveEnabled) {
      disableDirectMove();
    }

    iframeReference.current?.contentWindow?.postMessage('direct-resize-enabled', '*');
    setIsDirectResizeEnabled(true);
  }

  const disableDirectResize = () => {
    if (!selected_page) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    iframeReference.current?.contentWindow?.postMessage('direct-resize-disabled', '*');
    setIsDirectResizeEnabled(false);
  }

  // window.onmessage = (e) => {
  //   if (e.data === 'manually-edited') setHasBeenManuallyEdited(true);
  // }

  window.onmessage = (e) => {
    // console.log(e.data);

    if (e.data === 'close-panels-on-mobile') handleClickInProcessorWhenMobile();

    /*
    Old way of handling direct edit,
    by way of enabling a special mode, and changing the state
    to allow for the activation of the apply changes button.
    */

    // if (e.data === 'manually-edited') setHasBeenManuallyEdited(true);

    /*
    We update pageContent regularly, after manual edits,
    and we debounce it to not trigger too many calls to the server,
    keeping things in sync.
    */

    if (e.data === 'manually-edited') {
      if (!selected_page) return;

      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      debouncedUpdatePage(viewport, selected_page.id);
    }




    if (e.data.type === 'js-exec-error') {

      const errorMessage = e.data.errmsg;

      // Don't run this when throttled,
      // but throttled how?
      // on a per project basis, or a cooldown?

      // tryToFixErrorWithAi(errorMessage);

      // toast({
      //   title: 'Code execution error',
      //   description: 'Looks like there is an error in the code, please undo and try again.',
      //   variant: 'solid',
      //   status: 'error',
      //   position: 'bottom-left',
      //   duration: 6000,
      // });

      // const errorMessage = e.data.errmsg;

      // toast({
      //   position: 'bottom-left',
      //   duration: 6000,
      //   render: () => (
      //     <Box>
      //       <Button colorScheme="red" onClick={() => tryToFixErrorWithAi(errorMessage)} size="sm">Try to fix.</Button>
      //     </Box>
      //   ),
      // })

    }
  }

  useEffect(() => {
    // console.log(viewportSourceDocument); //

    // console.log("viewportSourceDocument: ", viewportSourceDocument);

    if (viewportSourceDocument) {
      iframeReference.current!.srcdoc = viewportSourceDocument;
    }

    // For extra caution?
    clearVisualEditor();
    undrawSectionLabels();

    // if (!displaySectionLabels) {
    //   undrawSectionLabels();
    // }

    // console.log(viewportSourceDocument);
    // console.log(iframeReference.current!.srcdoc);

    // if (iframeReference.current)
    // iframeReference.current.srcdoc = viewportSourceDocument;
  }, [viewportSourceDocument, selected_page?.id])

  useEffect(() => {
    if (undoHistoryStepIndex) {
      setViewportSourceDocument(null);
    }
  }, [undoHistoryStepIndex])

  // useEffect(() => {
  //   if (didIUndo) {
  //     undrawSectionLabels();
  //   } 
  // }, [didIUndo])

  // useEffect(() => {
  //   if (!isUpdated) return;

  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;


  //   const bodyElement = viewport.querySelector('div#body');
  //   if (bodyElement) bodyElement.innerHTML = htmlCode;

  //   const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');
  //   if (styleElement) styleElement.innerHTML = cssCode;

  //   const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
  //   if (scriptElement) scriptElement.innerHTML = javascriptCode;

  // }, [htmlCode, cssCode, javascriptCode]);

  useEffect(() => {
    if (!isUpdated) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const bodyElement = viewport.querySelector('div#body');
    if (bodyElement) bodyElement.innerHTML = htmlCode;
  }, [htmlCode]);

  useEffect(() => {
    if (!isUpdated) return;

    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');
    if (styleElement) styleElement.innerHTML = cssCode;
  }, [cssCode]);

  useEffect(() => {
    if (!isUpdated) return;

    // I probably need to reset the flag for consecutive ones not working,
    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
    if (scriptElement) scriptElement.innerHTML = javascriptCode;

    refreshViewport();
    // iframeReference.current!.srcdoc = viewport.documentElement.outerHTML;

    // console.log(viewport.documentElement.outerHTML);
    // reloadJavaScript(viewport, javascriptCode);
  }, [javascriptCode]);

  useEffect(() => {
    if (isUpdated) {
      if (!selected_page) return;
      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      // Forms
      const pageFormTags: any = viewport.querySelectorAll('form');

      if (pageFormTags.length > 0 && selected_page && user) {
        const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
        setPageForms(pageForms);
        if (selected_page) updateForms(selected_page.id, pageForms);
      }

      const pageAmchorTags: any = viewport.querySelectorAll('a');
      if (pageAmchorTags.length > 0) {
        const pageLinks: any = constructPageLinksArray(pageAmchorTags);
        setPageLinks(pageLinks);
        if (selected_page) updateLinks(selected_page.id, pageLinks);
      }

      const allElements: any = viewport.querySelectorAll('*');

      const imageElements: any = Array.from(allElements).filter((element: any) => {
        if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
          return true;
        }
      })

      if (imageElements.length > 0) {
        const pageImages: any = constructPageImagesArray(imageElements);
        console.log('pageImages: ', pageImages);
        setPageImages(pageImages);
        if (selected_page) updateImages(selected_page.id, pageImages);
      }

      // const elems = viewport.querySelectorAll('div#body *');
      // if (elems.length > 0) {
      //   elems.forEach((elem: any) => {
      //     const elemId = elem.getAttribute('id');

      //     if (!elemId) {
      //       const tag = elem.tagName.toLowerCase() || 'unknown';
      //       const randomId = Math.floor(Math.random() * 1000000);
      //       elem.setAttribute('id', `${tag}-${randomId}`)
      //     }
      //   })
      // };

      const viewportContent = viewport.documentElement.outerHTML;

      setContentUndoHistory((prev) => {
        let modifiedPrev = prev;
        if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

        return [
          ...modifiedPrev,
          {
            timestamp: new Date().toLocaleString(),
            label: 'Developer mode edit',
            content: viewportContent
          }
        ]
      });

      setConversationHistoryUndoHistory((prev) => {
        let modifiedPrev = prev;
        if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
        return modifiedPrev;
      })

      setDidIUndo(false);
      updatePageContent(selected_page.id, viewportContent);
      setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

      /*
      Legacy way of doing things, using the viewportTree for context,
      and a specific format of completions.
      */

      // const viewportTree = buildContextTree(viewport.documentElement);
      // setViewportTree(viewportTree);

      /*
      New method of doing things, context is plain html,
      and we remove contenteditable from context because it is not needed,
      as it is injected afterwards.
      */

      const bodyElement = viewport.querySelector("div#body");

      if (bodyElement) {
        bodyElement.querySelectorAll('[contenteditable]').forEach(element => element.removeAttribute('contenteditable'));
        setGlobalContextOuterHTML(bodyElement.innerHTML);
      }

      // setGlobalContextOuterHTML(viewport.querySelector("div#body")!.innerHTML);
      // setGlobalContextOuterHTML(viewport.querySelector("div#body")!.outerHTML);

    }


    setIsUpdated(false);
  }, [isUpdated])

  useEffect(() => {
    if (pageLinksHaveBeenUpdated) {
      if (!selected_page) return;
      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      // Do my changes,
      pageLinks.forEach((link: any) => {
        const anchorTag = viewport.querySelector(`#${link.id}`);
        if (anchorTag) {
          anchorTag.innerHTML = link.inner;
          anchorTag.setAttribute('href', link.href);
        }
      })

      // Grab content
      const viewportContent = viewport.documentElement.outerHTML;

      // Set undo history
      setContentUndoHistory((prev) => {
        let modifiedPrev = prev;
        if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

        return [
          ...modifiedPrev,
          {
            timestamp: new Date().toLocaleString(),
            label: 'Edit links',
            content: viewportContent
          }
        ]
      });

      setConversationHistoryUndoHistory((prev) => {
        let modifiedPrev = prev;
        if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
        return modifiedPrev;
      })

      setDidIUndo(false);
      updatePageContent(selected_page.id, viewportContent);
      setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

      const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
      const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
      const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

      if (htmlContent)
        setHTMLCode(htmlContent);

      if (cssContent)
        setCSSCode(cssContent);

      if (javascriptContent)
        setJavaScriptCode(javascriptContent);
    }

    setPageLinksHaveBeenUpdated(false);
  }, [pageLinksHaveBeenUpdated])

  useEffect(() => {
    if (pageFormsHaveBeenUpdated && pageForms.length > 0) {
      if (!selected_page) return;
      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      // I need to access the state, and save in the dom
      // Do my changes,

      console.log('The pageForms I\'m about to save: ', pageForms);

      if (selected_page) updateForms(selected_page.id, pageForms);

      pageForms.forEach((form: any) => {
        const formTag = viewport.querySelector(`#${form.dom_id}`);
        if (formTag) {
          formTag.setAttribute('action', form.action);
          formTag.setAttribute('method', 'post');
          formTag.removeAttribute('onsubmit');

          // formTag.setAttribute('target', 'submission_target_frame');
          // formTag.setAttribute('target', '_blank');
        }
      })

      // Grab content
      const viewportContent = viewport.documentElement.outerHTML;

      // Set undo history
      setContentUndoHistory((prev) => {
        let modifiedPrev = prev;
        if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

        return [
          ...modifiedPrev,
          {
            timestamp: new Date().toLocaleString(),
            label: 'Edit forms',
            content: viewportContent
          }
        ]
      });

      setConversationHistoryUndoHistory((prev) => {
        let modifiedPrev = prev;
        if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
        return modifiedPrev;
      })

      setDidIUndo(false);
      updatePageContent(selected_page.id, viewportContent);
      setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

      const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
      const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
      const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

      if (htmlContent)
        setHTMLCode(htmlContent);

      if (cssContent)
        setCSSCode(cssContent);

      if (javascriptContent)
        setJavaScriptCode(javascriptContent);
    }

    setPageFormsHaveBeenUpdated(false);
  }, [pageFormsHaveBeenUpdated, pageForms])

  function getPlaceholderDimensions(url: string) {
    if (url.includes("placehold")) {
      const regex = /placehold.co\/(\d+)x(\d+)/;
      const match = url.match(regex);

      if (match) {
        return {
          width: parseInt(match[1], 10),
          height: parseInt(match[2], 10)
        };
      }
    }

    return null;
  }

  function detectPlaceholderImages() {
    const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
    if (!viewport) return;

    const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');



    const images = viewport.querySelectorAll('img');

    images.forEach((img: any) => {
      const imageSrc = img.getAttribute("src");

      if (!imageSrc) return;
      if (imageSrc === "#") return;

      const placeholderImageDimensions = getPlaceholderDimensions(imageSrc);

      if (placeholderImageDimensions) {
        const fixedCssBlock = fixCssSelectors(`#${img.getAttribute("id")} { width: 100%; height: auto; max-height: ${placeholderImageDimensions.height}px; max-width: ${placeholderImageDimensions.width}px; object-fit: cover; }`);
        if (styleElement) styleElement.innerHTML = insertStyles(styleElement.innerHTML, fixedCssBlock);

        // img.style.maxHeight = placeholderImageDimensions.height + "px";

      }

    })
  }

  // function detectUnlimitedImages() {
  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;

  //   let sectionsOuterHtml = new Set();
  //   let willTriggerAutofix = false;

  //   const images = viewport.querySelectorAll('img');

  //   images.forEach((img: any) => {
  //     const imageSrc = img.getAttribute("src");

  //     if (!imageSrc) return;
  //     if (imageSrc === "#") return;
  //     if (imageSrc.includes("placehold")) return;

  //     const heightStyle = window.getComputedStyle(img).height;
  //     if (heightStyle === 'auto' || !img.style.height) {


  //       // The current image is one of those images, gotta find its parent, and construct a set, and pass it as context. 
  //       const imageParentSection = findParentSection(img);
  //       if (imageParentSection) {
  //         sectionsOuterHtml.add(imageParentSection.outerHTML);
  //         // Push the outerHTML to the set.
  //       }

  //       willTriggerAutofix = true;
  //       // AAAAA,  it depends on the number of images, I see.
  //     }
  //   });

  //   if (willTriggerAutofix) {
  //     toast({
  //       title: "Layout breaking images detected.",
  //       description: 'We\'ll go ahead and fix them for you.',
  //       variant: 'solid',
  //       status: 'info',
  //       position: 'bottom-left',
  //       duration: 4000,
  //     });
  //   }

  //   return [willTriggerAutofix, sectionsOuterHtml];
  // }

  useEffect(() => {
    if (pageImagesHaveBeenUpdated) {
      if (!selected_page) return;
      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;
  
      pageImages.forEach((image: any) => {
        if (!image.id) {
          console.warn('Image without id:', image);
          return; // Skip if id is missing ( why is it missing converter work )
        }
  
        if (image.type === 'url') {
          const imgTag = viewport.querySelector(`#${image.id}`);
          if (imgTag) {
            imgTag.setAttribute('src', image.src);
          }
        } else if (image.type === 'background') {
          console.log('image.type, image.id, image.url: ', image.type, image.id, image.src);
  
          const elementWithImageBackground:any = viewport.querySelector(`#${image.id}`);
          if (elementWithImageBackground) {
            elementWithImageBackground.style.backgroundImage = `url('${image.src}')`;
          }
        }
      })

      // Grab content
      const viewportContent = viewport.documentElement.outerHTML;

      // Set undo history
      setContentUndoHistory((prev) => {
        let modifiedPrev = prev;
        if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

        return [
          ...modifiedPrev,
          {
            timestamp: new Date().toLocaleString(),
            label: 'Edit images',
            content: viewportContent
          }
        ]
      });

      setConversationHistoryUndoHistory((prev) => {
        let modifiedPrev = prev;
        if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);
        return modifiedPrev;
      })

      setDidIUndo(false);
      updatePageContent(selected_page.id, viewportContent);
      setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

      const htmlContent = viewport.documentElement.querySelector('div#body')?.innerHTML;
      const cssContent = viewport.documentElement.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML;
      const javascriptContent = viewport.documentElement.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML;

      if (htmlContent)
        setHTMLCode(htmlContent);

      if (cssContent)
        setCSSCode(cssContent);

      if (javascriptContent)
        setJavaScriptCode(javascriptContent);

      // Check for changes in images and trigger autofixing
      // Detect image elements with `auto` size.

      // const images = viewport.querySelectorAll('img');

      // let sectionsOuterHtml = new Set();
      // let willTriggerAutofix = false;

      // images.forEach((img: any) => {
      //   const heightStyle = window.getComputedStyle(img).height;
      //   if (heightStyle === 'auto' || !img.style.height) {

      //     // The current image is one of those images, gotta find its parent, and construct a set, and pass it as context. 
      //     const imageParentSection = findParentSection(img);
      //     if (imageParentSection) {
      //       sectionsOuterHtml.add(imageParentSection.outerHTML);
      //       // Push the outerHTML to the set.
      //     }

      //     willTriggerAutofix = true;
      //     // AAAAA,  it depends on the number of images, I see.
      //   }
      // });




      // if (!isEnhanceCoolingDown) {
      //   const isUnlimitedImages: any = detectUnlimitedImages();

      //   if (isUnlimitedImages[0]) {
      //     const context = joinSet(isUnlimitedImages[1]);
      //     setAutofixerContext(context);
      //     setAutofixer("images-unlimited-height");
      //   }
      // }

    }


    setPageImagesHaveBeenUpdated(false);
  }, [pageImagesHaveBeenUpdated])

  useEffect(() => {
    if (!isWideScreen) clearVisualEditor();
    if (!displayVisualEditor) clearVisualEditor();
  }, [displayVisualEditor, isWideScreen])

  // useEffect(() => {
  //   if (isWideScreen) {
  //     setDisplayVisualEditor(true);
  //   } else {
  //     setDisplayVisualEditor(false);
  //   }
  // }, [])

  drawVisualEditor();
  // drawSectionLabels();

  if (displaySectionLabels) {
    drawSectionLabels();
  } else {
    undrawSectionLabels();
  }

  // useEffect(() => {
  defineEventListeners();
  // }, [])

  // useEffect(() => {
  //   if (displaySectionLabels === false) undrawSectionLabels();
  //   // if (displaySectionLabels === true) drawSectionLabels();
  // }, [displaySectionLabels])

  // useEffect(() => {
  //   if (!htmlCode) return;
  //   drawSectionLabels();
  // }, [htmlCode, cssCode])

  const [viewportZoomLevel, setViewportZoomLevel] = useState(1)

  // const handleTranslatePage = async (language: string) => {
  //   const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
  //   if (!viewport) return;

  //   const localizationMap = buildLocalizationMap(viewport);
  //   const translation = await getTranslation(JSON.stringify(localizationMap), language);

  //   try {
  //     const parsedTranslation: any = JSON.parse(translation as string);
  //     console.log(parsedTranslation);

  //     // I could loop through the viewport right now, and insert in place.
  //   } catch {
  //     throw new Error('Failed to parse.');
  //   }


  //   // Create another page and so on. Also, in languages, get mappings to shorthand versions of language? fr and de for instance.

  //   // This gets sent, 
  //   // Gets translated,
  //   // Gets back, parsed,
  //   // Populated, saved, and all that.

  //   // For starters, then think of how to better integrate it I guess.
  //   // Then think of how to do it all at once.
  // }

  useEffect(() => {
    if (wipeIframeContent) {

      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      const body: any = viewport.querySelector('div#body');
      if (body) {
        body.innerHTML = "";
      }

      const style: any = viewport.querySelector('style[data-dev-tag="inject-into"]');
      if (style) {
        style.innerHTML = "";
      }

      const script: any = viewport.querySelector('script[data-dev-tag="inject-into"]');
      if (script) {
        script.innerHTML = "";
      }

      // Remove cdn script tags
      const cdnLibraryScripts = viewport.querySelectorAll('.sifo-lib-cdn');
      cdnLibraryScripts.forEach((cdn: any) => {
        cdn.remove();
      })

      // Remove cdn css stylesheet link tags
      const cssLibraryStylesheets = viewport.querySelectorAll('.sifo-lib-css');
      cssLibraryStylesheets.forEach((css: any) => {
        css.remove();
      })

      resetLocalContext();
      setViewportTree('');
      setGlobalContextOuterHTML('');
      setOutlinerTree([]);

      setPageForms([]);
      setPageLinks([]);
      setPageImages([]);

      const bodyElement = viewport.querySelector('div#body');
      const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');
      const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');

      if (bodyElement) setHTMLCode(bodyElement.innerHTML);
      if (styleElement) setCSSCode(String(styleElement.textContent));
      if (scriptElement) setJavaScriptCode(scriptElement.innerHTML);

      // setHTMLCode("");
      // setCSSCode("");
      // setJavaScriptCode("");

      if (selected_page) {
        const viewportContent = viewport.documentElement.outerHTML;

        setContentUndoHistory((prev) => {
          let modifiedPrev = prev;
          if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

          return [
            ...modifiedPrev,
            {
              timestamp: new Date().toLocaleString(),
              label: 'Start from scratch',
              content: viewportContent
            }
          ]
        });

        setDidIUndo(false);
        updatePageContent(selected_page.id, viewportContent);
        setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

      }

      undrawSectionLabels();

      setWipeIframeContent(false);
    }


  }, [wipeIframeContent])

  useEffect(() => {
    if (outlinerDeleteElementId) {
      const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
      if (!viewport) return;

      const elementToDelete = viewport.querySelector(`#${outlinerDeleteElementId}`);
      if (elementToDelete) {
        elementToDelete.remove();

        const outlinerTree = buildOutlinerTree(viewport);
        setOutlinerTree(outlinerTree);

        const pageFormTags: any = viewport.querySelectorAll('form');

        if (pageFormTags.length > 0 && selected_page && user) {
          const pageForms: any = constructPageFormsArray(pageFormTags, selected_page.id, user.email);
          setPageForms(pageForms);
          updateForms(selected_page.id, pageForms);
        }

        const pageAmchorTags: any = viewport.querySelectorAll('a');
        if (pageAmchorTags.length > 0) {
          const pageLinks: any = constructPageLinksArray(pageAmchorTags);
          setPageLinks(pageLinks);
          if (selected_page) updateLinks(selected_page.id, pageLinks);
        }

        const allElements: any = viewport.querySelectorAll('*');

        const imageElements: any = Array.from(allElements).filter((element: any) => {
          if (element.tagName === 'IMG' || hasBackgroundImage(element)) {
            return true;
          }
        })

        if (imageElements.length > 0) {
          const pageImages: any = constructPageImagesArray(imageElements);
          setPageImages(pageImages);
          if (selected_page) updateImages(selected_page.id, pageImages);
        }

        if (selected_page) {
          const viewportContent = viewport.documentElement.outerHTML;

          setContentUndoHistory((prev) => {
            let modifiedPrev = prev;
            if (didIUndo) modifiedPrev = prev.filter((step, index) => index <= undoHistoryStepIndex);

            return [
              ...modifiedPrev,
              {
                timestamp: new Date().toLocaleString(),
                label: 'Delete element',
                content: viewportContent
              }
            ]
          });

          setDidIUndo(false);
          updatePageContent(selected_page.id, viewportContent);
          setUndoHistoryStepIndex((prevIndex) => prevIndex + 1);

        }

        setOutlinerDeleteElementId(null);
      }
    }

  }, [outlinerDeleteElementId])

  // useEffect(() => {
  //   drawSectionLabels();
  // }, [mobileView])

  function defineEventListeners() {
    // if (!displaySectionLabels) return;

    const viewportWindow: any = iframeReference.current?.contentWindow;
    if (!viewportWindow) return;

    if (displaySectionLabels) {
      viewportWindow.onresize = drawSectionLabels;
    } else {
      viewportWindow.onresize = null;
    }
    // viewport.onscroll = drawSectionLabels();
  }

  // useEffect(() => {
  // }, [])

  return (
    <Flex flex={1} gap={2}>
      <Flex justifyContent='center' flex={1} h='full' css={{ "::-webkit-scrollbar": { display: "none" }, overflow: 'hidden' }}>
        <iframe
          id='sifo-viewport-iframe'
          style={{ height: '100%', width: mobileView ? '24rem' : '100%', borderRadius: '3px', backgroundColor: 'white', transition: 'all 0.2s ease-in-out', transform: `scale(${viewportZoomLevel})` }}
          title="Viewport"
          allowFullScreen
          ref={iframeReference}
        />
      </Flex>
      {isWideScreen && (
        <Flex flexDir='column' gap={2}>
          <Tooltip gutter={11} placement='left' label='Apply: Confirm and save manual changes.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant={hasBeenManuallyEdited ? 'solid' : 'subtle'}
              colorScheme='purple'
              aria-label='Apply changes'
              icon={<GiConfirmed />}
              onClick={confirmManualEdits}
              isDisabled={!hasBeenManuallyEdited}
            />
          </Tooltip>

          {
            /* 
                      {isDirectEditEnabled ? (
                        <Tooltip gutter={11} placement='left' label='Direct Edit: Directly edit text on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                          <IconButton
                            size="xs"
                            variant='outline'
                            colorScheme='purple'
                            aria-label='Disable Direct Edit'
                            icon={<IoText />}
                            onClick={disableDirectEdit}
                          />
                        </Tooltip>
                      ) : (
            
                        <Tooltip gutter={11} placement='left' label='Direct Edit: Directly edit any text on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                          <IconButton
                            size="xs"
                            variant='subtle'
                            colorScheme='purple'
                            aria-label='Enable Direct Dditing'
                            icon={<IoText />}
                            onClick={enableDirectEdit}
                          />
                        </Tooltip>
                      )}
            */
          }


          {isDirectResizeEnabled ? (
            <Tooltip gutter={11} placement='left' label='Direct Resize: Directly resize elements on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
              <IconButton
                size="xs"
                variant='outline'
                colorScheme='purple'
                aria-label='Disable Direct Resize'
                icon={<GiResize />}
                onClick={disableDirectResize}
              />
            </Tooltip>
          ) : (

            <Tooltip gutter={11} placement='left' label='Direct Resize: Directly resize elements on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
              <IconButton
                size="xs"
                variant='subtle'
                colorScheme='purple'
                aria-label='Enable Direct Resize'
                icon={<GiResize />}
                onClick={enableDirectResize}
              />
            </Tooltip>
          )}


          {isDirectMoveEnabled ? (
            <Tooltip gutter={11} placement='left' label='Direct Move: Directly move elements on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
              <IconButton
                size="xs"
                variant='outline'
                colorScheme='purple'
                aria-label='Disable Move & Resize Mode'
                icon={<IoMdMove />}
                onClick={disableDirectMove}
              />
            </Tooltip>
          ) : (

            <Tooltip gutter={11} placement='left' label='Direct Move: Directly move elements on the page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
              <IconButton
                size="xs"
                variant='subtle'
                colorScheme='purple'
                aria-label='Enable Move & Resize Mode'
                icon={<IoMdMove />}
                onClick={enableDirectMove}
              />
            </Tooltip>
          )}

          <Tooltip gutter={11} placement='left' label='Desktop: Switch to wide screen view.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Desktop View'
              icon={<FaDesktop />}
              onClick={() => setMobileView(false)}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='left' label='Mobile: Switch to responsive view.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Mobile View'
              icon={<FaMobileAlt />}
              onClick={() => setMobileView(true)}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='left' label='Zoom in: Make the viewport bigger.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Zoom in'
              icon={<SlMagnifierAdd />}
              onClick={() => setViewportZoomLevel(viewportZoomLevel + 0.2)}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='left' label='Zoom out: Make the viewport smaller.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Zoom out'
              icon={<SlMagnifierRemove />}
              onClick={() => setViewportZoomLevel(viewportZoomLevel - 0.2)}
            />
          </Tooltip>

          <Tooltip gutter={11} placement='left' label='Reset zoom: Go back to default zoom level.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Reset zoom'
              icon={<SlMagnifier />}
              onClick={() => setViewportZoomLevel(1)}
              isDisabled={viewportZoomLevel === 1}
            />
          </Tooltip>

          {/* <Tooltip gutter={11} placement='left' label='Translate: Translate page.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              size="xs"
              variant='ghost'
              aria-label='Translate'
              icon={<MdGTranslate  />}
              onClick={() => handleTranslatePage('French')}
            />
          </Tooltip> */}
        </Flex>
      )}
    </Flex>
  );
};
