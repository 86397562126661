// deno-lint-ignore-file no-explicit-any

import { EMPTY_STRING } from "./defs";
import { compareScope, normalizeLocationProperties, createIdentifyingProperty } from "./utils";

import { parse as generateEsTree } from "acorn-loose";
import { generate as writeCode } from "astring";

/*
import { flatten as flattenObject, unflatten as restoreFlattenedObject } from 'npm:flat';
import hash from 'npm:object-hash';
import { v4 as uuid } from 'npm:uuid';
*/

export default function LogicEngine(existingCode: string, incomingCode: string) {
    let source: string = EMPTY_STRING;

    const existingAst = generateEsTree(existingCode, { ecmaVersion: 2020 });
    const incomingAst = generateEsTree(incomingCode, { ecmaVersion: 2020 });

    const normalizedLocationExistingAst = normalizeLocationProperties(existingAst);
    const normalizedLocationIncomingAst = normalizeLocationProperties(incomingAst);

    const comparableExistingAst = createIdentifyingProperty(normalizedLocationExistingAst);
    const comparableIncomingAst = createIdentifyingProperty(normalizedLocationIncomingAst);

    // Compare global scope, to begin with.
    const mergedAstBodies = compareScope(comparableExistingAst.body, comparableIncomingAst.body);

    // Cleanup submit event listeners code.
    const noSubmitMergedAstBodies = removeSubmitEventListeners(mergedAstBodies);

    /*
    Then, we'll need to find other scopes, which are basically all block statements, and compare their bodies,
    as they are basically arrays of statements, just like what I am doing right now.
    and, that needs to be done recursively, as block statements, can also hold other block statements.
    and that's where or when I'll need a proper parent-child relationship support.
    estree-toolkit.netlify.app/nodepath might come in handy.
    */

    noSubmitMergedAstBodies.forEach((node: any, index: number) => {
        source += writeCode(node);
        source += noSubmitMergedAstBodies.length - 1 === index ? '' : '\n\n';
    })

    return source;
}

function removeSubmitEventListeners(estree: any): any {
  if (Array.isArray(estree)) {
    return estree.filter(node => !isSubmitEventListener(node))
                 .map(removeSubmitEventListeners);
  }

  if (estree && typeof estree === 'object') {
    for (let key in estree) {
      if (estree.hasOwnProperty(key)) {
        estree[key] = removeSubmitEventListeners(estree[key]);
      }
    }
  }

  return estree;
}

function isSubmitEventListener(node: any) {
  if (node.type === 'ExpressionStatement' &&
      node.expression.type === 'CallExpression' &&
      node.expression.callee.type === 'MemberExpression' &&
      node.expression.callee.property.name === 'addEventListener' &&
      node.expression.arguments[0] &&
      node.expression.arguments[0].value === 'submit') {
    return true;
  }

  if (node.type === 'ExpressionStatement' &&
      node.expression.type === 'AssignmentExpression' &&
      node.expression.left.type === 'MemberExpression' &&
      node.expression.left.property.name === 'onsubmit') {
    return true;
  }

  return false;
}

// function removeSubmitEventListeners(estree: any) {
//     return estree.filter((node: any) => {
//       if (node.type === 'ExpressionStatement' &&
//           node.expression.type === 'CallExpression' &&
//           node.expression.callee.type === 'MemberExpression' &&
//           node.expression.callee.property.name === 'addEventListener' &&
//           node.expression.arguments[0].value === 'submit') {
//         return false;
//       }
  
//       if (node.type === 'ExpressionStatement' &&
//           node.expression.type === 'AssignmentExpression' &&
//           node.expression.left.type === 'MemberExpression' &&
//           node.expression.left.property.name === 'onsubmit') {
//         return false;
//       }
  
//       return true;
//     }).map((node: any) => {
//       if (node.type === 'BlockStatement' && node.body) {
//         node.body = removeSubmitEventListeners(node.body);
//       }
//       return node;
//     });
//   }