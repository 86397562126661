import React, { useState, useEffect } from 'react';
import { Flex, Text, IconButton, Button, Image, Box, Heading, Badge, useColorModeValue } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil';
import { AuthenticatedLayout } from '../layouts';
import { useCloudFrontActions } from '../hooks';
import { currentUserState, selectedProjectSelector, UserAllottedCredits } from '../state';
import { SoftCard } from '../components/generic/SoftCard';
import { DangerButton } from '../components/generic/DangerButton';
import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Stat, StatLabel, StatNumber, StatHelpText, StatArrow, StatGroup } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md';

export const SitesPage: React.FC = () => {

    const noSitesFoundImageSource = useColorModeValue('/illustrations/no-sites-found.png', '/illustrations/no-sites-found-dark.png');

    const userAllottedCredits = useRecoilValue(UserAllottedCredits);
    const currentUser = useRecoilValue(currentUserState);
    const selectedProject = useRecoilValue(selectedProjectSelector);
    const [userDeployedSites, setUserDeployedSites] = useState([]);
    const { fetchSites, deleteSite } = useCloudFrontActions();

    const onDeleteSite = (siteId: string) => {
        if (selectedProject) {
            deleteSite(siteId, selectedProject?.id);
            setUserDeployedSites((prev: any) => prev.filter((s: any) => s.id !== siteId));
        }
    }

    useEffect(() => {
        const getSites = async () => {
            if (!currentUser) return;

            const sites: any = await fetchSites(currentUser.id);
            if (sites) setUserDeployedSites(sites);
            // console.log('User Deployed Sites: ', sites);
        }

        getSites();
    }, [currentUser, fetchSites])

    return (
        <AuthenticatedLayout>
            <Flex flex={1} flexDirection={'column'} gap={3} w='full'>
                <SoftCard h='full'>
                    <Flex p={4}  justifyContent='space-between' gap={4}>
                        <Heading as="h3" size="md">
                            Sites
                        </Heading>
                        <Box>
                            <Stat>
                                <StatLabel>Sites Left</StatLabel>
                                <StatHelpText>{userAllottedCredits?.sites_allowed}</StatHelpText>
                            </Stat>
                        </Box>
                    </Flex>
                    <Flex flexDir='column' h='full' gap={2}>
                        {userDeployedSites && userDeployedSites.length > 0 ? (
                            <>
                                <TableContainer>
                                    <Table variant='simple'>
                                        <TableCaption>Published Sites</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Name</Th>
                                                <Th>URL</Th>
                                                <Th>Timestamp</Th>
                                                <Th>Identifier</Th>
                                                <Th>Delete</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>

                                            {

                                                userDeployedSites.map((site: any, index: number) => (
                                                    <Tr key={index}>
                                                        <Td>{site.name}</Td>
                                                        <Td>
                                                            <Link href={site.url} isExternal>
                                                                {site.url} <ExternalLinkIcon mx='2px' />
                                                            </Link>
                                                        </Td>
                                                        <Td>{new Date(site.created_at).toLocaleString()}</Td>
                                                        <Td>{site.id}</Td>
                                                        <Td>

                                                            <IconButton
                                                                size="xs"
                                                                variant='solid'
                                                                colorScheme='purple'
                                                                aria-label='Delete'
                                                                icon={<MdDelete />}
                                                                onClick={() => onDeleteSite(site.id)}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))
                                            }

                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (
                            <Flex flexGrow={1} justifyContent="center" alignItems="center">
                                <Flex flexDir="column" gap={10} alignItems="center">
                                    <Image src={noSitesFoundImageSource} width='500px' />
                                    <Text textAlign="center">You do not have any published sites at the moment.</Text>
                                </Flex>
                            </Flex>
                        )
                        }
                    </Flex>
                </SoftCard>
            </Flex>
        </AuthenticatedLayout>
    );
};