import { atom } from 'recoil';

export const ChatBubbles = atom({
    key: 'chat-bubbles',
    default: [
        {
            date: Date.now(),
            type: 'message',
            source: 'assistant',
            content: "Hi! SIFO here. Let's create cool stuff together!"
        }
    ]
});