import AWS from 'aws-sdk';
import React, { useState, useEffect, useRef } from 'react';
import {
  Box, SimpleGrid,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Badge,
  Tooltip, Spinner, Select, Image, Button, useDisclosure, Flex, Input, IconButton, Heading, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton
} from '@chakra-ui/react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { PageImages, LocalContext, LocalContextId, IsGeneratingImageSpinner, IsTranscribingSpinner, selectedPageSelector, PageImagesHaveBeenUpdated, UserPreferences, IsUploadingSpinner } from '../../state';
import { SoftCard } from '../../components/generic/SoftCard';
import { FaRegImage } from "react-icons/fa";
import { MdChevronRight, MdSave } from "react-icons/md";
import { IoSparkles } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useOpenaiActions, usePageActions } from '../../hooks';
import { TbMicrophone, TbMicrophoneOff } from "react-icons/tb";
import '../../lib/anim.css';
import { hasBackgroundImage } from './links-tools';
import { imageGeneration } from '../../lib/feature-availability';
import { currentUserState } from '../../state';
import { useNavigate } from 'react-router-dom';

export const Images: React.FC<any> = () => {

  const navigate = useNavigate();

  const user = useRecoilValue(currentUserState);
  const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure();
  const cancelRef = useRef<any | null>();

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const promptInputRef = useRef<HTMLInputElement>(null);
  const keywordsInputRef = useRef<HTMLInputElement>(null);

  const setIsUploadingSpinner = useSetRecoilState(IsUploadingSpinner);
  const [isGeneratingImageSpinner, setIsGeneratingImageSpinner] = useRecoilState(IsGeneratingImageSpinner);
  const [pageImages, setPageImages] = useRecoilState(PageImages);
  const setIsTranscribingSpinner = useSetRecoilState(IsTranscribingSpinner);

  const [recording, setRecording] = useState(false);
  const [inputValues, setInputValues] = useState(pageImages);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<any>('');
  const [promptTranscription, setPromptTranscription] = useState('');
  const [imageBeingEditedSrc, setImageBeingEditedSrc] = useState<any | null>('');
  const [imageBeingEditedIndex, setImageBeingEditedIndex] = useState<any | null>(0);

  const { isOpen: isGenerateOpen, onOpen: onGenerateOpen, onClose: onGenerateClose } = useDisclosure()
  const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure()

  const localContextId = useRecoilValue(LocalContextId);
  const localContext = useRecoilValue<any>(LocalContext);

  const userPreferences = useRecoilValue(UserPreferences);

  const { getTranscription, getImageGeneration, fetchUnsplash, uploadToS3 } = useOpenaiActions();
  const { updateImages } = usePageActions();

  const selectedPage = useRecoilValue(selectedPageSelector);

  const setPageImagesHaveBeenUpdated = useSetRecoilState(PageImagesHaveBeenUpdated);

  useEffect(() => {
    if (pageImages.length > 0) setInputValues(pageImages);
  }, [pageImages]);

  const handleInputChange = (event: any, index: number, property: string, is_radio: boolean = false) => {

    // Shit happens here, I believe,
    // or, should I do a comparative thing upon updating, for a more controlled approach?


    setInputValues((previousValue: any) =>
      previousValue.map((value: any, i: number) =>
        i === index
          ? { ...value, [property]: is_radio ? event : event.target.value }
          : value
      )
    );
  };

  const handleSaveChanges = () => {
    setPageImages(inputValues);
    if (selectedPage) updateImages(selectedPage.id, inputValues);
    setPageImagesHaveBeenUpdated(true);

    console.log('inputValues (after saving images...): ', inputValues);
  }

  const handleEnterKeyPress = (event: any) => {
    if (event.key === 'Enter')
      handleSaveChanges();
  }

  const [imageSizeInputValue, setImageSizeInputValue] = useState('1024x1024');

  const handleGenerateImage = async (transcription?: string) => {
    const promptValue: any = transcription || promptInputRef?.current?.value;

    setPromptTranscription(promptValue);

    setIsGeneratingImageSpinner(true);
    const imageUrl: any = await getImageGeneration(promptValue, { model: userPreferences.image_model, size: imageSizeInputValue || '1024x1024' })
    setIsGeneratingImageSpinner(false);
    if (imageUrl) {
      setGeneratedImageUrl(imageUrl);
      if (promptInputRef.current) promptInputRef.current.value = '';
    }

  }

  const handleEnterKeyPressGenerateImage = (event: any) => {
    if (event.key === 'Enter') {
      handleGenerateImage();
    }
  }

  const handleConfirmGeneratedImage = async () => {
    const formData = new FormData();
    formData.append('url', generatedImageUrl);

    setIsUploadingSpinner(true);
    const newurl: any = await uploadToS3(formData)
    setIsUploadingSpinner(false);
    handleInputChange({ target: { value: newurl } }, imageBeingEditedIndex, 'src');
    onGenerateClose();
  }

  const handleOpenGenerateModal = (index: number, src: string) => {
    if (imageGeneration.includes(user?.plan_id as string)) {
      setGeneratedImageUrl('');

      setImageBeingEditedIndex(index);
      setImageBeingEditedSrc(src);
      onGenerateOpen();
    } else {
      onUpgradeOpen();
    }
  }

  const startRecording = async () => {
    try {

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks: any[] = [];
      mediaRecorder.addEventListener("dataavailable", (event) => {
        chunks.push(event.data);
      });

      mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(chunks, { type: "audio/mp4" });
        setRecording(false);
        runTranscription(audioBlob);
      });

      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
      if (isIOS) mediaRecorder.start(1000);
      else mediaRecorder.start();

      setRecording(true);
    } catch (error) {
      console.error(error);
    }
  };

  const stopRecording = async () => {
    mediaRecorderRef.current?.stop();
  };

  const runTranscription = async (audioBlob: Blob) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.mp4");
    setIsTranscribingSpinner(true);
    const transcription: any = await getTranscription(formData);
    handleGenerateImage(transcription.text);
    setIsTranscribingSpinner(false);
  };

  const [lastSearchTerm, setLastSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const handleConfirmSelectedImage = () => {
    if (selectedImage) {
      handleInputChange({ target: { value: selectedImage['urls']['full'] } }, imageBeingEditedIndex, 'src');
      onSearchClose();

      setSearchTerm('');
      setImages([]);
    }
  }

  const handleEnterKeyPressFindImage = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }

  useEffect(() => {
    if (searchTerm) {
      handleSearch();
    }
  }, [page]);

  const handleSelectImage = (image: any) => {
    setSelectedImage(image);
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleOpenSearchModal = (index: number, src: string) => {
    setImageBeingEditedIndex(index);
    setImageBeingEditedSrc(src);
    onSearchOpen();
  }

  const handleSearch = async () => {
    setLastSearchTerm(searchTerm);
    try {
      const data: any = await fetchUnsplash(searchTerm, page);
      setImages((prevImages) => (page !== 1 && searchTerm === lastSearchTerm ? [...prevImages, ...data.results] : data.results));
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  // const ImageGrid: React.FC<any> = ({ images, onSelectImage }) => {
  //   return (
  //     <SimpleGrid gridTemplateColumns='repeat(auto-fill, minmax(180px, auto))' gridAutoRows={'180px'} gap={2}>
  //       {images.map((image: any, index: any) => (
  //         <Box key={index} style={{ transform: selectedImage && selectedImage.id === image.id ? 'scale(0.95)' : 'scale(1)' }}>
  //           <Image
  //             key={image.id}
  //             src={image.urls.thumb}
  //             alt={image.alt_description}
  //             onClick={() => onSelectImage(image)}
  //             h='full'
  //             w='full'
  //             objectFit="cover"
  //           />
  //         </Box>
  //       ))}
  //     </SimpleGrid>
  //   );
  // };

  // v1
  // const ImageGrid: React.FC<any> = ({ images, onSelectImage }) => {
  //   return (
  //     <SimpleGrid gridTemplateColumns='repeat(auto-fill, minmax(180px, auto))' gridAutoRows={'180px'} gap={2}>
  //       {images.map((image: any, index: any) => (
  //         <Box
  //           key={index}
  //           position="relative"
  //           overflow="hidden"
  //           style={{ transform: selectedImage && selectedImage.id === image.id ? 'scale(0.95)' : 'scale(1)' }}
  //         >
  //           <Image
  //             src={image.urls.thumb}
  //             alt={image.alt_description}
  //             onClick={() => onSelectImage(image)}
  //             h='full'
  //             w='full'
  //             objectFit="cover"
  //             cursor="pointer"
  //           />
  //           <Box
  //             position="absolute"
  //             bottom="0"
  //             left="0"
  //             width="100%"
  //             bg="rgba(0, 0, 0, 0.6)"
  //             color="white"
  //             opacity="0"
  //             _hover={{ opacity: "1" }}
  //             transition="opacity 0.3s"
  //             p={2}
  //             textAlign="center"
  //           >
  //             <Text fontSize="sm">
  //               <a href={image.user.links.html} target="_blank" rel="noopener noreferrer">
  //                 {image.user.name}
  //               </a>
  //             </Text>
  //           </Box>
  //         </Box>
  //       ))}
  //     </SimpleGrid>
  //   );
  // };

  // v2
  const ImageGrid: React.FC<any> = ({ images, onSelectImage }) => {
    return (
      <SimpleGrid gridTemplateColumns="repeat(auto-fill, minmax(180px, auto))" gridAutoRows="180px" gap={2}>
        {images.map((image: any, index: any) => (
          <Box
            key={index}
            position="relative"
            overflow="hidden"
            style={{ transform: selectedImage && selectedImage.id === image.id ? 'scale(0.95)' : 'scale(1)' }}
            _hover={{ '.overlay': { opacity: 1 } }} // Hover effect on the container
          >
            <Image
              src={image.urls.thumb}
              alt={image.alt_description}
              onClick={() => onSelectImage(image)}
              h="full"
              w="full"
              objectFit="cover"
              cursor="pointer"
            />
            <Box
              className="overlay"
              position="absolute"
              bottom="0"
              left="0"
              width="100%"
              bg="rgba(0, 0, 0, 0.6)"
              color="white"
              opacity="0" // Initially hidden
              transition="opacity 0.3s"
              p={2}
              textAlign="center"
            >
              <Text fontSize="sm">
                <a href={image.user.links.html} target="_blank" rel="noopener noreferrer">
                  {image.user.name}
                </a>
              </Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  const [selectedImageFile, setSelectedImageFile] = useState<any>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const fileInputRef = useRef<any>(null);

  const handleFileChange = (event: any) => {
    setSelectedImageFile(event.target.files[0]);
  };

  const handleOpenUploadDialog = (index?: number, src?: string) => {
    if (index && src) {
      setImageBeingEditedIndex(index);
      setImageBeingEditedSrc(src);
    }

    if (fileInputRef) fileInputRef.current.click()
  }

  const handleUploadImage = async () => {
    const formData = new FormData();
    formData.append("file", selectedImageFile);

    setIsUploadingSpinner(true);
    const newurl: any = await uploadToS3(formData)
    setIsUploadingSpinner(false);

    setUploadedImageUrl(newurl);
  }

  useEffect(() => {
    if (selectedImageFile) {
      handleUploadImage();
    }

  }, [selectedImageFile])

  useEffect(() => {
    if (uploadedImageUrl) {
      handleConfirmUploadedImage();
    }

  }, [uploadedImageUrl])

  const handleConfirmUploadedImage = () => {
    if (uploadedImageUrl) {
      handleInputChange({ target: { value: uploadedImageUrl } }, imageBeingEditedIndex, 'src');
    }
  }

  useEffect(() => {
    if (inputValues && (generatedImageUrl || selectedImage || uploadedImageUrl)) {
      handleSaveChanges();
    }
  }, [inputValues, generatedImageUrl, selectedImage, uploadedImageUrl])

  return (
    <SoftCard h='100%' minH='0' flex={2}>
      <Flex className='zoom' flexDir='column' justify='space-between' gap={4} h='full'>

        <Flex alignItems='end' gap={2}>
          <FaRegImage />
          <Heading as='h5' size='sm'>
            Images
          </Heading>

        </Flex>

        {/* {selectedPage && (
          <Text>Current page: {selectedPage.title + "," + selectedPage.path}</Text>
        )} */}

        <Flex flex={1} flexDir='column' gap={4} overflowY='auto' css={{ "::-webkit-scrollbar": { display: "none" } }}>
          {pageImages.length > 0 ? (
            <>
              {
                (inputValues.length > 0) && inputValues.map((image: any, index: number) => {
                  if (localContext !== null && (localContext.tagName === 'IMG' || hasBackgroundImage(localContext))) {
                    if (image.id === localContextId) {
                      console.log(image.id);

                      return (
                        <Flex key={index} gap={3} flexDir='column' alignItems='flex-start'>
                          <Breadcrumb fontSize='xs' separator={<ChevronRightIcon color='gray.500' />}>
                            {image.parent_id && (
                              <BreadcrumbItem>
                                <BreadcrumbLink>{image.parent_id}</BreadcrumbLink>
                              </BreadcrumbItem>
                            )}

                            <BreadcrumbItem isCurrentPage>
                              <BreadcrumbLink>{image.id}</BreadcrumbLink>
                            </BreadcrumbItem>
                          </Breadcrumb>

                          <Flex gap={2} flexDir='column'>
                            <Input size={'sm'} value={image.src} onChange={(e) => handleInputChange(e, index, 'src')} onKeyPress={handleEnterKeyPress} />
                            <Flex gap={2}>

                              <Flex gap={2} flexDir='column'>
                                <input ref={fileInputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                                <Tooltip gutter={11} placement='auto' label='Upload: Grab an image from your device.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                                  <IconButton
                                    onClick={() => handleOpenUploadDialog(index, image.src)}
                                    size="xs"
                                    variant="solid"
                                    colorScheme='purple'
                                    aria-label="Upload"
                                    icon={<FaUpload />}
                                  />
                                </Tooltip>

                                <Tooltip gutter={11} placement='auto' label='Generate: Use AI to create an image.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                                  <IconButton
                                    onClick={() => handleOpenGenerateModal(index, image.src)}
                                    size="xs"
                                    variant="solid"
                                    colorScheme='purple'
                                    aria-label="Generate"
                                    icon={<IoSparkles />}
                                  />
                                </Tooltip>

                                <Tooltip gutter={11} placement='auto' label='Search: Find images on the web.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                                  <IconButton
                                    onClick={() => handleOpenSearchModal(index, image.src)}
                                    size="xs"
                                    variant="solid"
                                    colorScheme='purple'
                                    aria-label="Search"
                                    icon={<FaSearch />}
                                  />
                                </Tooltip>
                              </Flex>

                              <Box w='full'>
                                <Image objectFit='cover' rounded='sm' src={image.src} alt="Image Preview" />
                              </Box>

                            </Flex>
                          </Flex>
                        </Flex>
                      )

                    }
                  } else {
                    console.log(image.id);

                    return (
                      <Flex key={index} gap={3} flexDir='column' alignItems='flex-start'>
                        <Breadcrumb fontSize='xs' separator={<ChevronRightIcon color='gray.500' />}>
                          {image.parent_id && (
                            <BreadcrumbItem>
                              <BreadcrumbLink>{image.parent_id}</BreadcrumbLink>
                            </BreadcrumbItem>
                          )}

                          <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{image.id}</BreadcrumbLink>
                          </BreadcrumbItem>
                        </Breadcrumb>

                        <Flex gap={2} flexDir='column'>
                          <Input size={'sm'} value={image.src} onChange={(e) => handleInputChange(e, index, 'src')} onKeyPress={handleEnterKeyPress} />
                          <Flex gap={2}>

                            <Flex gap={2} flexDir='column'>
                              <input ref={fileInputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                              <Tooltip gutter={11} placement='auto' label='Upload: Grab an image from your device.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                                <IconButton
                                  onClick={() => handleOpenUploadDialog(index, image.src)}
                                  size="xs"
                                  variant="solid"
                                  colorScheme='purple'
                                  aria-label="Upload"
                                  icon={<FaUpload />}
                                />
                              </Tooltip>

                              <Tooltip gutter={11} placement='auto' label='Generate: Use AI to create an image.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                                <IconButton
                                  onClick={() => handleOpenGenerateModal(index, image.src)}
                                  size="xs"
                                  variant="solid"
                                  colorScheme='purple'
                                  aria-label="Generate"
                                  icon={<IoSparkles />}
                                />
                              </Tooltip>

                              <Tooltip gutter={11} placement='auto' label='Search: Find images on the web.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                                <IconButton
                                  onClick={() => handleOpenSearchModal(index, image.src)}
                                  size="xs"
                                  variant="solid"
                                  colorScheme='purple'
                                  aria-label="Search"
                                  icon={<FaSearch />}
                                />
                              </Tooltip>
                            </Flex>

                            <Box w='full'>
                              <Image objectFit='cover' rounded='sm' src={image.src} alt="Image Preview" />
                            </Box>

                          </Flex>
                        </Flex>
                      </Flex>
                    )
                  }
                })
              }

              <Modal size={{ base: "full", md: "sm" }} isOpen={isGenerateOpen} onClose={onGenerateClose} initialFocusRef={promptInputRef} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Generate image</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Flex flexDir='column' gap={4}>
                      <Flex gap={2} justifyContent='space-between'>
                        <Flex flexDir='column' gap={2} flex={1}>
                          <Input
                            ref={promptInputRef}
                            placeholder="e.g. A photo of a pitbull wearing a blue bow tie."
                            size='sm'
                            onKeyPress={handleEnterKeyPressGenerateImage}
                            flex={2}
                          />
                          <Select value={imageSizeInputValue} onChange={(e) => setImageSizeInputValue(e.target.value)} variant='filled' size={'sm'} flex={1}>
                            {userPreferences.image_model === 'image-1' ? (
                              <>
                                <option value='1024x1024'>1024x1024</option>
                                <option value='512x512'>512x512</option>
                                <option value='256x256'>256x256</option>
                              </>
                            ) : (
                              <>
                                <option value='1024x1024'>1024x1024</option>
                                <option value='1024x1792'>1024x1792</option>
                                <option value='1792x1024'>1792x1024</option>
                              </>
                            )}
                          </Select>
                        </Flex>

                        <Flex flexDir='column' gap={2}>
                          <IconButton
                            size="sm"
                            colorScheme='purple'
                            variant='solid'
                            aria-label='Generate'
                            icon={<IoSparkles />}
                            onClick={() => handleGenerateImage()}
                          />
                          <Tooltip gutter={11} placement='bottom-end' label='Talk: Use your voice.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                            {recording ? (
                              <IconButton
                                size="sm"
                                colorScheme='red'
                                aria-label='Stop recording'
                                icon={<TbMicrophoneOff />}
                                onClick={stopRecording}
                              />
                            ) : (
                              <IconButton
                                colorScheme='pink'
                                size="sm"
                                aria-label='Start recording'
                                icon={<TbMicrophone />}
                                onClick={startRecording}
                              />
                            )}
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Flex pt={2} justifyContent='center' flexDir='column' gap={4} alignItems='center' h='300px' w='full'>
                        {isGeneratingImageSpinner ? (
                          <>
                            <Spinner size='lg' colorScheme='purple' />
                            <Text>{promptTranscription}</Text>
                          </>
                        ) : (
                          <Image h='full' w='full' objectFit='contain' rounded='sm' src={generatedImageUrl || imageBeingEditedSrc} alt="Generated image" />
                        )}
                      </Flex>
                    </Flex>
                  </ModalBody>

                  <ModalFooter>
                    <Button isDisabled={!generatedImageUrl} size="sm" colorScheme="purple" rightIcon={<MdChevronRight />} onClick={() => handleConfirmGeneratedImage()}>
                      Confirm
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>


              <AlertDialog
                isOpen={isUpgradeOpen}
                leastDestructiveRef={cancelRef}
                onClose={onUpgradeClose}
                isCentered
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                      Upgrade
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      This action is not available in your current plan, you need to upgrade your subscription plan.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button size="sm" ref={cancelRef} onClick={onUpgradeClose}>
                        Cancel
                      </Button>
                      <Button size="sm" colorScheme='purple' onClick={() => navigate('/upgrade')} ml={3}>
                        Upgrade
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>

              <Modal size={{ base: "full", md: "md" }} isOpen={isSearchOpen} onClose={onSearchClose} initialFocusRef={keywordsInputRef} scrollBehavior='inside' isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Search image</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Flex flexDir='column' gap={4}>
                      <Flex gap={4} justifyContent="space-between" alignItems="center">
                        <Text fontSize="md">
                          What are you looking for?
                        </Text>
                        <Badge fontSize="10px" variant="outline" colorScheme="purple">Images by Unsplash</Badge>
                      </Flex>
                      <Flex gap={2}>
                        <Input
                          ref={keywordsInputRef}
                          placeholder="e.g. Robot"
                          value={searchTerm}
                          size='sm'
                          onChange={(e) => setSearchTerm(e.target.value)}
                          onKeyPress={handleEnterKeyPressFindImage}
                          flex={2}
                        />

                        <Tooltip gutter={11} placement='bottom' label='Search: Find images.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
                          <IconButton
                            onClick={handleSearch}
                            size="sm"
                            colorScheme='purple'
                            aria-label="Search"
                            title="Search"
                            icon={<FaSearch />}
                          />
                        </Tooltip>
                      </Flex>

                      {images && images.length > 0 && (
                        <>
                          <ImageGrid images={images} onSelectImage={handleSelectImage} />
                          <Button w='full' rightIcon={<FaCloudDownloadAlt />} size='xs' colorScheme='purple' variant='outline' onClick={handleLoadMore}>Load more</Button>
                        </>
                      )}
                    </Flex>
                  </ModalBody>

                  <ModalFooter>
                    <Button isDisabled={!selectedImage} size="sm" colorScheme="purple" rightIcon={<MdChevronRight />} onClick={() => handleConfirmSelectedImage()}>
                      Confirm
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          ) : (
            <>
              <Flex flexDir='column' justifyContent='center' alignItems='center' p={4}>
                <Text fontSize='sm' >There are no images in the current page.</Text>
              </Flex>
            </>
          )}
        </Flex>

        <Flex justifyContent='flex-end'>
          <Tooltip gutter={11} placement='auto' label='Update: Save and apply changes.' hasArrow isDisabled={!userPreferences.display_tooltips || false}>
            <IconButton
              onClick={handleSaveChanges}
              size="sm"
              colorScheme='purple'
              aria-label="Update"
              title="Update"
              icon={<MdSave />}
            />
          </Tooltip>
        </Flex>

      </Flex>
    </SoftCard>
  );
};