import { atom } from 'recoil';

export const ViewportSourceDocument = atom({
    key: 'viewport-srcdoc',
    default: ''
    // default: defaultSource
});

export const ViewportTree = atom({
    key: 'viewport-tree',
    default: ''
});

export const OutlinerTree = atom({
    key: 'outliner-tree',
    default: []
});

// export const Tree = selector({
//     key: 's-tree',
//     get: ({ get }) => {
//         const viewportSourceDocument: any = get(ViewportSourceDocument);
//         if (viewportSourceDocument) {

//             const loop = (node: any) => {
//                 const element: any = {
//                     tag: node.tagName.toLowerCase(),
//                     children: []
//                 };

//                 const children = node.children;
//                 for (let i = 0; i < children.length; i++) {
//                     element.children.push(loop(children[i]));
//                 }

//                 return element;
//             }

//             const parser = new DOMParser();
//             const parsedHTML = parser.parseFromString(viewportSourceDocument, 'text/html');

//             const rootElement = parsedHTML.querySelector('div#body');

//             const content = [
//                 {
//                     type: "html",
//                     element: loop(rootElement)
//                 }, 
//             ];

//             const styleAndScript: any = [
//                 {
//                     type: "css",
//                     code: parsedHTML.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML
//                 },
//                 {
//                     type: "javascript",
//                     code: parsedHTML.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML
//                 }
//             ];

//             const tree = [
//                 ...content,
//                 ...styleAndScript
//             ]
            
//             return JSON.stringify(tree);
//         }

//         return null;
//     }
// });