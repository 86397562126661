import React, { useState } from 'react';
import { Flex, IconButton, Button, Heading } from '@chakra-ui/react'
import { useRecoilValue, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { LocalContextId, SelectedOutlinerNode, OutlinerTree, LocalContext, LocalContextCSS, OutlinerDeleteElementId } from '../../state';
import { MdAccountTree } from "react-icons/md";
import { MdExpandMore, MdExpandLess, MdDeleteOutline } from "react-icons/md";
import { FaDotCircle } from "react-icons/fa";
import { SoftCard } from '../../components/generic/SoftCard';
import '../../lib/anim.css';

export const Outliner: React.FC<any> = () => {

  const outlinerTree = useRecoilValue(OutlinerTree);
  const localContext = useRecoilValue<any>(LocalContext);
  const localContextId = useRecoilValue(LocalContextId);

  const resetLocalContext = useResetRecoilState(LocalContext);
  const resetLocalContextCSS = useResetRecoilState(LocalContextCSS);
  const [selectedOutlinerNode, setSelectedOutlinerNode] = useRecoilState<any>(SelectedOutlinerNode);
  const resetSelectedOutlinerNode = useResetRecoilState(SelectedOutlinerNode);

  const setOutlinerDeleteElementId = useSetRecoilState<any>(OutlinerDeleteElementId);

  const handleToggleSelectedOutlinerNode = (nodeId: any, nodeTag: any) => {
    if (selectedOutlinerNode === nodeId) {
      resetSelectedOutlinerNode();
      resetLocalContext();
      resetLocalContextCSS();
    } else {
      setSelectedOutlinerNode({nodeId, nodeTag});
    }
  }

  const handleRemoveElement = (nodeId: string) => {
    setOutlinerDeleteElementId(nodeId);
  }

  const OutlinerNode: React.FC<any> = ({ node, level = 0 }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <Flex flexDir='column' gap={2} flex={1}>
        <Flex gap={1} justifyContent='space-between' pl={level}>
          <Button justifyContent='flex-start' flexGrow={1} leftIcon={<FaDotCircle />} onClick={() => handleToggleSelectedOutlinerNode(node.id, node.tag)} size='xs' colorScheme='purple' variant={localContext && localContext.tagName.toLowerCase() === node.tag && localContextId === node.id ? 'solid' : 'ghost'}>
            {node.id || node.tag}
          </Button>
          <IconButton size='xs' aria-label='Expand' colorScheme='purple' variant='ghost' onClick={handleToggle} icon={isExpanded ? <MdExpandLess /> : <MdExpandMore />} isDisabled={node.children.length === 0} />
          <IconButton size='xs' aria-label='Remove' colorScheme='purple' variant='ghost' onClick={() => handleRemoveElement(node.id)} icon={<MdDeleteOutline />} />
        </Flex>
        {isExpanded &&
          node.children.map((child: any, index: number) => (
            <OutlinerNode key={index} node={child} level={level + 1} />
          ))
        }
      </Flex>
    );
  }


  return (
    <SoftCard h='100%' minH='0' flex={2}>
      <Flex className='zoom' flexDir='column' gap={4} h='full'>
        <Flex alignItems='end' gap={2}>
          <MdAccountTree />
          <Heading as='h5' size='sm'>
            Outliner
          </Heading>
        </Flex>
        <Flex flexDir='column' gap={2} overflowY='auto' css={{ "::-webkit-scrollbar": { display: "none" } }}>
          {outlinerTree && outlinerTree.map((child: any, index: number) => (
            <OutlinerNode key={index} node={child} />
          ))}
        </Flex>

      </Flex>
    </SoftCard>
  );
};
