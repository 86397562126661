import { useEffect } from 'react';
// import NoSleep from 'nosleep.js';
import keepAwake from './keep-awake';

// let noSleep = new NoSleep();


const WakeLockComponent = () => {

    useEffect(() => {
        document.addEventListener('click', function enableKeepAwake() {
            document.removeEventListener('click', enableKeepAwake, false);
            keepAwake.enable();
        }, false);
    }, [])

    return (
        <></>
    );
};

export default WakeLockComponent;

// import React, { useEffect } from 'react';

// const WakeLockComponent = () => {
//     let wakeLock: any = null;

//     const requestWakeLock = async () => {
//         try {
//             wakeLock = await (navigator as any).wakeLock.request('screen');
//             // console.log('Wake lock is active.');
//         } catch (err: any) {
//             console.error(`${err.name}, ${err.message}`);
//         }
//     };

//     const releaseWakeLock = async () => {
//         if (wakeLock !== null) {
//             await wakeLock.release();
//             wakeLock = null;
//             // console.log('Wake lock has been released.');
//         }
//     };

//     useEffect(() => {
//         // Request the wake lock when the component mounts
//         requestWakeLock();

//         // Handle visibility change
//         const handleVisibilityChange = async () => {
//             if (document.visibilityState === 'visible') {
//                 await requestWakeLock();
//             } else {
//                 await releaseWakeLock();
//             }
//         };

//         document.addEventListener('visibilitychange', handleVisibilityChange);

//         // Cleanup function to release the wake lock and remove event listener
//         return () => {
//             releaseWakeLock();
//             document.removeEventListener('visibilitychange', handleVisibilityChange);
//         };
//     }, []);

//     return (
//         <></>
//     );
// };

// export default WakeLockComponent;