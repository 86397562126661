import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { Page } from '../types';
import { useApiActions } from './api-actions';
import { pagesState, selectedPageSelector, ViewportSourceDocument, ConversationHistory, ChatBubbles, ViewportTree, PageLinks, PageImages, PageForms, GlobalContextOuterHTML } from '../state';
import { useSIFOActions } from './sifo-actions';

export const usePageActions = () => {
  const { executeApiAction } = useApiActions();
  const { buildContextTree } = useSIFOActions();

  const [pages, setPages] = useRecoilState(pagesState);
  const setSelectedPage = useSetRecoilState(selectedPageSelector);
  const setViewportSourceDocument = useSetRecoilState(ViewportSourceDocument);
  const setConversationHistory = useSetRecoilState(ConversationHistory);
  const setChatBubbles = useSetRecoilState(ChatBubbles);
  const setPageLinks = useSetRecoilState(PageLinks);
  const setPageForms = useSetRecoilState(PageForms);
  const setPageImages = useSetRecoilState(PageImages);
  const setViewportTree = useSetRecoilState(ViewportTree);
  const setGlobalContextOuterHTML = useSetRecoilState(GlobalContextOuterHTML);

  const createPage = async ({
    projectId,
    title,
    path,
    content,
    conversationHistory,
    chat,
    links,
    images,
    forms,
    locale,
    isIndex
  }: { projectId: string, title: string, path: string, content: string, conversationHistory: any, chat: any, links: any, images: any, forms: any, locale: any, isIndex?: boolean }) =>
    await executeApiAction({
      action: () => ApiClient.createPage({
        projectId,
        title,
        path,
        content,
        conversationHistory,
        chat,
        links,
        images,
        forms,
        locale,
        isIndex
      }),
      onSuccess: async (page: Page) => {
        setPages((previousPages: Page[]) => {
          return [...previousPages, page];
        });
        if (isIndex) setSelectedPage(page);
      },
      errorMessage: 'Failed to create page.',
      successMessage: 'Page created',
    });


  const deletePage = async (id: string) =>
    await executeApiAction({
      action: () => ApiClient.deletePage(id),
      onSuccess: () => {
        setPages((previousPages: Page[]) => previousPages.filter((p: Page) => p.id !== id));
        // setSelectedPage(pages[0])
      },
      errorMessage: 'Failed to delete page.',
      successMessage: 'Page deleted',
    });

  const renamePage = async (page_id: string, new_title: string) =>
    await executeApiAction({
      action: () => ApiClient.renamePage({ page_id, new_title }),
      onSuccess: () => {
        setPages((previousPages: Page[]) => {
          return previousPages.map((page: Page) => {
            if (page.id === page_id) {
              return {
                ...page,
                title: new_title,
              };
            }
            return page;
          });
        });
      },
      errorMessage: 'Failed to rename page.',
      successMessage: 'Page renamed',
    });

  const updatePageContent = async (page_id: string, page_content: string) =>
    await executeApiAction({
      action: () => ApiClient.updatePageContent({ page_id, page_content }),
      onSuccess: () => {
        setPages((previousPages: Page[]) => {
          return previousPages.map((page: Page) => {
            if (page.id === page_id) {
              return {
                ...page,
                content: page_content,
              };
            }
            return page;
          });
        });
      },
      errorMessage: 'Failed to update content of page.',
      successMessage: 'Content updated.',
    });

  const updatePageConversationHistory = async (page_id: string, conversation_history: any[]) =>
    await executeApiAction({
      action: () => ApiClient.updatePageConversationHistory({ page_id, conversation_history }),
      onSuccess: () => {
        setPages((previousPages: Page[]) => {
          return previousPages.map((page: Page) => {
            if (page.id === page_id) {
              return {
                ...page,
                conversation_history: conversation_history,
              };
            }
            return page;
          });
        });
      },
      errorMessage: 'Failed to update conversation history of page.',
      successMessage: 'Conversation history updated.',
    });

  const fetchPageContent = async (page_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchPageContent(page_id),
      onSuccess: (content: string) => {
        setViewportSourceDocument(content);

        /*
        Legacy way of doing things, using the viewportTree for context,
        and a specific format of completions.
        */

        // const viewportTree = buildContextTree(parser.parseFromString(content, 'text/html'));
        // setViewportTree(viewportTree);

        const parser = new DOMParser();

        /*
        New method of doing things, context is plain html,
        and we remove contenteditable from context because it is not needed,
        as it is injected afterwards.
        */

        const bodyElement = parser.parseFromString(content, 'text/html').querySelector("div#body");

        if (bodyElement) {
          bodyElement.querySelectorAll('[contenteditable]').forEach(element => element.removeAttribute('contenteditable'));
          setGlobalContextOuterHTML(bodyElement.innerHTML);
        }

        // setGlobalContextOuterHTML(parser.parseFromString(content, 'text/html').querySelector("div#body")!.innerHTML)
        // setGlobalContextOuterHTML(parser.parseFromString(content, 'text/html').querySelector("div#body")!.outerHTML)
      },
      errorMessage: 'Failed to fetch page content.',
      successMessage: 'Page content fetched.',
    });
  const fetchPageContentWithoutViewport = async (page_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchPageContent(page_id),
      onSuccess: (content: string) => {
        // No viewport updates, just return the content
        return content;
      },
      errorMessage: 'Failed to fetch page content.',
      successMessage: 'Page content fetched.',
    });
  const updateAdditionalFormPageContent = async (pageId: string, content: string) =>
    await executeApiAction({
      action: () => ApiClient.updatePageContent({ page_id: pageId, page_content: content }),
      // No state updates needed for additional forms
      errorMessage: 'Failed to update additional form page content.',
      successMessage: 'Additional form page content updated.',
    });
  const fetchAllPagesContents = async (project_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchAllPagesContents(project_id),
      errorMessage: 'Failed to fetch all pages contents.',
      successMessage: 'Pages contents fetched.',
    });

  const fetchConversationHistory = async (page_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchConversationHistory(page_id),
      onSuccess: (conversation_history: any[]) => {
        setConversationHistory(conversation_history);
      },
      errorMessage: 'Failed to fetch conversation history.',
      successMessage: 'Conversation history fetched.',
    });

  const fetchChat = async (page_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchChat(page_id),
      onSuccess: (chat: any[]) => {
        setChatBubbles(chat);
      },
      errorMessage: 'Failed to fetch chat.',
      successMessage: 'Chat fetched.',
    });

  const updateChat = async (page_id: string, chat: any[]) =>
    await executeApiAction({
      action: () => ApiClient.updateChat({ page_id, chat }),
      onSuccess: () => {
        setPages((previousPages: Page[]) => {
          return previousPages.map((page: Page) => {
            if (page.id === page_id) {
              return {
                ...page,
                chat: chat,
              };
            }
            return page;
          });
        });
      },
      errorMessage: 'Failed to update chat.',
      successMessage: 'Chat updated.',
    });

  const fetchUndoHistory = async (page_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchUndoHistory(page_id),
      errorMessage: 'Failed to fetch undo history.',
      successMessage: 'Undo history fetched.',
    });

  const fetchLinks = async (page_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchLinks(page_id),
      onSuccess: (links: any) => {
        setPageLinks(links);
        // console.log('page-actions.ts -> ', links);
      },
      errorMessage: 'Failed to fetch links.',
      successMessage: 'links fetched.',
    });

  const updateLinks = async (page_id: string, links: any[]) =>
    await executeApiAction({
      action: () => ApiClient.updateLinks({ page_id, links }),
      onSuccess: () => {
        setPages((previousPages: Page[]) => {
          return previousPages.map((page: Page) => {
            if (page.id === page_id) {
              return {
                ...page,
                links: links,
              };
            }
            return page;
          });
        });
      },
      errorMessage: 'Failed to update links.',
      successMessage: 'links updated.',
    });

  // Forms start
  //   const fetchForms = async (page_id: string) => {
  //     await executeApiAction({
  //         action: () => ApiClient.fetchForms(page_id),
  //         onSuccess: (forms: any) => {
  //             console.log('Raw forms data from API:', forms);
  //             const mappedForms = forms.map((form:any) => ({
  //                 ...form,
  //                 is_active: Boolean(form.is_active),
  //                 destination_email: form.destination_email,
  //                 action: form.action
  //             }));
  //             console.log('Mapped forms data:', mappedForms);
  //             setPageForms(mappedForms);
  //         },
  //         errorMessage: 'Failed to fetch forms.',
  //         successMessage: 'Forms fetched.',
  //     });
  // };
  const fetchForms = async (page_id: string) => {
    await executeApiAction({
      action: () => ApiClient.fetchForms(page_id),
      onSuccess: (forms: any) => {
        console.log('Raw forms data from API:', forms);
        const mappedForms = forms.map((form: any) => ({
          ...form,
          is_active: Boolean(form.is_active),
          destination_email: form.destination_email,
          action: form.action
        }));
        console.log('Mapped forms data:', mappedForms);

        // Merge with existing forms while respecting page-specific states
        setPageForms((currentForms: any) => {
          return currentForms.map((currentForm: any) => {
            const matchingDbForm = mappedForms.find((dbForm: any) =>
              dbForm.dom_id === currentForm.dom_id &&
              dbForm.page_id === page_id
            );

            if (matchingDbForm) {
              return {
                ...currentForm,
                is_active: matchingDbForm.is_active,
                destination_email: matchingDbForm.destination_email,
                action: matchingDbForm.action
              };
            }
            return currentForm;
          });
        });
      },
      errorMessage: 'Failed to fetch forms.',
      successMessage: 'Forms fetched.',
    });
  }
  const updateForms = async (page_id: string, forms: any[]) =>
    await executeApiAction({
      action: () => ApiClient.updateForms({ page_id, forms }),
      onSuccess: () => {
        setPages((previousPages: Page[]) => {
          return previousPages.map((page: Page) => {
            if (page.id === page_id) {
              return {
                ...page,
                forms: forms,
              };
            }
            return page;
          });
        });
      },
      errorMessage: 'Failed to update forms.',
      successMessage: 'Forms updated.',
    });
  // Forms End

  const fetchImages = async (page_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchImages(page_id),
      onSuccess: (images: any) => {
        setPageImages(images);
      },
      errorMessage: 'Failed to fetch images.',
      successMessage: 'images fetched.',
    });

  const updateImages = async (page_id: string, images: any[]) =>
    await executeApiAction({
      action: () => ApiClient.updateImages({ page_id, images }),
      onSuccess: () => {
        setPages((previousPages: Page[]) => {
          return previousPages.map((page: Page) => {
            if (page.id === page_id) {
              return {
                ...page,
                images: images,
              };
            }
            return page;
          });
        });
      },
      errorMessage: 'Failed to update images.',
      successMessage: 'images updated.',
    });

  return {
    createPage: useCallback(createPage, [executeApiAction, setPages, setSelectedPage]),
    renamePage: useCallback(renamePage, [executeApiAction, setPages]),
    deletePage: useCallback(deletePage, [executeApiAction, setPages, pages, setSelectedPage]),
    updatePageContent: useCallback(updatePageContent, [executeApiAction, setPages]),
    updatePageConversationHistory: useCallback(updatePageConversationHistory, [executeApiAction, setPages]),
    fetchPageContent: useCallback(fetchPageContent, [executeApiAction, setViewportSourceDocument]),
    fetchAllPagesContents: useCallback(fetchAllPagesContents, [executeApiAction]),
    fetchConversationHistory: useCallback(fetchConversationHistory, [executeApiAction, setConversationHistory]),
    fetchUndoHistory: useCallback(fetchUndoHistory, [executeApiAction]),
    fetchChat: useCallback(fetchChat, [executeApiAction, setChatBubbles]),
    updateChat: useCallback(updateChat, [executeApiAction, setPages]),
    fetchLinks: useCallback(fetchLinks, [executeApiAction, setPageLinks]),
    updateLinks: useCallback(updateLinks, [executeApiAction, setPages]),
    fetchForms: useCallback(fetchForms, [executeApiAction, setPageForms]),
    updateForms: useCallback(updateForms, [executeApiAction, setPages]),
    fetchImages: useCallback(fetchImages, [executeApiAction, setPageImages]),
    updateImages: useCallback(updateImages, [executeApiAction, setPages]),
    fetchPageContentWithoutViewport: useCallback(fetchPageContentWithoutViewport, [executeApiAction]),
    updateAdditionalFormPageContent: useCallback(updateAdditionalFormPageContent, [executeApiAction]),
  };
};