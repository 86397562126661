import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserPreferences, currentUserState } from '../../state';
import { Tooltip, useBreakpointValue, Image, Checkbox, Button, IconButton, useDisclosure, useColorModeValue, Modal, ModalBody, ModalFooter, ModalContent, ModalHeader, Flex, Text, Heading, ModalOverlay, ModalCloseButton, Box, Grid } from '@chakra-ui/react';
import { MdGTranslate, MdOutlineCode, MdFormatListBulletedAdd, MdUndo, MdAccountTree, MdOutlineLineWeight, MdOutlineRestore, MdHelp, MdChevronRight } from 'react-icons/md';
import { RiVoiceprintFill } from "react-icons/ri";
import { TbMessageCode } from "react-icons/tb";
import { IoLinkSharp, IoSparkles } from "react-icons/io5";
import { FaFileExport, FaRegImage } from "react-icons/fa";
import { BsRocketTakeoff } from "react-icons/bs";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

export const Tips: React.FC<any> = ({ full }) => {
    const isWideScreen = useBreakpointValue({ base: false, md: true });
    const tipsIllustration = useColorModeValue('/illustrations/tips.png', '/illustrations/tips-dark.png');
    const user: any = useRecoilValue(currentUserState);

    const [userPreferences, setUserPreferences] = useRecoilState(UserPreferences);
    const { isOpen: isTipsModalOpen, onOpen: onTipsModalOpen, onClose: onTipsModalClose } = useDisclosure();

    const handleCheckboxOnChange = () => {
        setUserPreferences((previousPreferences: any) => ({
            ...previousPreferences,
            display_tips_modal_on_projects_list: !previousPreferences.display_tips_modal_on_projects_list
        }));
    }

    useEffect(() => {
        if (!full) return;
        if (window.location.href.endsWith('/') && userPreferences)
            if (userPreferences.display_tips_modal_on_projects_list) onTipsModalOpen();
    }, [])

    const bounceAnimation = {
        y: [0, -10, 0],
        transition: {
            duration: 1,
            repeat: Infinity,
        }
    };

    const smallScreenContent = (
        <Flex justifyContent={'space-between'} gap={4} flexDir="column">
            {/* Small screen content */}
            {[
                { text: "Clear content and start again.", icon: <MdOutlineRestore />, label: "Start From Scratch" },
                { text: "Use your voice to create.", icon: <RiVoiceprintFill />, label: "Stop recording", colorScheme: "pink" },
                { text: "Toggle pages panel.", icon: <MdOutlineLineWeight />, label: "Pages" },
                { text: "See an outline of your project's elements.", icon: <MdAccountTree />, label: "Outliner" },
                { text: "Use text to create.", icon: <TbMessageCode />, label: "Chat" },
                { text: "Toggle undo history panel.", icon: <MdUndo />, label: "Undo" },
                { text: "Toggle links and navigation panel.", icon: <IoLinkSharp />, label: "Links" },
                { text: "Toggle forms panel.", icon: <MdFormatListBulletedAdd />, label: "Forms" },
                { text: "Toggle images panel.", icon: <FaRegImage />, label: "Images" },
                { text: "Toggle developer mode panel.", icon: <MdOutlineCode />, label: "Developer Mode" },
                { text: "Instantly enhance your project with a click.", icon: <IoSparkles />, label: "Enhance" },
                { text: "Translate your project into different languages.", icon: <MdGTranslate />, label: "Translate" },
                { text: "Publish and share your project on the web.", icon: <BsRocketTakeoff />, label: "Deploy" },
                { text: "Export project locally and host anywhere.", icon: <FaFileExport />, label: "export" },
            ].map((item, index) => (
                <Flex key={index} gap={2} justifyContent="space-between" alignItems="center">
                    <Text>{item.text}</Text>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        color="current"
                        aria-label={item.label}
                        icon={item.icon}
                        colorScheme={item.colorScheme}
                    />
                </Flex>
            ))}
        </Flex>
    );

    const darkModeStyles = {
        bg: 'gray.800',
        color: 'white',
    };

    return (
        <>
            <Tooltip label='Tips: A quick guide to SIFO.' hasArrow placement='bottom-end' isDisabled={!userPreferences?.display_tooltips || false}>
                <IconButton
                    size="xs"
                    variant="ghost"
                    icon={<MdHelp />}
                    onClick={onTipsModalOpen}
                    aria-label="Tutorial"
                />
            </Tooltip>

            <Modal scrollBehavior="inside" isOpen={isTipsModalOpen} onClose={onTipsModalClose} size={user.plan_id === 'FREE_TRIAL' ? "5xl" : { base: "full", md: "4xl" }} isCentered>
                <ModalOverlay />
                <ModalContent maxH="90vh" {...(user.plan_id === 'FREE_TRIAL' ? darkModeStyles : {})}>
                    <ModalHeader> {user.plan_id === 'FREE_TRIAL' ? ( <Heading as="h1" size="2xl" textAlign="center" fontWeight="bold"> Prompting Tips </Heading> ) : ( 'Tips' )} </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {user.plan_id === 'FREE_TRIAL' && isWideScreen ? (
                            <Flex direction="column" alignItems="center">
                                <Box width="100%" maxWidth="1200px"> 
                                    <Box position="relative" paddingTop="52%" mb={4}> 
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                        >
                                            <source src="https://i.imgur.com/CI9BDhG.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <Flex 
                                            position="absolute" 
                                            bottom="0" 
                                            left="0" 
                                            right="0" 
                                            justifyContent="center" 
                                            alignItems="center"
                                            pb={2}
                                        >
                                            <motion.div
                                                animate={bounceAnimation}
                                            >
                                                <Box
                                                    bg="rgba(128,90,213,0.7)"
                                                    color="white"
                                                    borderRadius="full"
                                                    p={2}
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        const modalBody = document.querySelector('.chakra-modal__body');
                                                        if (modalBody) {
                                                            modalBody.scrollTo({
                                                                top: modalBody.scrollHeight,
                                                                behavior: 'smooth'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <ChevronDownIcon boxSize={6} />
                                                </Box>
                                            </motion.div>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Grid templateColumns={["1fr", "1fr 1fr"]} gap={4}>
                                            {[
                                                { title: "Specificity is Key", content: "Provide precise descriptions for enhanced visual uniqueness." },
                                                { title: "Embrace Details", content: "SIFO thrives on comprehensive information. Don't hesitate to provide extensive details in your requests." },
                                                { title: "Leverage Long Prompts", content: "Our starter prompts are intentionally lengthy. Aim for similar depth in your own prompts for optimal results." },
                                                { title: "Explore and Innovate", content: "SIFO's versatility allows for boundless creativity. Experiment with various concepts to unlock its full potential." }
                                            ].map((tip, index) => (
                                                <Box key={index} borderWidth={1} borderRadius="md" p={3} boxShadow="sm">
                                                    <Heading size="md" mb={2} textAlign="center">{tip.title}</Heading>
                                                    <Text fontSize="sm">{tip.content}</Text>
                                                </Box>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Box>
                            </Flex>
                        ) : user.plan_id === 'FREE_TRIAL' ? (
                            smallScreenContent
                        ) : isWideScreen ? (
                            // <Flex justifyContent={'space-between'} gap={10} flexDir={{ base: "column-reverse", md: "row" }}>
                            //     <Flex flex={1} flexDir={'column'} gap={4}>
                            //         <Heading size={'md'}>Be specific.</Heading>
                            //         <Text fontSize="md" mb={2}>
                            //             Describe exactly what you want. For more visual unique-ness.</Text>

                            //         <Heading size={'md'}>Get into details.</Heading>
                            //         <Text fontSize="md" mb={2}>
                            //             You are not only restricted to short prompts or requests, in fact, SIFO works better the more information you give it. So don't shy away from going into details.</Text>
                            //         <Heading size={'md'}>Long prompts are fine.</Heading>
                            //         <Text fontSize="md" mb={2}>
                            //             If you see our starter, example prompts, they are pretty lengthy. You should strive to be like that, the longer, the better.</Text>
                            //     </Flex>
                            //     <Flex flex={1} flexDir={'column'} gap={4}>
                            //         <Heading size={'md'}>Experiment, and have fun!</Heading>
                            //         <Text fontSize="md" mb={2}>
                            //             Do not constrain yourself a specific type of creation, SIFO is a platform that is capable of creating anything. So try things out.
                            //         </Text>
                            //         <Image src={tipsIllustration} width="400px" />
                            //     </Flex>
                            // </Flex> 
                            // uncomment above and comment below to go back to img for non free trial 
                            <Flex direction="column" alignItems="center">
                                <Box width="100%" maxWidth="1200px"> 
                                    <Box position="relative" paddingTop="52%" mb={4}> 
                                        <video 
                                            autoPlay 
                                            loop 
                                            muted 
                                            playsInline
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                        >
                                            <source src="https://i.imgur.com/CI9BDhG.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <Flex 
                                            position="absolute" 
                                            bottom="0" 
                                            left="0" 
                                            right="0" 
                                            justifyContent="center" 
                                            alignItems="center"
                                            pb={2}
                                        >
                                            <motion.div
                                                animate={bounceAnimation}
                                            >
                                                <Box
                                                    bg="rgba(128,90,213,0.7)"
                                                    color="white"
                                                    borderRadius="full"
                                                    p={2}
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        const modalBody = document.querySelector('.chakra-modal__body');
                                                        if (modalBody) {
                                                            modalBody.scrollTo({
                                                                top: modalBody.scrollHeight,
                                                                behavior: 'smooth'
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <ChevronDownIcon boxSize={6} />
                                                </Box>
                                            </motion.div>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Grid templateColumns={["1fr", "1fr 1fr"]} gap={4}>
                                            {[
                                                { title: "Specificity is Key", content: "Provide precise descriptions for enhanced visual uniqueness." },
                                                { title: "Embrace Details", content: "SIFO thrives on comprehensive information. Don't hesitate to provide extensive details in your requests." },
                                                { title: "Leverage Long Prompts", content: "Our starter prompts are intentionally lengthy. Aim for similar depth in your own prompts for optimal results." },
                                                { title: "Explore and Innovate", content: "SIFO's versatility allows for boundless creativity. Experiment with various concepts to unlock its full potential." }
                                            ].map((tip, index) => (
                                                <Box key={index} borderWidth={1} borderRadius="md" p={3} boxShadow="sm">
                                                    <Heading size="md" mb={2} textAlign="center">{tip.title}</Heading>
                                                    <Text fontSize="sm">{tip.content}</Text>
                                                </Box>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Box>
                            </Flex>
                        ) : (
                            smallScreenContent
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Flex w='full' justifyContent={'space-between'} gap={2}>
                            <Checkbox size={'sm'} onChange={handleCheckboxOnChange} colorScheme='purple' isChecked={userPreferences?.display_tips_modal_on_projects_list}>
                                Always show tips
                            </Checkbox>
                            <Button size="sm" colorScheme="purple" rightIcon={<MdChevronRight />} onClick={onTipsModalClose} variant="outline">
                                Got it
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};


// import { useEffect } from 'react';
// import { useRecoilState, useRecoilValue } from 'recoil';
// import { UserPreferences } from '../../state';
// import { Tooltip, useBreakpointValue, Image, Checkbox, Button, IconButton, useDisclosure, useColorModeValue, Modal, ModalBody, ModalFooter, ModalContent, ModalHeader, Flex, Text, Heading, ModalOverlay, ModalCloseButton } from '@chakra-ui/react';
// import { MdGTranslate, MdOutlineCode, MdFormatListBulletedAdd, MdUndo, MdAccountTree, MdOutlineLineWeight, MdOutlineRestore, MdHelp, MdChevronRight } from 'react-icons/md';
// import { RiVoiceprintFill } from "react-icons/ri";
// import { TbMessageCode } from "react-icons/tb";
// import { IoLinkSharp, IoSparkles } from "react-icons/io5";
// import { FaFileExport, FaRegImage } from "react-icons/fa";
// import { BsRocketTakeoff } from "react-icons/bs";

// // import { TipsSidebarIconsExplained } from '../../lib/tips-sidebar-icons-explained';

// export const Tips: React.FC<any> = ({ full }) => {
//     const isWideScreen = useBreakpointValue({ base: false, md: true });
//     const tipsIllustration = useColorModeValue('/illustrations/tips.png', '/illustrations/tips-dark.png');

//     const [userPreferences, setUserPreferences] = useRecoilState(UserPreferences);
//     const { isOpen: isTipsModalOpen, onOpen: onTipsModalOpen, onClose: onTipsModalClose } = useDisclosure();

//     const handleCheckboxOnChange = () => {
//         setUserPreferences((previousPreferences: any) => {
//             return {
//                 ...previousPreferences,
//                 display_tips_modal_on_projects_list: !previousPreferences.display_tips_modal_on_projects_list
//             }
//         });
//     }

//     useEffect(() => {
//         if (!full) return;

//         if (window.location.href.endsWith('/') && userPreferences)
//             if (userPreferences.display_tips_modal_on_projects_list) onTipsModalOpen();
//     }, [])

//     return (
//         <>
//             <Tooltip label='Tips: A quick guide to SIFO.' hasArrow placement='bottom-end' isDisabled={!userPreferences?.display_tooltips || false}>
//                 <IconButton
//                     size="xs"
//                     variant="ghost"
//                     icon={<MdHelp />}
//                     onClick={onTipsModalOpen}
//                     aria-label="Tutorial"
//                 />
//             </Tooltip>

//             <Modal scrollBehavior="inside" isOpen={isTipsModalOpen} onClose={onTipsModalClose} size={{ base: "full", md: "4xl" }} isCentered>
//                 <ModalOverlay />
//                 <ModalContent>
//                     <ModalHeader>Tips</ModalHeader>
//                     <ModalCloseButton />
//                     <ModalBody>
//                         {isWideScreen ? (
//                             <Flex justifyContent={'space-between'} gap={10} flexDir={{ base: "column-reverse", md: "row" }}>
//                                 <Flex flex={1} flexDir={'column'} gap={4}>
//                                     <Heading size={'md'}>Be specific.</Heading>
//                                     <Text fontSize="md" mb={2}>
//                                         Describe exactly what you want. For more visual unique-ness.</Text>

//                                     <Heading size={'md'}>Get into details.</Heading>
//                                     <Text fontSize="md" mb={2}>
//                                         You are not only restricted to short prompts or requests, in fact, SIFO works better the more information you give it. So don't shy away from going into details.</Text>
//                                     <Heading size={'md'}>Long prompts are fine.</Heading>
//                                     <Text fontSize="md" mb={2}>
//                                         If you see our starter, example prompts, they are pretty lengthy. You should strive to be like that, the longer, the better.</Text>
//                                 </Flex>
//                                 <Flex flex={1} flexDir={'column'} gap={4}>
//                                     <Heading size={'md'}>Experiment, and have fun!</Heading>
//                                     <Text fontSize="md" mb={2}>
//                                         Do not constrain yourself a specific type of creation, SIFO is a platform that is capable of creating anything. So try things out.
//                                     </Text>
//                                     <Image src={tipsIllustration} width="400px" />
//                                 </Flex>
//                             </Flex>
//                         )
//                             : (
//                                 <Flex justifyContent={'space-between'} gap={4} flexDir="column">
//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Clear content and start again.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Start Form Scratch"
//                                             icon={<MdOutlineRestore />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Use your voice to create.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             colorScheme='pink'
//                                             aria-label='Stop recording'
//                                             icon={<RiVoiceprintFill />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Toggle pages panel.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Pages"
//                                             icon={<MdOutlineLineWeight />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >See an outline of your project's elements.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Outliner"
//                                             icon={<MdAccountTree />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Use text to create.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Chat"
//                                             icon={<TbMessageCode />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Toggle undo history panel.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Undo"
//                                             icon={<MdUndo />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Toggle links and navigation panel.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Links"
//                                             icon={<IoLinkSharp />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Toggle forms panel.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Forms"
//                                             icon={<MdFormatListBulletedAdd />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Toggle images panel.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Images"
//                                             icon={<FaRegImage />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Toggle developer mode panel.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Developer Mode"
//                                             icon={<MdOutlineCode />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Instantly enhance your project with a click.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Enhance"
//                                             icon={<IoSparkles />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Translate your project into different languages.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Translate"
//                                             icon={<MdGTranslate />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Publish and share your project on the web.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="Deploy"
//                                             icon={<BsRocketTakeoff />}
//                                         />
//                                     </Flex>

//                                     <Flex gap={2} justifyContent="space-between" alignItems="center">
//                                         <Text >Export project locally and host anywhere.</Text>
//                                         <IconButton
//                                             size="sm"
//                                             variant="ghost"
//                                             color="current"
//                                             aria-label="export"
//                                             icon={<FaFileExport />}
//                                         />
//                                     </Flex>

//                                 </Flex>
//                             )}

//                     </ModalBody>

//                     <ModalFooter>
//                         <Flex w='full' justifyContent={'space-between'} gap={2}>
//                             <Checkbox size={'sm'} onChange={handleCheckboxOnChange} colorScheme='purple' isChecked={userPreferences?.display_tips_modal_on_projects_list}>
//                                 Always show tips
//                             </Checkbox>
//                             <Button size="sm" colorScheme="purple" rightIcon={<MdChevronRight />} onClick={onTipsModalClose}>
//                                 Got it
//                             </Button>
//                         </Flex>
//                     </ModalFooter>
//                 </ModalContent>
//             </Modal>
//         </>
//     );
// };

