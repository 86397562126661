import { parse } from 'acorn-loose';
import { generate } from 'astring';

export function insertJavascript(existingJavascript: string, CompletionJavascript: string) {
    let output: string = '';

    const parsedOutput = parse(existingJavascript, { ecmaVersion: 2020 }).body;
    const parsedCompletion = parse(CompletionJavascript, { ecmaVersion: 2020 }).body;
    const parsedJoined: any = parsedOutput.concat(parsedCompletion);
    const uniqueESTreeNodes = filterNodes(parsedJoined);

    uniqueESTreeNodes.forEach((node: any) =>
        output += generate(node) + "\n\n"
    );

    return output.trim();
}

export function compareNodes(
    firstNode: any,
    secondNode: any,
    recursionLevel: number = 0,
) {
    if (typeof firstNode !== "object" || typeof secondNode !== "object") {
        return false;
    }

    // When a function's name is repeated, but its properties or body changes.
    if (
        firstNode.type === "FunctionDeclaration" &&
        secondNode.type === "FunctionDeclaration"
    ) {
        if (
            firstNode.id && secondNode.id &&
            firstNode.id.name === secondNode.id.name &&
            !compareNodesArrays(firstNode.params, secondNode.params) ||
            !compareNodesSimple(firstNode.body, secondNode.body)
        ) {
            return true;
        }
    }

    // AddEventListener, (and the likes of it.)
    if (
        firstNode.type === "ExpressionStatement" &&
        secondNode.type === "ExpressionStatement"
    ) {
        if (
            firstNode.expression.type === "CallExpression" &&
            secondNode.expression.type === "CallExpression"
        ) {
            const firstCalleeProperty = firstNode.expression.callee?.property;
            const secondCalleeProperty = secondNode.expression.callee?.property;
            if (
                firstCalleeProperty && secondCalleeProperty &&
                firstCalleeProperty.name === secondCalleeProperty.name
                // firstCalleeProperty.name === "addEventListener" &&
                // secondCalleeProperty.name === "addEventListener"
            ) {
                // If querySelector argument is the same, red-btn, red-btn, overwrite.
                if (
                    Array.isArray(
                        firstNode.expression.callee.object.arguments,
                    ) &&
                    Array.isArray(
                        secondNode.expression.callee.object.arguments,
                    ) &&
                    Array.isArray(
                        firstNode.expression.arguments,
                    ) &&
                    Array.isArray(
                        secondNode.expression.arguments,
                    )
                ) {
                    if (
                        (
                            firstNode.expression.callee.object.arguments[0].value ===
                            secondNode.expression.callee.object.arguments[0].value
                        ) && (
                            firstNode.expression.arguments[0].value ===
                            secondNode.expression.arguments[0].value
                        )
                    ) {
                        return true;
                    }
                } else {
                    if (
                        firstNode.expression.callee.object.name &&
                        secondNode.expression.callee.object.name &&
                        firstNode.expression.callee.object.name ===
                        secondNode.expression.callee.object.name &&
                        firstNode.expression.arguments[0].value ===
                        secondNode.expression.arguments[0].value
                    ) {
                        return true;
                    }
                }
            }
        }
    }

    if (
        firstNode.type === "IfStatement" &&
        secondNode.type === "IfStatement"
    ) {
        if (
            compareNodesSimple(firstNode.test, secondNode.test)
        ) {
            return true;
        }
    }

    if (
        firstNode.type === "SwitchStatement" &&
        secondNode.type === "SwitchStatement"
    ) {
        if (
            compareNodesSimple(firstNode.discriminant, secondNode.discriminant)
        ) {
            return true;
        }
    }

    if (
        firstNode.type === "WhileStatement" &&
        secondNode.type === "WhileStatement"
    ) {
        if (
            compareNodesSimple(firstNode.test, secondNode.test)
        ) {
            return true;
        }
    }

    if (
        firstNode.type === "ForStatement" &&
        secondNode.type === "ForStatement"
    ) {
        if (
            compareNodesSimple(firstNode.init, secondNode.init) &&
            compareNodesSimple(firstNode.update, secondNode.update) &&
            compareNodesSimple(firstNode.test, secondNode.test)
        ) {
            return true;
        }
    }

    if (
        firstNode.type === "ForInStatement" &&
        secondNode.type === "ForInStatement"
    ) {
        if (
            compareNodesSimple(firstNode.left, secondNode.left) &&
            compareNodesSimple(firstNode.right, secondNode.right)
        ) {
            return true;
        }
    }

    if (
        firstNode.type === "ForOfStatement" &&
        secondNode.type === "ForOfStatement"
    ) {
        if (
            compareNodesSimple(firstNode.left, secondNode.left) &&
            compareNodesSimple(firstNode.right, secondNode.right)
        ) {
            return true;
        }
    }

    if (
        firstNode.type === "VariableDeclaration" &&
        secondNode.type === "VariableDeclaration"
    ) {
        let matchFound = false;

        firstNode.declarations.forEach((declaration: any, index: number) => {
            const firstId = declaration.id;
            const secondId = secondNode.declarations[index]?.id;

            if (firstId && secondId && firstId.name === secondId.name) {
                matchFound = true;
            }
        });

        if (matchFound) {
            return true;
        }
    }

    // Keep on the lookout for issues that might be caused
    // by the following code section.

    // Allow duplication on top recursionLevel.
    if (recursionLevel === 0) {
        if (
            firstNode.type === "Literal" &&
            secondNode.type === "Literal"
        ) {
            return false;
        }

        // This is confirmed to be problematic in some cases,
        // things seem fine so far, only testing will tell.

        // if (
        //   firstNode.type === "Identifier" &&
        //   secondNode.type === "Identifier"
        // ) {
        //   return false;
        // }

        if (
            firstNode.type === "ExpressionStatement" &&
            secondNode.type === "ExpressionStatement"
        ) {
            return false;
        }

        if (
            firstNode.type === "BinaryExpression" &&
            secondNode.type === "BinaryExpression"
        ) {
            return false;
        }

        if (
            firstNode.type === "UnaryExpression" &&
            secondNode.type === "UnaryExpression"
        ) {
            return false;
        }

        if (
            firstNode.type === "UpdateExpression" &&
            secondNode.type === "UpdateExpression"
        ) {
            return false;
        }

        if (
            firstNode.type === "LogicalExpression" &&
            secondNode.type === "LogicalExpression"
        ) {
            return false;
        }

        if (
            firstNode.type === "DebuggerStatement" &&
            secondNode.type === "DebuggerStatement"
        ) {
            return false;
        }
    }

    const firstNodeProperties = Object.keys(firstNode);
    const secondNodeProperties = Object.keys(secondNode);

    if (firstNodeProperties.length !== secondNodeProperties.length) return false;

    for (const key of firstNodeProperties) {
        if (!secondNodeProperties.includes(key)) return false;
        if (key === "start" || key === "end") continue;

        if (Array.isArray(firstNode[key]) && Array.isArray(secondNode[key])) {
            if (!compareNodesArrays(firstNode[key], secondNode[key])) {
                return false;
            }
        } else if (
            (firstNode[key] !== null && secondNode[key] !== null) &&
            typeof firstNode[key] === "object" &&
            typeof secondNode[key] === "object"
        ) {
            if (!compareNodes(firstNode[key], secondNode[key], recursionLevel + 1)) {
                return false;
            }
        } else if (firstNode[key] !== secondNode[key]) {
            return false;
        }
    }

    return true;
}

export function compareNodesSimple(firstNode: any, secondNode: any) {
    if (typeof firstNode !== "object" || typeof secondNode !== "object") {
        return false;
    }

    const firstNodeProperties = Object.keys(firstNode);
    const secondNodeProperties = Object.keys(secondNode);

    if (firstNodeProperties.length !== secondNodeProperties.length) return false;

    for (const key of firstNodeProperties) {
        if (!secondNodeProperties.includes(key)) return false;
        if (key === "start" || key === "end") continue;

        if (Array.isArray(firstNode[key]) && Array.isArray(secondNode[key])) {
            if (!compareNodesArrays(firstNode[key], secondNode[key])) {
                return false;
            }
        } else if (
            (firstNode[key] !== null && secondNode[key] !== null) &&
            typeof firstNode[key] === "object" &&
            typeof secondNode[key] === "object"
        ) {
            if (!compareNodes(firstNode[key], secondNode[key])) {
                return false;
            }
        } else if (firstNode[key] !== secondNode[key]) {
            return false;
        }
    }

    return true;
}

export function compareNodesArrays(firstArray: any, secondArray: any) {
    if (
        !Array.isArray(firstArray) ||
        !Array.isArray(secondArray) ||
        firstArray.length !== secondArray.length
    ) {
        return false;
    }

    for (let i = 0; i < firstArray.length; i++) {
        if (!compareNodes(firstArray[i], secondArray[i])) return false;
    }

    return true;
}

export function filterNodes(estree: any) {
    const uniqueNodesArray: any = [];

    for (const node of estree) {
        const existingIndex: any = uniqueNodesArray.findIndex((uniqueNode: any) =>
            compareNodes(uniqueNode, node)
        );

        if (existingIndex === -1) {
            uniqueNodesArray.push(node);
        } else {
            uniqueNodesArray[existingIndex] = node;
        }
    }

    return uniqueNodesArray;
}
