import { atom } from 'recoil';

export const ContentUndoHistory = atom({
    key: 'content-undo-history',
    default: [
        {
            timestamp: new Date().toLocaleString(),
            label: 'Action',
            content: ''
            // content
        }
    ]
});

// export const ContentUndoHistory = atom({
//     key: 'content-undo-history',
//     default: []
// });

export const ConversationHistoryUndoHistory = atom({
    key: 'conversation-history-undo-history',
    default: [
        [
            {
                role: 'system',
                content: '',
                // content: systemMessage,
            }
        ],
    ]
});

export const UndoHistoryStepIndex = atom({
    key: 'undo-history-step-index',
    default: 0
});

export const DidIUndo = atom({
    key: 'did-i-undo',
    default: false
});