import * as React from 'react';
import { Flex } from '@chakra-ui/react';

interface MainPanelProps {
  children: React.ReactNode;
}

export const MainContent: React.FC<MainPanelProps> = ({ children }) => (
  <Flex flexGrow={1} p={2} overflowY='auto'>
  {/* <Flex flexDir='column' flexGrow={1} p={3} overflowY='auto'> */}
    {children}
  </Flex>
);
