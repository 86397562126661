import * as React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
// import { Status } from '../../components/engine/Status';

interface MainPanelProps {
  children: React.ReactNode;
  clearSidebar: boolean;
}

export const MainPanel: React.FC<MainPanelProps> = ({ children, clearSidebar }) => {
  const bg = useColorModeValue('gray.100', 'gray.900');
  // const bg = useColorModeValue('gray.100', 'gray.900');

  return (
    <Flex
      overflowY="auto"
      // position="relative"
      // transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
      // transitionDuration=".2s, .2s, .35s"
      // transitionProperty="top, bottom, width"
      // transitionTimingFunction="linear, linear, ease"
      // flexDir="column"
      flexGrow="1"
      // p={2} 
      // ml={{ base: 0, md: clearSidebar ? 60 : 0 }}
      // bg={bg}
    >
      {children}
      {/* <Status /> */}
    </Flex>
  );
};
