import React, { useState, useRef } from 'react';
import {
  useDisclosure, Alert, AlertIcon, Box, Flex, Button, Link, SimpleGrid, Text, VStack, Checkbox, Divider, Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,Tooltip,Icon
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

import { MdPayments, MdAddShoppingCart, MdPerson, MdPayment } from 'react-icons/md';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AuthenticatedLayout } from '../layouts';
import { currentUserState, UserPreferences } from '../state';
import { SoftCard } from '../components/generic/SoftCard';
import { PageHeading } from '../components/generic/PageHeading';
import { useSubscriptionActions, useUserActions } from '../hooks';
import { useNavigate } from 'react-router-dom';

import { model2, model3, model4, image1, image2, aiActionsFunctionCalling } from '../lib/feature-availability';
import { DangerButton } from '../components/generic/DangerButton';


export const SettingsPage: React.FC = () => {

  const user = useRecoilValue(currentUserState);
  const { manageSubscriptions } = useSubscriptionActions();
  const { resendVerification, deleteUser } = useUserActions();
  const navigate = useNavigate();
  const [userPreferences, setUserPreferences] = useRecoilState(UserPreferences);
  const MotionBox = motion(Box);


  console.log(userPreferences);

  const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure();
  const cancelRef = useRef<any | null>();

  const onDeleteAccount = async () => {
    if (user && user.id) {
      await deleteUser(user.id);
    }
  }

  const handleDisplayTutorialOnChange = () => {
    setUserPreferences((previousPreferences: any) => {
      return {
        ...previousPreferences,
        display_tips_modal_on_projects_list: !previousPreferences.display_tips_modal_on_projects_list
      }
    });
  }

  const handleDisplayTooltipsOnChange = () => {
    setUserPreferences((previousPreferences: any) => {
      return {
        ...previousPreferences,
        display_tooltips: !previousPreferences.display_tooltips
      }
    });
  }

  const handleEnableAiActionsOnChange = () => {
    if (aiActionsFunctionCalling.includes(user?.plan_id as string)) {
      setUserPreferences((previousPreferences: any) => {
        return {
          ...previousPreferences,
          ai_actions: !previousPreferences.ai_actions
        }
      });
    } else {
      onUpgradeOpen();
    }
  }

  const handleEnableCreatePagesAutoOnLinkOnChange = () => {
    setUserPreferences((previousPreferences: any) => {
      return {
        ...previousPreferences,
        create_pages_automatically_on_linking: !previousPreferences.create_pages_automatically_on_linking
      }
    });
  }

  // const handleContextSizeOnChange = (context_size: any) => {
  //   setUserPreferences((previousPreferences: any) => {
  //     return {
  //       ...previousPreferences,
  //       context_size: context_size
  //     }
  //   });
  // }

  const handleModelOnChange = (event: any) => {
    if (event.target.value === "model-1") {
      setUserPreferences((previousPreferences: any) => {
        return {
          ...previousPreferences,
          ai_model: event.target.value
        }
      });
    } else if (event.target.value === "model-2") {
      if (model2.includes(user?.plan_id as string)) {
        setUserPreferences((previousPreferences: any) => {
          return {
            ...previousPreferences,
            ai_model: event.target.value
          }
        });
      } else {
        onUpgradeOpen();
      }

    } else if (event.target.value === "model-3") {
      if (model3.includes(user?.plan_id as string)) {
        setUserPreferences((previousPreferences: any) => {
          return {
            ...previousPreferences,
            ai_model: event.target.value
          }
        });
      } else {
        onUpgradeOpen();
      }
    } else if (event.target.value === "model-4") {
      if (model4.includes(user?.plan_id as string)) {
        setUserPreferences((previousPreferences: any) => {
          return {
            ...previousPreferences,
            ai_model: event.target.value
          }
        });
      } else {
        onUpgradeOpen();
      }
    }

    // if (model2.includes(user?.plan_id as string)) {
    //   setUserPreferences((previousPreferences: any) => {
    //     return {
    //       ...previousPreferences,
    //       ai_model: event.target.value
    //     }
    //   });
    // } else {
    //   onUpgradeOpen();
    // }

  }

  const handleImageGenerationModelOnChange = (event: any) => {

    if (event.target.value === "image-1") {
      if (image1.includes(user?.plan_id as string)) {
        setUserPreferences((previousPreferences: any) => {
          return {
            ...previousPreferences,
            image_model: event.target.value
          }
        });
      } else {
        onUpgradeOpen();
      }
    } else if (event.target.value === "image-2") {
      if (image2.includes(user?.plan_id as string)) {
        setUserPreferences((previousPreferences: any) => {
          return {
            ...previousPreferences,
            image_model: event.target.value
          }
        });
      } else {
        onUpgradeOpen();
      }
    }
    
    // if (image2.includes(user?.plan_id as string)) {
    //   setUserPreferences((previousPreferences: any) => {
    //     return {
    //       ...previousPreferences,
    //       image_model: event.target.value
    //     }
    //   });
    // } else {
    //   onUpgradeOpen();
    // }

  }

  const labelStyles = {
    mt: '5',
    ml: '-1',
    fontSize: 'xs',
  }

  const marks = [
    { value: 0, label: '0' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 75, label: '75' },
    { value: 100, label: '100' },
  ];

  return (
    <AuthenticatedLayout sidebar={false}>
      {/* <SimpleGrid gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} width="100%" gridGap={4}> */}
      <SimpleGrid gridTemplateColumns='1fr' w={{ base: 'full', md: '720px' }} mx='auto' gridGap={4}>
        {/* <SimpleGrid minChildWidth="200px" width="100%" gridGap={4}> */}
        <SoftCard>
          <PageHeading title="Profile" icon={MdPerson} />
          {user && (
            <>
              <VStack pt={6} spacing={6} alignItems="flex-start">
                {!user.verified_at && (
                  <Alert status="warning">
                    <AlertIcon />
                    <Text>
                      Your account is not verified. Please verify your account by using the link you received after sign
                      up. Did not get the email? Click{' '}
                      <Link fontWeight={600} onClick={async () => await resendVerification()}>
                        here
                      </Link>{' '}
                      to resend.
                    </Text>
                  </Alert>
                )}
                <Flex p='4' alignItems='center' gap={6} w='full' justifyContent='space-between'>

                  <Flex gap={6} justifyContent='space-between'>
                    <Box>
                      <Text fontWeight={700}>Name</Text>
                      <Text size="md">
                        {user.first_name} {user.last_name}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontWeight={700}>Email</Text>
                      <Text noOfLines={2}>{user.email}</Text>
                    </Box>

                  </Flex>

                  <DangerButton
                    onClick={onDeleteAccount}
                    title={'Delete account'}
                    body={'Make sure to cancel any running subscriptions you may have; are you sure you want to delete this account? This action can not be reversed.'}
                    colorScheme="red"
                    extraButtonText={'Manage subscription'}
                    extraButtonIcon={<MdPayments />}
                    extraButtonOnClick={manageSubscriptions}
                  />
                </Flex>
                <Divider />

                <Flex flexDirection='column' gap={3}>
                  <PageHeading title="Preferences" />
                  <Checkbox size={'sm'} onChange={handleDisplayTutorialOnChange} colorScheme='purple' isChecked={userPreferences.display_tips_modal_on_projects_list}>
                    Display tutorial & tips
                  </Checkbox>
                  <Checkbox size={'sm'} onChange={handleDisplayTooltipsOnChange} colorScheme='purple' isChecked={userPreferences.display_tooltips}>
                    Display tooltips on hover
                  </Checkbox>
                </Flex>

                <Box>
                  <Text fontWeight={700}>Model</Text>
                  {/* <Text mb={3}>The AI model to use, each model has is own style, power points, and weaknesses.</Text> */}

                  <Box p={2}>
                  <Text fontSize='xs'><b>Model 1</b> is very fast, good for building block by block, section by section, and offers precise editing.</Text>
                  <Text fontSize='xs'><b>Model 2</b> is extremely effective and easily follows multiple requests at once. Perfect for big sites and applications.</Text>
                  <Text fontSize='xs'><b>Model 3</b> is powerful, great at styling, and meticulously follows requests. Great for lengthy sites and applications.</Text>
                  <Text fontSize='xs'><b>Model 4</b> is Smart, has good reasoning, more fit to create applications with logic than user interfaces.</Text>
                  </Box>

                  <Select mt={1} size="sm" onChange={handleModelOnChange} value={userPreferences.ai_model} width='sm'>
                    <option value='model-1'>MODEL-1</option>
                    <option value='model-2'>MODEL-2 {!model2.includes(user?.plan_id as string) ? '🔒' : ''}</option>
                    <option value='model-3'>MODEL-3 {!model3.includes(user?.plan_id as string) ? '🔒' : ''}</option>
                    <option value='model-4'>MODEL-4 {!model3.includes(user?.plan_id as string) ? '🔒' : ''}</option>
                    {/* <option value='sifo-0'>SIFO-0	{!gpt4.includes(user?.plan_id as string) ? '🔒' : ''}</option> */}
                  </Select>

                </Box>

                <Box>
                  <Text fontWeight={700}>Image generation model</Text>
                  <Text mb={3}>The image generation model to use, Image-1, is fast, reliable, and gets decent results. While Image-2 produces more realistic and higher-quality images, it is limited to premium plans.</Text>

                  <Select mt={1} size="sm" onChange={handleImageGenerationModelOnChange} value={userPreferences.image_model} width='sm'>
                    <option value='image-1'>IMAGE-1</option>
                    <option value='image-2'>IMAGE-2	{!image2.includes(user?.plan_id as string) ? '🔒' : ''}</option>
                  </Select>
                </Box>

                <Flex flexDirection='column' gap={3}>
                <Text fontWeight={700}>Experimental</Text>
                  <Checkbox size={'sm'} onChange={handleEnableAiActionsOnChange} colorScheme='purple' isChecked={userPreferences.ai_actions}>
                    Enable AI actions
                  </Checkbox>
                  <Checkbox size={'sm'} onChange={handleEnableCreatePagesAutoOnLinkOnChange} colorScheme='purple' isChecked={userPreferences.create_pages_automatically_on_linking}>
                    Create pages automatically when linked to
                  </Checkbox>
                </Flex>

                {/* Legacy: Context size */}
                {/* <Box w="full">
                  <Text fontWeight={700}>Memory</Text>
                  <Text mb={3}>How much the AI remembers, and takes into account, also known as, the context size, or length.</Text>

                  <Slider value={userPreferences.context_size} onChange={handleContextSizeOnChange} min={3} max={6} step={1} size="md" width="sm">
                    <SliderMark value={3} {...labelStyles}>
                      Decent
                    </SliderMark>
                    <SliderMark value={4} {...labelStyles}>
                      Normal
                    </SliderMark>
                    <SliderMark value={5} {...labelStyles}>
                      Good
                    </SliderMark>
                    <SliderMark value={6} {...labelStyles}>
                      Better
                    </SliderMark>

                    <SliderTrack bg='purple.100'>
                      <Box position='relative' right={10} />
                      <SliderFilledTrack bg='purple.400' />
                    </SliderTrack>
                    <SliderThumb boxSize={5} />
                  </Slider>
                </Box> */}

<AlertDialog
  isOpen={isUpgradeOpen}
  leastDestructiveRef={cancelRef}
  onClose={onUpgradeClose}
  isCentered
  size="lg"
>
<AlertDialogOverlay>
    <AlertDialogContent boxShadow="lg" p={5} borderRadius="md" bg="gray.800">
      <AlertDialogHeader
        fontSize="2xl"
        fontWeight="bold"
        display="flex"
        alignItems="center"
        color="white"
      >
        <Tooltip label="Upgrade to unlock all features" aria-label="Upgrade tooltip">
          <Icon as={InfoOutlineIcon} color="purple.500" mr={3} />
        </Tooltip>
        Upgrade
      </AlertDialogHeader>

      <AlertDialogBody fontSize="lg" mt={4} display="flex" flexDirection="column" alignItems="center" color="gray.200">
        <MotionBox
          animate={{ rotate: [0, 360] }}
          transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }}
          mb={6}
        >
          <Icon as={InfoOutlineIcon} w={16} h={16} color="purple.400" />
        </MotionBox>
        <Text textAlign="center">
          <Text as="span" fontWeight="bold">
          This action is not available in your current plan.
          </Text>{" "}
          Upgrade now to unlock it and get the most out of SIFO's full suite of features.
        </Text>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent="center" mt={6}>
        <Button
          size="md"
          variant="outline"
          ref={cancelRef}
          onClick={onUpgradeClose}
          mr={3}
          color="white"
          _hover={{ bg: "gray.700" }}
        >
          Cancel
        </Button>
        <Button
          size="md"
          colorScheme="purple"
          onClick={() => navigate('/upgrade')}
          _hover={{ boxShadow: "0 0 10px rgba(128, 90, 213, 0.8)" }} variant='outline'
        >
          Unlock Now
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>

              </VStack>
            </>
          )}
        </SoftCard>
        <SoftCard>
          <PageHeading title="Subscriptions" icon={MdPayment} />
          {!user?.has_active_subscription && (
            <Button mt={6} colorScheme="purple" size={'sm'} rightIcon={<MdAddShoppingCart />} onClick={() => navigate('/onboarding')}>
              Activate Subscription
            </Button>
          )}
          <Button mt={6} colorScheme="purple" size={'sm'} rightIcon={<MdPayments />} onClick={manageSubscriptions} variant="outline">
            Manage Subscription
          </Button>
        </SoftCard>
      </SimpleGrid>
    </AuthenticatedLayout>
  );
};
