import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AuthenticatedLayout } from "../layouts";
import { useCloudFrontActions, usePageActions } from "../hooks";
import {
  currentUserState,
  IsDeployingSpinner,
  pagesState,
  selectedProjectSelector,
  UserAllottedCredits,
} from "../state";
import { SoftCard } from "../components/generic/SoftCard";
import { DangerButton } from "../components/generic/DangerButton";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { html as beautify_html } from "js-beautify";
import { languages } from "../lib/languages";
import { motion } from 'framer-motion';

export const HostingPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    isOpen: isUpgradeOpen,
    onOpen: onUpgradeOpen,
    onClose: onUpgradeClose,
  } = useDisclosure();
  const cancelRef = useRef<any | null>();

  const publishedProjectImageSource = useColorModeValue(
    "/illustrations/published-project.png",
    "/illustrations/published-project-dark.png",
  );
  const unpublishedProjectImageSource = useColorModeValue(
    "/illustrations/unpublished-project.png",
    "/illustrations/unpublished-project-dark.png",
  );

  const pages = useRecoilValue(pagesState);
  const currentUser:any = useRecoilValue(currentUserState);
  const selectedProject = useRecoilValue(selectedProjectSelector);
  const [deployedSite, setDeployedSite] = useState<null | any>(null);
  const { createSite, deploySite, fetchSite,fetchSites, saveSite, deleteSite } = useCloudFrontActions();


  const { fetchAllPagesContents } = usePageActions();

  const setIsDeployingSpinner = useSetRecoilState(IsDeployingSpinner);
  const isDeployingSpinner = useRecoilValue(IsDeployingSpinner);
  const userAllottedCredits:any = useRecoilValue(UserAllottedCredits);
  const MotionBox = motion(Box);

  const prepareDocumentForExport = (
    pageDocument: any,
    pageTitle: string,
    pageLocale: any,
  ) => {
    // const baseElement = pageDocument.querySelector('base');
    // baseElement.remove();

    const titleElement = document.createElement("title");
    // titleElement.textContent = `${pageTitle} | ${project?.name}`;
    titleElement.textContent = `${
      selectedProject?.title_display
        ? selectedProject.title_placement === "left"
          ? selectedProject?.name + " " + selectedProject.title_separator
          : ""
        : ""
    } ${pageTitle} ${
      selectedProject?.title_display
        ? selectedProject.title_placement === "right"
          ? selectedProject.title_separator + " " + selectedProject?.name
          : ""
        : ""
    }`;
    pageDocument.head.appendChild(titleElement);

    const faviconElement = document.createElement("link");
    faviconElement.rel = "icon";
    faviconElement.type = "image/x-icon";
    if (selectedProject) faviconElement.href = selectedProject?.favicon;
    pageDocument.head.appendChild(faviconElement);

    // The fact that everything works in the viewport means that I shouldn't touch anything.
    // Now, on export,

    // In a localized page,
    if (pageLocale !== "Default") {
      const anchorTags = pageDocument.querySelectorAll("a");
      anchorTags.forEach((anchor: any) => {
        const anchorHref = anchor.getAttribute("href");
        if (!anchorHref.endsWith(".html")) return;

        const dividedAnchorHref = anchorHref.split("/");
        const localeShortForm = dividedAnchorHref[0];
        const locale = languages.find((lang) =>
          lang.shortForm === localeShortForm
        )?.name;

        // Trying to go to a localized page
        if (dividedAnchorHref.length === 2) {
          // If the page's locale is the same as thr links locale, e.g. fr and fr, remove the links locale, to allow navigation,
          if (locale === pageLocale) {
            anchor.setAttribute("href", dividedAnchorHref[1]);
          } else {
            anchor.setAttribute(
              "href",
              `../${dividedAnchorHref[0]}/${dividedAnchorHref[1]}`,
            );
          }

          // if it's existent (and is not the same as page's locale, go back, then go into locales directory, ../de/

          // Trying to go to a non-localized page
        } else if (dividedAnchorHref.length === 1) {
          // If the link's locale is not existent in languages, meaning it's Default, add ../ instead

          anchor.setAttribute("href", `../${dividedAnchorHref[0]}`);
        }
      });
    }

    // const formTags = pageDocument.querySelectorAll('form');
    // formTags.forEach((formTag: any) => formTag.setAttribute('target', '_blank'));

    const contentEditableElements = pageDocument.querySelectorAll(
      "[contenteditable]",
    );
    contentEditableElements.forEach((ceElement: any) =>
      ceElement.removeAttribute("contenteditable")
    );

    const manualEditorElements = pageDocument.querySelectorAll(
      ".sifo-manual-editor",
    );
    manualEditorElements.forEach((meElement: any) => meElement.remove());

    const previewElements = pageDocument.querySelectorAll(
      '[data-dev-tag="sifo-preview"]',
    );
    previewElements.forEach((previewElement: any) => previewElement.remove());

    const developmentElements = pageDocument.querySelectorAll(
      '[data-dev-tag="sifo-dev"]',
    );
    developmentElements.forEach((devElement: any) => devElement.remove());

    const injectedElements = pageDocument.querySelectorAll(
      '[data-dev-tag="inject-into"]',
    );
    injectedElements.forEach((injectedElement: any) => {
      injectedElement?.removeAttribute("data-dev-tag");

      if (!injectedElement.textContent) {
        injectedElement.remove();
      }
    });

    pageDocument.head.removeAttribute("id");

    const elementsWithPositioningAttributes = pageDocument.querySelectorAll(
      "[data-parent]",
    );
    elementsWithPositioningAttributes.forEach(
      (elementWithPositioningAttributes: any) => {
        elementWithPositioningAttributes.removeAttribute("data-parent");
        elementWithPositioningAttributes.removeAttribute("data-is-before");
        elementWithPositioningAttributes.removeAttribute("data-is-after");
      },
    );

    return pageDocument;
  };

  const handleCreateProjectZip = async () => {
    if (!selectedProject) return;

    // const pages: any = await fetchAllPagesContents(project.id);
    if (!pages) return;

    const zip = new JSZip();
    const projectFolder = zip.folder(selectedProject?.name as any);

    console.log("project?.name as any: ", selectedProject?.name as any);
    console.log("projectFolder: ", projectFolder);

    const parser = new DOMParser();

    pages.forEach((page: any) => {
      let pageDocument = parser.parseFromString(page.content, "text/html");
      const languageShortForm = languages.find((lang) =>
        lang.name === page.locale
      )?.shortForm;

      console.log("languageShortForm: ", languageShortForm);

      const pageDocumentStringified =
        prepareDocumentForExport(pageDocument, page.title, page.locale)
          .documentElement.outerHTML;
      projectFolder?.file(
        // `${page.locale === 'Default' ? '' : languageShortForm + '/'}${page.is_index ? 'index' : page.title.toLowerCase().split(' ').join('-')}.html`,
        page.path,
        beautify_html(pageDocumentStringified).replace(
          /(\r\n|\r|\n){2,}/g,
          "\n",
        ),
      );
    });

    const blob = await zip.generateAsync({ type: "blob" });
    return blob;
  };


  const handleDeploy = async () => {
    
    try {
      if (currentUser?.plan_id === 'FREE_TRIAL') {
        onUpgradeOpen();
        return;
      } 
      const sites: any = await fetchSites(currentUser?.id);
      const deployedSitesCount = sites.length;
      console.log('SITES',sites)
      console.log('SITES',deployedSitesCount)

      if (deployedSitesCount < userAllottedCredits?.sites_allowed) {
        setIsDeployingSpinner(true);
        const projectFile = await handleCreateProjectZip();
        if (!projectFile) return;
  
        if (!currentUser?.id || !selectedProject?.id || !selectedProject?.name) {
          console.error('Missing required parameters: userId, projectId, projectName');
          return;
        }
  
        const site: any = await createSite(currentUser?.id, selectedProject?.id, selectedProject?.name);
        if (!site) return;
  
        const formData = new FormData();
        formData.append("project", projectFile);
        formData.append("sid", site.site_id);
        deploySite(formData);
  
        if (currentUser && selectedProject && site) {
          saveSite(
            site.id,
            currentUser?.id,
            selectedProject?.id,
            selectedProject?.name,
            site.url,
          );
        }
  
        setDeployedSite(site);
        setIsDeployingSpinner(false);
      } else {
        onUpgradeOpen();
      }
    } catch (error) {
      console.error('Error in handleDeploy:', error);
    }
  };
  // const handleDeploy = async () => {
  //   if (userAllottedCredits?.sites_allowed !== 0) {
  //     setIsDeployingSpinner(true);
  //     const projectFile = await handleCreateProjectZip();
  //     if (!projectFile) return;

  //     if (!currentUser?.id || !selectedProject?.id || !selectedProject?.name) {
  //       console.error('Missing required parameters: userId, projectId, projectName');
  //       return;
  //   }
  //     const site: any = await createSite(currentUser?.id,selectedProject?.id,selectedProject?.name);
  //     if (!site) return;

  //     // console.log(site);

  //     const formData = new FormData();
  //     formData.append("project", projectFile);
  //     formData.append("sid", site.site_id);
  //     deploySite(formData);

  //     if (currentUser && selectedProject && site) {
  //       saveSite(
  //         site.id,
  //         currentUser?.id,
  //         selectedProject?.id,
  //         selectedProject?.name,
  //         site.url,
  //       );
  //     }

  //     setDeployedSite(site);
  //     setIsDeployingSpinner(false);
  //   } else {
  //     onUpgradeOpen();
  //   }
  // };

  const onDeleteSite = () => {
    if (deployedSite && selectedProject) {
      deleteSite(deployedSite.id, selectedProject?.id);
      setDeployedSite(null);
    }
  };

  useEffect(() => {
    const getSite = async () => {
      if (!selectedProject) return;
  
      try {
        const site: any = await fetchSite(selectedProject.id);
        console.log('Fetched site:', site);
        if (site) setDeployedSite(site);
      } catch (error) {
        console.error('Error fetching site:', error);
      }
    };
  
    getSite();
  }, [selectedProject, fetchSite]);

  return (
    <AuthenticatedLayout>
      <Flex flex={1} flexDirection={"column"} gap={3} w="full">
        <SoftCard h="full">
          {deployedSite
            ? (
              <>
                <Flex flexGrow={1} justifyContent="center" alignItems="center">
                  <Flex
                    flexDirection={{ base: "column", md: "row" }}
                    gap={8}
                    justifyContent="space-around"
                    flexGrow={1}
                    alignItems="center"
                  >
                    <Flex flexDir="column" gap={4} alignItems="start">
                      <Heading size="xl">
                        {deployedSite.name}
                      </Heading>
                      {/* <Text fontSize="lg" >This project is published. Check out the details below, and unpublish it if you need to.</Text> */}
                      <Badge colorScheme="purple" variant="outline">
                        {deployedSite.id}
                      </Badge>
                      {/* <Text>{deployedSite.id}</Text> */}
                      <Text>
                        {new Date(deployedSite.created_at).toLocaleString()}
                      </Text>
                      <Link href={deployedSite.url} isExternal>
                        {deployedSite.url} <ExternalLinkIcon mx="2px" />
                      </Link>
                      <Box>
                        <DangerButton
                          onClick={onDeleteSite}
                          title={"Delete"}
                          body={"Are you sure you want to unpublish this project?"}
                        />
                      </Box>
                    </Flex>
                    {/* <Flex flex={1} justifyContent={'center'}> */}
                    {/* <Text fontSize='9xl'>🔮</Text> */}
                    <Image src={publishedProjectImageSource} width="300px" />
                    {/* </Flex> */}
                  </Flex>
                </Flex>
              </>
            )
            : (
              <>
                <Flex flexGrow={1} justifyContent="center" alignItems="center">
                  <Flex flexDir="column" gap={6} alignItems="center">
                    <Image src={unpublishedProjectImageSource} width="500px" />
                    <Text>
                      Your site is not published. You can publish it by clicking
                      the button below.
                    </Text>
                    <Button
                      colorScheme="purple"
                      size="sm"
                      onClick={handleDeploy}
                    >
                      Publish
                    </Button>

                    <AlertDialog
  isOpen={isUpgradeOpen}
  leastDestructiveRef={cancelRef}
  onClose={onUpgradeClose}
  isCentered
  size="lg"
>
<AlertDialogOverlay>
    <AlertDialogContent boxShadow="lg" p={5} borderRadius="md" bg="gray.800">
      <AlertDialogHeader
        fontSize="2xl"
        fontWeight="bold"
        display="flex"
        alignItems="center"
        color="white"
      >
        <Tooltip label="Upgrade to unlock all features" aria-label="Upgrade tooltip">
          <Icon as={InfoOutlineIcon} color="purple.500" mr={3} />
        </Tooltip>
        Upgrade
      </AlertDialogHeader>

      <AlertDialogBody fontSize="lg" mt={4} display="flex" flexDirection="column" alignItems="center" color="gray.200">
        <MotionBox
          animate={{ rotate: [0, 360] }}
          transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }}
          mb={6}
        >
          <Icon as={InfoOutlineIcon} w={16} h={16} color="purple.400" />
        </MotionBox>
        <Text textAlign="center">
          <Text as="span" fontWeight="bold">
          This action is not available in your current plan.
          </Text>{" "}
          Upgrade now to unlock it and get the most out of SIFO's full suite of features.
        </Text>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent="center" mt={6}>
        <Button
          size="md"
          variant="outline"
          ref={cancelRef}
          onClick={onUpgradeClose}
          mr={3}
          color="white"
          _hover={{ bg: "gray.700" }}
        >
          Cancel
        </Button>
        <Button
          size="md"
          colorScheme="purple"
          onClick={() => navigate('/upgrade')}
          _hover={{ boxShadow: "0 0 10px rgba(128, 90, 213, 0.8)" }} variant='outline'
        >
          Unlock Now
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>
                  </Flex>
                </Flex>
              </>
            )}
        </SoftCard>
      </Flex>
    </AuthenticatedLayout>
  );
};
